import { Col, Form, Modal, ModalHeader, Row } from 'react-bootstrap'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'

const ModalCreate = (props) => {
  return (
    <Modal {...props} centered>
      <ModalHeader closeButton>Create ML Draft</ModalHeader>
      <Modal.Body>
        <p>Create ml draft?</p>
      </Modal.Body>
      <Modal.Footer>
        <CustomCloseButton onClick={props.onHide} />
        <CustomButton variant={'warning'} text={'Create'} />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCreate
