import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faCircleMinus,
  faClose,
  faFileDownload
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  BadgeStatusOutboundPlan,
  BadgeStatusPrListInbounds
} from '../../../components/atoms/StatusLabel'
import CustomButton from '../../../components/Button/CustomButton'
import Label from '../../../components/Label/Input/index'
import ModalUpdateVehicleOutboundPlan from '../../../components/modal/Outbound/Plan/ModalUpdateVehicleOutboundPlan'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { formatThousandSeparator } from '../../../utils/TextFormatter'
import ActionButton from './ActionButton'
import styles from './outbound_plan.module.css'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const labelFormStyle = {
  fontSize: '12px'
}
const statusProperty = [
  'pnp_picking',
  'waiting_om',
  'request_loading',
  'finish_loading',
  'loading',
  'ready',
  'on_delivery'
]
const DetailOutboundPlan = () => {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const { code } = useParams()
  const [showNotificationUpdate, setShowNotificationUpdate] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [loadingSubmitTicket, setLoadingSubmitTicket] = useState(false)
  // const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingRemoveSj, setLoadingRemoveSj] = useState({})
  // const [showModalUpdateDriver, setShowModalUpdateDriver] = useState(false)
  const [showModalUpdateVehicle, setShowModalUpdateVehicle] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalImageSrc, setModalImageSrc] = useState('')
  const [modalImageLabel, setModalImageLabel] = useState('')
  const [progressDetailCommand, setProgressDetailCommand] = useState(true)
  const [iconProgressDetailCommand, setIconProgressDetailCommand] =
    useState(faCaretUp)
  const [updateVehicle, setUpdateVehicle] = useState({
    step: 1,
    type: 'internal',
    vehicle_id: '',
    driver_id: '',
    sim: null,
    memo: null,
    stnk: null
  })
  const [outboundPlan, setOutboundPlan] = useState({})
  const [total, setTotal] = useState({
    qty: '',
    pack: '',
    volume: ''
  })
  useEffect(() => {
    // when code falsy redirect to page outbound plan
    if (!code) {
      navigate('/outbound/plan')
    }
    const timeoutCloseNotification = setTimeout(() => {
      setShowNotificationUpdate(false)
    }, 10000)

    fetchDetailOutboundPlan()
    return () => {
      clearTimeout(timeoutCloseNotification)
    }
  }, [code])

  useEffect(() => {
    setTotal({
      qty: calculateTotalQty('qty'),
      pack: calculateTotalQty('pack'),
      volume: calculateTotalVolume()
    })
  }, [outboundPlan])

  const handleRemoveSj = (id) => {
    Swal.fire({
      title: 'Remove SJ draft?',
      text: 'This action can’t be undone',
      icon: 'warning',
      iconColor: '#828282',
      showCancelButton: true,
      confirmButtonColor: '#C12727',
      cancelButtonColor: 'transparent',
      confirmButtonText: 'Remove',
      showCloseButton: true,
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-remove-outbound'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        removeDataSj(id)
      }
    })
  }

  const removeDataSj = async (id) => {
    try {
      setLoadingRemoveSj((prevLoadingRemoveSj) => ({
        ...prevLoadingRemoveSj,
        [id]: true
      }))
      const res = await api.delete(`outbound/sjdraft/${id}`)
      if (res.status === 200) {
        // check have data sj_draft after remove
        const existDataSjDraft = outboundPlan?.sj_drafts?.filter(
          (sj_draft) => sj_draft.sj_draft_id !== id
        )?.length
        if (!existDataSjDraft) {
          // when length 0 direct to outbound plan index
          return navigate('/outbound/plan', { replace: true })
        }
        fetchDetailOutboundPlan()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingRemoveSj((prevLoadingRemoveSj) => ({
        ...prevLoadingRemoveSj,
        [id]: false
      }))
    }
  }

  const handleImageClick = (imageSrc, label) => {
    setModalImageSrc(imageSrc)
    setModalImageLabel(label)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const fetchDetailOutboundPlan = async () => {
    try {
      setIsLoadingPage(true)
      const res = await api.get(`/outbound/outbound_plan/${code}`, {
        params: {
          notif_id: searchParams.get('notif_id')
        }
      })
      if (res.status === 200) {
        setOutboundPlan(res.data.data)
        setUpdateVehicle({
          step: 1,
          type: res.data.data.delivery_type ?? 'internal',
          vehicle_id: res.data.data.vehicle_id,
          driver_id: res.data.data.driver_id,
          sim: res.data.data.sim,
          memo: res.data.data.sim,
          stnk: res.data.data.stnk
        })
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingPage(false)
    }
  }

  const calculateTotalQty = (type) => {
    let qtyMap = []
    if (outboundPlan?.sj_drafts?.length > 0) {
      outboundPlan?.sj_drafts?.map((sjValue) => {
        sjValue.pick_up_commands.forEach((command) => {
          if (type === 'qty') {
            const { qty, uom } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty, uom })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom
                    ? { ...value, qty: (value.qty += qty) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty, uom })
              }
            }
          }
          if (type === 'pack') {
            const { total_pack, uom_pack } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty: total_pack, uom: uom_pack })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom_pack
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom_pack
                    ? { ...value, qty: (value.qty += total_pack) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty: total_pack, uom: uom_pack })
              }
            }
          }
        })
      })
      const totalQty = qtyMap
        .map((data) => `${data.qty} ${data.uom}`)
        .join(' + ')
      return totalQty
    }
    return 0
  }

  const calculateTotalVolume = () => {
    if (outboundPlan?.sj_drafts?.length > 0) {
      let tmpVolume = []
      outboundPlan?.sj_drafts?.map((sjValue) => {
        sjValue.pick_up_commands?.map((pickupCommand) => {
          tmpVolume.push(pickupCommand.qty * pickupCommand.volume_item)
        })
      })

      if (tmpVolume.length > 0) {
        const totalVolume = tmpVolume.reduce((acc, volume) => {
          return (acc += volume ?? 0)
        }, 0)
        return totalVolume
      }
    }
    return 0
  }
  const handleUpdateVehicle = (name, value) => {
    setUpdateVehicle((prevUpdateVehicle) => ({
      ...prevUpdateVehicle,
      [name]: value
    }))
  }
  const handleUploadFile = (e = '', action) => {
    if (action === 'upload') {
      const { name, files } = e?.target
      setUpdateVehicle((prevFiles) => ({ ...prevFiles, [name]: files[0] }))
    }
    if (action === 'delete') {
      setUpdateVehicle((prevFiles) => ({ ...prevFiles, [e]: null }))
    }
  }
  const handleSubmitTicket = async () => {
    try {
      setLoadingSubmitTicket(true)
      const res = await api.post(
        `/outbound/outbound_plan/${outboundPlan?.outbound_plan_id}/submit-ticket`
      )
      if (res.data.success) {
        fetchDetailOutboundPlan(outboundPlan?.outbound_plan_id)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSubmitTicket(false)
    }
  }

  const propertySubmitTicket = () => {
    if (loadingSubmitTicket) {
      return {
        text: 'Loading...',
        disabled: true,
        color: 'secondary'
      }
    }
    if (
      (outboundPlan?.sj_drafts?.filter(
        (sjDraft) => sjDraft.status === 'problem'
      ).length === 0 &&
        outboundPlan?.outbound_problem) ||
      (outboundPlan?.sj_drafts?.filter(
        (sjDraft) => sjDraft.status === 'problem'
      ).length === 0 &&
        !outboundPlan?.outbound_problem &&
        outboundPlan?.delivery_type)
    ) {
      return {
        text: 'Submit Ticket',
        disabled: false,
        color: 'warning'
      }
    }

    return {
      text: 'Submit Ticket',
      disabled: true,
      color: 'secondary'
    }
  }

  const handleDownloadList = async (value) => {
    try {
      let url =
        value === 'packing_list'
          ? `/outbound/outbound_plan/${outboundPlan?.outbound_plan_id}/download_packing_list`
          : `/outbound/outbound_plan/${outboundPlan?.outbound_plan_id}/download_checklist_armada`
      const res = await api.get(url, { responseType: 'arraybuffer' })
      const { status, data } = res

      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const urlDownload = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = urlDownload
        link.setAttribute(
          'download',
          `file_${value}_${outboundPlan.outbound}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  if (isLoadingPage) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="container mb-3">
        <div className="row">
          {/* <div className="col-8"> */}
          <div
            className={
              statusProperty.some((status) => status === outboundPlan?.status)
                ? 'col-4'
                : 'col-12'
            }
          >
            <h4 style={headerTitleStyle} className="d-flex flex-row gap-2">
              <p>{outboundPlan?.outbound}</p>
              <sup className="ml-3">
                <BadgeStatusOutboundPlan text={outboundPlan?.status} />
              </sup>
            </h4>
          </div>
          {statusProperty.some((status) => status === outboundPlan?.status) && (
            <ActionButton>
              {outboundPlan?.sj_drafts?.filter(
                (sjDraft) => sjDraft.status === 'problem'
              ).length === 0 &&
                !outboundPlan?.outbound_problem && (
                  <>
                    <CustomButton
                      variant={'transparent'}
                      style={{ borderColor: '#F89D1D' }}
                      classNameValue={`${
                        outboundPlan?.status === 'finish_loading' ||
                        outboundPlan?.status === 'on_delivery'
                          ? 'd-block'
                          : 'd-none'
                      } text-warning`}
                      onClick={() => handleDownloadList('checklist_armada')}
                      text={
                        <>
                          <FontAwesomeIcon
                            icon={faFileDownload}
                            className="me-2"
                          />
                          <span>Checklist Armada</span>
                        </>
                      }
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ borderColor: '#F89D1D' }}
                      classNameValue={`text-warning ${
                        outboundPlan?.status === 'waiting_om'
                          ? 'd-none'
                          : 'd-block'
                      }`}
                      text={'Download Packing List'}
                      onClick={() => handleDownloadList('packing_list')}
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#203568' }}
                      text={'Update Vehicle'}
                      onClick={() => setShowModalUpdateVehicle(true)}
                    />
                  </>
                )}
              {outboundPlan?.status === 'waiting_om' && (
                <>
                  {(outboundPlan?.sj_drafts.filter(
                    (sjDraft) => sjDraft.status === 'problem'
                  ).length > 0 ||
                    outboundPlan?.outbound_problem) && (
                    <p
                      className="text-danger mt-3 fw-bold"
                      style={{ fontSize: '12px' }}
                    >
                      Please solve problem from all SJ to submit
                    </p>
                  )}

                  <CustomButton
                    variant={propertySubmitTicket().color}
                    disabled={propertySubmitTicket().disabled}
                    onClick={() =>
                      handleSubmitTicket(outboundPlan?.outbound_plan_id)
                    }
                    text={propertySubmitTicket().text}
                  />
                </>
              )}
            </ActionButton>
          )}

          <div className="col-4">
            {showNotificationUpdate && (
              <>
                <Card
                  className={`${styles.successNotification}`}
                  style={{ cursor: 'pointer' }}
                >
                  <Card.Body>
                    <Row>
                      <Col md={1}>
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="mt-1"
                            style={{
                              fontSize: '15px',
                              color: 'white'
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={11}>
                        <Row className="d-flex align-items-center">
                          <Col sm={10}>
                            <p className="text-light ml-4">
                              Driver and Vehicle Updated!
                            </p>
                          </Col>
                          <Col sm={2}>
                            <div
                              className="d-flex justify-content-end"
                              onClick={(e) => {
                                setShowNotificationUpdate(false)
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faClose}
                                style={{
                                  fontSize: '15px',
                                  color: 'white',
                                  marginTop: '-.8rem',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb"
              style={{ fontSize: '12px', cursor: 'pointer' }}
            >
              <li
                className="breadcrumb-item sj-item"
                onClick={() => navigate('/outbound/plan')}
              >
                <span className="text-black fw-bold">OUTBOUND</span>
              </li>
              <li
                className="breadcrumb-item sj-item active text-uppercase"
                aria-current="page"
              >
                {outboundPlan?.outbound}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-3">
            <Form.Group className="mb-3 text-muted" controlId="sourcewarehouse">
              <Form.Label style={labelFormStyle}>SOURCE WAREHOUSE</Form.Label>
              <Form.Select aria-label="sourcewarehouse" disabled>
                <option value={outboundPlan?.warehouse_name}>
                  {outboundPlan?.warehouse_name}
                </option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="col-3">
            <Form.Group className="mb-3 text-muted" controlId="deliverydate">
              <Form.Label style={labelFormStyle}>DELIVERY DATE</Form.Label>
              <Form.Control
                type="date"
                value={moment(outboundPlan?.delivery_date).format('YYYY-MM-DD')}
                disabled
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
      <div className="row">
        <div className="col-3 mt-3">
          <Table borderless style={{ marginLeft: '-.5rem' }}>
            <tbody>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Qty
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {total.qty}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Pack
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {total.pack}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Vol
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {formatThousandSeparator(total.volume)} cm3
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <div className="row mt-3">
        {outboundPlan?.sj_drafts?.map((itemCommand, index) => (
          <ListDataSj
            key={itemCommand.id}
            data={itemCommand}
            number={index + 1}
            onHandleRemoveSj={handleRemoveSj}
            status={outboundPlan?.status}
            outboundPlanId={outboundPlan?.outbound_plan_id}
            outboundPlanCode={outboundPlan?.outbound}
            loadingRemoveSj={loadingRemoveSj}
          />
        ))}
      </div>
      <div
        style={{ borderTop: '3px solid #F1F2F7' }}
        className="mt-3 mb-3"
      ></div>
      {/* {outboundPlan?.status === 'done' && (
        <>
          <div className="d-flex justify-content-start gap-3 my-4">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleImageClick(outboundPlan?.fill_doc, 'Isi')}
            >
              <ImageDisplay label="ISI" imageSrc={outboundPlan?.fill_doc} />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleImageClick(outboundPlan?.seal_doc, 'SEGEL')}
            >
              <ImageDisplay label="SEGEL" imageSrc={outboundPlan?.seal_doc} />
            </div>
          </div>
        </>
      )} */}
      <Row className="bg-white rounded">
        <Col md={12} className="mb-3">
          <h5 className="fw-bold text-uppercase">
            Progress Detail
            <FontAwesomeIcon
              icon={iconProgressDetailCommand}
              className="ms-3"
              onClick={() => {
                const statusProgressDetailCollapse = !progressDetailCommand
                setProgressDetailCommand((prev) => !prev)
                statusProgressDetailCollapse === false
                  ? setIconProgressDetailCommand(faCaretDown)
                  : setIconProgressDetailCommand(faCaretUp)
              }}
              aria-expanded={progressDetailCommand}
              aria-controls="example-collapse-text2"
            />
          </h5>
        </Col>
        <Collapse in={progressDetailCommand}>
          <ul className="list-unstyled">
            {outboundPlan?.progress?.length > 0 &&
              outboundPlan?.progress?.map((progress) => (
                <li>
                  <div className="d-flex flex-row gap-3 progress-detail">
                    <div className="circle-progress-detail"></div>
                    <p>
                      {moment(progress?.datetime).format('DD MMMM YYYY HH:mm')}
                    </p>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ height: '13px' }}
                    >
                      <div className="d-flex flex-row gap-3">
                        <div className="circle-small-progress-detail mt-2 ms-1"></div>
                        <span style={{ fontSize: '13px' }}>
                          {progress?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled" style={{ marginLeft: '2rem' }}>
                    {progress?.detail?.length > 0 &&
                      progress?.detail?.map((detail) => (
                        <li
                          style={{
                            color: `${detail.color ? detail.color : 'black'} `
                          }}
                          className="mb-3"
                        >
                          {detail?.description}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
          </ul>
        </Collapse>
      </Row>
      <div className="row mt-3 mb-5">
        <Card>
          <Card.Body>
            <Row>
              <Label
                label="CREATED BY"
                value={outboundPlan?.createdby}
                col={3}
              />
              <Label
                label="CREATED AT"
                value={moment(outboundPlan?.createdAt).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}
                col={3}
              />
              <Label
                label="LAST UPDATED BY"
                value={outboundPlan?.updatedby}
                col={3}
              />
              <Label
                label="LAST UPDATED AT"
                value={moment(outboundPlan?.updatedAt).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}
                col={3}
              />
            </Row>
          </Card.Body>
        </Card>
      </div>
      <ModalUpdateVehicleOutboundPlan
        show={showModalUpdateVehicle}
        onHide={() => {
          setShowModalUpdateVehicle(false)
          setUpdateVehicle({
            step: 1,
            type: outboundPlan?.delivery_type,
            vehicle_id: outboundPlan?.vehicle_id,
            driver_id: outboundPlan?.driver_id,
            sim: outboundPlan?.sim,
            memo: outboundPlan?.memo,
            stnk: outboundPlan?.stnk
          })
        }}
        updateVehicle={updateVehicle}
        handleUpdateVehicle={handleUpdateVehicle}
        handleUploadFile={handleUploadFile}
        totalVolume={calculateTotalVolume()}
        outboundPlanId={outboundPlan?.outbound_plan_id}
        refreshData={() => fetchDetailOutboundPlan(outboundPlan?.outbound)}
        isTr={
          outboundPlan?.sj_drafts?.filter((sj_draft) => sj_draft.is_tr)
            ?.length > 0
            ? true
            : false
        }
      />

      {/* <ModalViewProof
        show={showModal}
        handleClose={handleCloseModal}
        imageSrc={modalImageSrc}
        imageLabel={modalImageLabel}
      /> */}
    </>
  )
}

const ListDataSj = ({
  data,
  number,
  onHandleRemoveSj,
  status,
  loadingRemoveSj,
  outboundPlanId,
  outboundPlanCode
}) => {
  const propsCancelSj = () => {
    if (loadingRemoveSj[data.sj_draft_id]) {
      return {
        key: data.sj_draft_id,
        variant: 'secondary',
        className: 'fw-bold',
        size: 'sm',
        disabled: true,
        children: (
          <div className="d-flex flex-row gap-2">
            <span>REMOVE</span>
            <FontAwesomeIcon
              icon={faCircleMinus}
              style={{ color: 'white' }}
              className="ml-2 mt-1"
            />
          </div>
        )
      }
    }
    return {
      key: data.sj_draft_id,
      variant: 'danger',
      className: 'fw-bold',
      size: 'sm',
      onClick: () => onHandleRemoveSj(data.sj_draft_id),
      children: (
        <div className="d-flex flex-row gap-2">
          <span>REMOVE</span>
          <FontAwesomeIcon
            icon={faCircleMinus}
            style={{ color: 'white' }}
            className="ml-2 mt-1"
          />
        </div>
      )
    }
  }
  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-8">
            <p className="d-flex flex-row gap-2">
              <span className="fw-bold mr-2">
                {number}.{' '}
                <Link
                  to={`/outbound/sj/detail/${encodeURIComponent(
                    data?.code.split(' - ')[0]
                  )}?outboundId=${outboundPlanId}&outboundCode=${outboundPlanCode}`}
                  className="text-decoration-none"
                >
                  {data?.code.split(' - ')[0]}
                </Link>
                {' - '}
                {data?.code.split(' - ')[1]}
              </span>
              <sup className="ml-2" style={{ fontSize: '15px' }}>
                <BadgeStatusPrListInbounds text={data.status} />
              </sup>
            </p>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {(status === 'unassigned' || status === 'waiting_pnp') &&
              data.status === 'waiting' && <Button {...propsCancelSj()} />}
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="col-4">
          <p>{data.customer}</p>
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="col-6">
          <div dangerouslySetInnerHTML={{ __html: data.delivery_address }} />
        </div>
      </div>
      <div className="col-12">
        <h6 className="fw-bold text-muted">PICK UP COMMAND</h6>
      </div>
      <div className="col-12 mt-3 pe-2">
        <Table
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          responsive="sm"
          style={{ fontSize: '14px' }}
        >
          <thead
            className="table-secondary"
            style={{ borderColor: 'transparent' }}
          >
            <tr>
              <th className="p-2 align-middle">No</th>
              <th className="p-2 align-middle">Item</th>
              <th className="p-2 align-middle">Item Alias</th>
              <th className="p-2 align-middle">Item Code</th>
              <th className="p-2 align-middle text-end">Batch</th>
              <th className="p-2 align-middle text-end">Expired</th>
              <th className="p-2 align-middle text-end">Total Qty</th>
              <th className="p-2 align-middle text-end">Total Pack</th>
            </tr>
          </thead>
          <tbody>
            {data.pick_up_commands.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <p className="fw-bold text-center">Item Empty</p>
                </td>
              </tr>
            )}
            {data.pick_up_commands.map((item, index) => (
              <ListPickupCommand data={item} number={index + 1} key={index} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

const ListPickupCommand = ({ data, number }) => {
  return (
    <tr key={data.id}>
      <td
        className="p-2 align-middle"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {number}
      </td>
      <td
        className="p-2 align-middle"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.item_name}
      </td>
      <td
        className="p-2 align-middle"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.item_alias ?? '-'}
      </td>
      <td
        className="p-2 align-middle"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.item_code}
      </td>
      <td
        className="p-2 align-middle text-end"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.batch}
      </td>
      <td
        className="p-2 align-middle text-end"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data.exp_date ? moment(data.exp_date).format('DD-MM-YYYY') : '-'}
      </td>
      <td
        className="p-2 align-middle text-end"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.qty} {data?.uom}
      </td>
      <td
        className="p-2 align-middle text-end"
        style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
      >
        {data?.total_pack}
      </td>
    </tr>
  )
}

export default DetailOutboundPlan
