import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import Multiselect from 'multiselect-react-dropdown'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Toggle from 'react-toggle'
import api from '../../../../config/AxiosInstance'
import '../../../../styles/style.css'
import * as Common from '../../../../utils/Common'
import { MapEditorSchema } from '../../../../utils/validationSchema'
import CustomButton from '../../../Button/CustomButton'
function FormRack(props) {
  const animatedComponents = makeAnimated()
  const [activeToggle, setActiveToggle] = useState(false)
  const [racks, setRacks] = useState([])
  const [beams, setBeams] = useState([])
  const [slots, setSlots] = useState([])
  const [areas, setAreas] = useState([])
  const [disableArea, setDisableArea] = useState(true)
  const [inputRack, setInputRack] = useState('')
  const [inputBeam, setInputBeam] = useState('')
  const [inputSlot, setInputSlot] = useState('')
  const [inputArea, setInputArea] = useState('')
  const [typeSuggestion, setTypeSuggestion] = useState('')
  const [suggestions, setSuggestions] = useState([])
  // const []
  useEffect(() => {
    if (props?.warehouseId && props?.show) {
      const controller = new AbortController()
      fetchRacks(props.warehouseId, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    }
  }, [props.warehouseId])
  const fetchRacks = async (warehouseId, controller) => {
    try {
      const values = {
        warehouse_id: warehouseId,
        type: 'rack'
      }
      const res = await api.post(`/settings/map/editor/rack/all`, values, {
        signal: controller.signal
      })

      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((rack) =>
          optionValue.push({
            value: rack.id,
            label: rack.name
          })
        )
      }
      setRacks(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  const fetchBeams = async (rackId) => {
    try {
      const values = {
        rack_id: rackId
      }
      const res = await api.post(`settings/map/editor/beam/all`, values)
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((beam) =>
          optionValue.push({
            value: beam.id,
            label: beam.code
          })
        )
      }
      setBeams(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchSlots = async (beamId) => {
    try {
      const values = {
        beam_id: beamId
      }
      const res = await api.post(`settings/map/editor/slot/all`, values)
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((slot) =>
          optionValue.push({
            value: slot.id,
            label: slot.code
          })
        )
      }
      setSlots(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchTypeSuggestion = async (type, query) => {
    try {
      let optionValue = []
      if (type === 'item') {
        const res = await api.get(`/master/items/all`, {
          params: { q: query }
        })
        if (res.data.data.length > 0) {
          res.data.data.map((item) =>
            optionValue.push({
              value: item.item_code,
              label: item.item_name
            })
          )
        }
      }

      if (type === 'category') {
        const res = await api.get(`/master/categories/all`, {
          params: { name: query }
        })
        if (res.data.data.length > 0) {
          res.data.data.map((category) =>
            optionValue.push({
              value: category.id,
              label: category.name
            })
          )
        }
      }

      setSuggestions(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchAreas = async (warehouseId) => {
    try {
      const res = await api.post(`settings/map/editor/area/all`, {
        warehouse_id: warehouseId
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((area) =>
          optionValue.push({
            value: area.id,
            label: area.name
          })
        )
      }
      setAreas(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleInputRackChange = (newValue) => {
    setInputRack(newValue)
  }
  const handleSelectRack = (e) => {
    setInputRack(e?.value ?? '')
    if (e) {
      fetchAreas(props.warehouseId)
      fetchBeams(e?.value)
      setDisableArea(false)
    } else {
      setDisableArea(true)
    }
  }
  const handleInputBeamChange = (newValue) => {
    setInputBeam(newValue)
  }
  const handleSelectBeam = (e) => {
    setInputBeam(e?.value ?? '')
    if (e) {
      fetchSlots(e?.value)
    }
  }

  const handleInputSlotChange = (newValue) => {
    setInputSlot(newValue)
  }

  const handleInputAreaChange = (newValue) => {
    setInputArea(newValue)
  }
  return (
    <Formik
      initialValues={{
        rack: '',
        beam: '',
        slot: '',
        area: '',
        type: typeSuggestion,
        type_id: []
      }}
      validationSchema={MapEditorSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => {
        values.activeToggle = activeToggle
        props.onHandleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched
      }) => {
        return (
          <Form className="d-grid gap-2" onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    RACK
                  </Form.Label>
                  <Select
                    options={
                      inputRack !== ''
                        ? racks.filter(
                            (rack) =>
                              rack.label.toLowerCase() ===
                              inputRack.toLowerCase()
                          )
                        : racks
                    }
                    onBlur={() => setFieldTouched('rack')}
                    onInputChange={handleInputRackChange}
                    onChange={(e) => {
                      setFieldValue('rack', e)
                      handleSelectRack(e)
                      if (!e) {
                        setFieldValue('beam', '')
                        setFieldValue('slot', '')
                        setFieldValue('area', '')
                        setFieldValue('type', '')
                        setFieldValue('type_id', [])
                      }
                    }}
                    value={values.rack}
                    placeholder="Search racks..."
                    isClearable
                    isSearchable
                  />
                  {touched.rack && !!errors.rack && (
                    <p className="text-danger mt-2">{errors.rack}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    BEAM
                  </Form.Label>
                  <Select
                    options={
                      inputBeam !== ''
                        ? beams.filter((beam) => beam.label == inputBeam)
                        : beams
                    }
                    onBlur={() => setFieldTouched('beam')}
                    onInputChange={handleInputBeamChange}
                    onChange={(e) => {
                      setFieldValue('beam', e)
                      handleSelectBeam(e)
                    }}
                    value={values.beam}
                    placeholder="Search beams..."
                    isClearable
                    isSearchable
                  />
                  {touched.beam && !!errors.beam && (
                    <p className="text-danger mt-2">{errors.beam}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    SLOT
                  </Form.Label>

                  <Select
                    options={
                      inputSlot !== ''
                        ? slots.filter((slot) => slot.label == inputSlot)
                        : slots
                    }
                    onBlur={() => setFieldTouched('slot')}
                    onInputChange={handleInputSlotChange}
                    onChange={(e) => {
                      setFieldValue('slot', e)
                    }}
                    value={values.slot}
                    placeholder="Search slots..."
                    isClearable
                    isSearchable
                  />
                  {touched.slot && !!errors.slot && (
                    <p className="text-danger mt-2">{errors.slot}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mt-2 ">
              <Col md={12}>
                <Form.Group>
                  <Form.Label
                    className="text-muted text-uppercase"
                    style={{ fontSize: '13px' }}
                  >
                    Item Suggestion
                  </Form.Label>
                  <div className="d-flex mt-2">
                    <div className="d-flex">
                      <Form.Check.Input
                        inline
                        id="itemSuggestionOne"
                        name="itemSuggestion"
                        type="radio"
                        className="access-management"
                        checked={values.type === 'item'}
                        onChange={() => {
                          setTypeSuggestion('item')
                          fetchTypeSuggestion('item', '')
                          setFieldValue('type', 'item')
                          setFieldValue('type_id', [])
                        }}
                      ></Form.Check.Input>
                      <Form.Check.Label
                        className="ml-2"
                        htmlFor="itemSuggestionOne"
                      >
                        Define by Item Code
                      </Form.Check.Label>
                    </div>

                    <div className="d-flex ml-3">
                      <Form.Check.Input
                        inline
                        id="itemSuggestionTwo"
                        name="itemSuggestion"
                        type="radio"
                        checked={values.type === 'category'}
                        className="access-management"
                        onChange={() => {
                          setTypeSuggestion('category')
                          fetchTypeSuggestion('category', '')
                          setFieldValue('type', 'category')
                          setFieldValue('type_id', [])
                        }}
                      ></Form.Check.Input>
                      <Form.Check.Label
                        className="ml-2"
                        htmlFor="itemSuggestionTwo"
                      >
                        Define by Category
                      </Form.Check.Label>
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Multiselect
                    customCloseIcon={
                      <span className="ml-3" style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faClose} />
                      </span>
                    }
                    onSearch={(e) => {
                      fetchTypeSuggestion(
                        typeSuggestion,
                        e?.length === 0 ? '' : e
                      )
                    }}
                    placeholder="Item Code or Category"
                    options={suggestions}
                    displayValue={'label'}
                    onSelect={(e) => {
                      setFieldTouched('type_id')
                      setFieldValue('type_id', e)
                      fetchTypeSuggestion(typeSuggestion, '')
                    }}
                    selectedValues={values.type_id}
                    onRemove={(e) => {
                      setFieldValue('type_id', e.length === 0 ? [] : e)
                    }}
                    style={{
                      chips: {
                        background: 'inherit',
                        borderRadius: '5px',
                        color: 'black',
                        border: '1px solid #F1F2F7'
                      },
                      searchBox: {
                        width: '100%'
                      },
                      customClose: {
                        cursor: 'pointer'
                      }
                    }}
                    disable={typeSuggestion ? false : true}
                  />
                  {touched.type_id && !!errors.type_id && (
                    <p className="text-danger mt-2">{errors.type_id}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    Area
                  </Form.Label>
                  <Select
                    options={
                      inputArea !== ''
                        ? areas.filter((area) => area.label == inputArea)
                        : areas
                    }
                    onBlur={() => setFieldTouched('area')}
                    onInputChange={handleInputAreaChange}
                    onChange={(e) => {
                      setFieldValue('area', e)
                    }}
                    value={values.area}
                    placeholder="Search areas..."
                    isClearable
                    isSearchable
                    isDisabled={disableArea}
                  />
                  {touched.area && !!errors.area && (
                    <p className="text-danger mt-2">{errors.area}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Active
                </Form.Label>
                <label className="d-block">
                  <Toggle
                    className="actived"
                    icons={false}
                    onChange={() => setActiveToggle((active) => !active)}
                  />
                </label>
              </Col>
            </Row>
            <Modal.Footer className="mt-3">
              <CustomButton
                text={'Discard'}
                variant={'white'}
                classNameValue={'text-black'}
                onClick={props.handleCloseButton}
              />
              <CustomButton
                onClick={handleSubmit}
                variant={'warning'}
                disabled={!isValid || !dirty}
                text={'Add'}
              />
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormRack
