export const PURCHASE_ORDERS = Object.freeze([
  {
    id: 1,
    value: 'To Receive and Bill',
    label: 'To Receive and Bill'
  },
  {
    id: 2,
    value: 'To Receive',
    label: 'To Receive'
  }
])

export const OUTBOUND_TYPES = Object.freeze([
  {
    id: 1,
    value: 'pick_up',
    label: 'Pick Up'
  },
  {
    id: 2,
    value: 'transfer',
    label: 'Transfer'
  }
])

export const DELIVERY_TYPES = Object.freeze([
  {
    id: 1,
    value: 'internal',
    label: 'Internal'
  },
  {
    id: 2,
    value: 'sewa',
    label: 'Sewa'
  },
  {
    id: 3,
    value: 'self-pickup',
    label: 'Self Pick Up'
  }
])

export const OUTBOUND_STATUS = Object.freeze([
  {
    id: 1,
    value: 'success',
    label: 'Success'
  },
  {
    id: 2,
    value: 'cancel',
    label: 'Cancel'
  }
])
