import { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import '../../styles/style.css'
import ModalCancelSlotLocation from '../modal/CustomerDeliveryPlan/ModalCancelSlotLocation'

function CreateSjLocation() {
  const navigate = useNavigate()
  const { state } = useLocation()
  // state
  const [slots, setSlots] = useState({})
  const [prevSlots, setPrevSlots] = useState(null)
  const [showCancelModal, setShowCancelModal] = useState(false)

  useEffect(() => {
    if (!state) {
      return navigate(-1)
    }
    // filter based on state pickupCommandId to data state pickupCommands
    const slotLocations = state?.pickupCommands.filter(
      (location) => location.id === state?.pickupCommandId
    )[0]
    setSlots(slotLocations)
    setPrevSlots(state?.pickupCommands)
  }, [state])
  const handleCheckPickup = (e, id) => {
    // check what total pack same or greather than equal total pack
    if (
      slots?.locations?.slots
        .filter((slot) => slot.is_checked)
        .reduce((total, slot) => total + slot.total_pack, 0) >=
        slots?.total_pack &&
      e.target.checked
    ) {
      Swal.fire({
        title: 'Action cant allowed!',
        text: 'Please uncheck slot first, before select another slot',
        icon: 'warning'
      })
      return
    }
    const updatedItemIsChecked = {
      ...slots,
      locations: {
        ...slots.locations,
        slots: slots.locations.slots.map((slotValue) =>
          slotValue.slot_id === id
            ? { ...slotValue, is_checked: e.target.checked }
            : slotValue
        )
      }
    }
    const updatedItem = {
      ...updatedItemIsChecked,
      location: updatedItemIsChecked.locations.slots
        .filter((slot) => slot.is_checked)
        .map((slotValue) => slotValue.name)
        .join(' ')
    }
    setSlots(updatedItem)
  }

  const handleSubmit = () => {
    const updatePickupCommands = state.pickupCommands.reduce(
      (acc, pickupCommand) => {
        if (pickupCommand.id === state.pickupCommandId) {
          acc.push(slots)
        } else {
          acc.push(pickupCommand)
        }
        return acc
      },
      []
    )
    navigate('/outbound/sj/create', {
      state: {
        resultCustomerDeliveryPlan: state.resultCustomerDeliveryPlan,
        pickupCommands: updatePickupCommands
      }
    })
  }
  // style
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 style={headerTitleStyle}>Slot Location</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item sj-item">
                  <Link
                    to="/outbound/customer/delivery/plan"
                    className="text-decoration-none"
                  >
                    <span className="text-black fw-bold text-uppercase">
                      Customer Delivery Plan
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item sj-item" aria-current="page">
                  <span
                    className="fw-bold text-uppercase"
                    onClick={() => navigate(-1)}
                  >
                    CREATE SJ DRAFT
                  </span>
                </li>
                <li
                  className="breadcrumb-item sj-item active"
                  aria-current="page"
                >
                  <span className="fw-bold text-uppercase">Slot Location</span>
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <div className="d-flex">
              <h6
                className="fw-bold text-muted text-uppercase"
                style={{ width: '50%' }}
              >
                slot location
              </h6>
              <div
                className="d-flex justify-content-end mr-2"
                style={{ width: '50%' }}
              >
                <p>
                  <span
                    className={`fw-bold ${
                      slots?.locations?.slots
                        .filter((slot) => slot.is_checked)
                        .reduce((total, slot) => total + slot.total_pack, 0) <
                      slots?.total_pack
                        ? 'text-danger'
                        : 'text-success'
                    }`}
                  >
                    {slots?.locations?.slots
                      .filter((slot) => slot.is_checked)
                      .reduce((total, slot) => total + slot.total_pack, 0)}
                  </span>
                  <span className="ml-2">/</span>
                  <span className="fw-bold ml-2">
                    {Number(slots?.locations?.pack)}
                  </span>
                  <span className="fw-bold text-uppercase ml-1">Pack</span>
                </p>
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <Table className="shadow-sm mb-5 bg-white rounded" bordered hover>
              <thead className="table-secondary">
                <tr>
                  <th>No</th>
                  <th>Item</th>
                  <th>Item Alias</th>
                  <th>Item Code</th>
                  <th>Batch</th>
                  <th>Total Pack</th>
                  <th>Slot</th>
                  <th className="text-center">Pick Up</th>
                </tr>
              </thead>
              <tbody>
                {slots?.locations?.slots.map((slot, index) => (
                  <tr key={index + 1}>
                    <td> {index + 1}</td>
                    <td>{slots.item_name}</td>
                    <td>{slots.item_alias ?? '-'}</td>
                    <td>{slots.item_code ?? '-'}</td>
                    <td>{slots.batch ?? '-'}</td>
                    <td>{slot.total_pack}</td>
                    <td>{slot.name}</td>
                    <td>
                      <div className="text-center">
                        <input
                          type="checkbox"
                          className="form-check-input access-management"
                          checked={slot.is_checked ? true : false}
                          onChange={(e) => handleCheckPickup(e, slot.slot_id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <div className="d-flex align-items-end h-100">
              <div className="w-50">
                <Button
                  variant="transparent"
                  onClick={() => setShowCancelModal(true)}
                >
                  <span className="text-uppercase text-danger fw-bold">
                    Cancel
                  </span>
                </Button>
              </div>
              <div className="d-flex justify-content-end w-50">
                {slots?.locations?.slots
                  .filter((slot) => slot.is_checked)
                  .reduce((total, slot) => total + slot.total_pack, 0) <
                  slots?.total_pack && (
                  <Button className="btn-secondary" disabled>
                    <span>Submit</span>
                  </Button>
                )}
                {slots?.locations?.slots
                  .filter((slot) => slot.is_checked)
                  .reduce((total, slot) => total + slot.total_pack, 0) >=
                  slots?.total_pack && (
                  <Button
                    className="btn-primary-orange"
                    style={{ border: 'none' }}
                    onClick={() => handleSubmit()}
                  >
                    <span>Submit</span>
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ModalCancelSlotLocation
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        prevSlots={prevSlots}
        stateData={state}
      />
    </>
  )
}

export default CreateSjLocation
