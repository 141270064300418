import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { updateCOA } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'

function ModalUpdateItemCoaPrDraftDetail(props) {
  const maxSize = 1242880 // max size file upload
  const { selecteditemcoa } = props
  const title = {
    fontSize: '9px',
    lineHeight: '12px',
    color: '#828282'
  }
  const [file, setFile] = useState(false)
  const fileInputSim = useRef(null)
  const [fileDocCoa, setFileDocCoa] = useState({})

  useEffect(() => {
    selecteditemcoa?.coa !== ''
      ? setFile(selecteditemcoa?.coa_doc?.split('/').pop()) &&
        setFileDocCoa(selecteditemcoa?.coa_doc?.split('/').pop())
      : setFile(false)
  }, [props.show, selecteditemcoa])

  const filehandleFileCoa = (e) => {
    const size = e.target.files[0].size
    const typeFile = e.target.files[0].type

    if (size > maxSize) {
      Common.showPopUpErrorMessage('Cant upload file more than 5 MB')
    } else if (
      typeFile !== 'image/jpg' &&
      typeFile !== 'image/jpeg' &&
      typeFile !== 'image/png' &&
      typeFile !== 'application/pdf'
    ) {
      Common.showPopUpErrorMessage('File upload must jpg/jpeg/png or pdf')
    } else {
      setFile(e.target.files[0].name)
      setFileDocCoa(e.target.files[0])
    }
  }

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData()
      // override data file upload to key docs
      formData.append('coa_file', fileDocCoa)
      formData.append('coa_pd', values.coa_pd)
      formData.append('coa_ed', values.coa_ed)
      formData.append('label_ed', values.label_ed)
      formData.append('label_pd', values.label_pd)
      const res = await api.postForm(
        `/inbound/pr-draft/coa/${selecteditemcoa.id}`,
        formData
      )

      if (res.data.success) {
        Common.showPopUpSuccessMessage(res.data.message)
        props.onHide()
        props.refreshdata()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update COA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            id: selecteditemcoa.id,
            coa_pd: selecteditemcoa.coa_pd,
            coa_ed: selecteditemcoa.coa_ed,
            label_ed: selecteditemcoa.label_ed,
            label_pd: selecteditemcoa.label_pd
          }}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={updateCOA}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values)
            resetForm()
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="fw-bold">
                      <h4>{selecteditemcoa?.item}</h4>
                      <small className="text-muted">
                        {selecteditemcoa?.batch}
                      </small>
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Row>
                      <Col md={12}>
                        <Form.Label className="fw-bold">
                          <small style={title}>
                            COA<sup style={{ color: 'red' }}>*</sup>
                          </small>
                        </Form.Label>
                      </Col>
                      <Col>
                        <Row>
                          {file !== false ? (
                            ''
                          ) : (
                            <Col md={2}>
                              <label
                                htmlFor="file"
                                onClick={() => fileInputSim.current.click()}
                              >
                                <FontAwesomeIcon
                                  icon={faPlusSquare}
                                  className="fa-2x"
                                  style={{ cursor: 'pointer' }}
                                />
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                onChange={filehandleFileCoa}
                                ref={fileInputSim}
                                hidden={true}
                              />
                            </Col>
                          )}
                          <Col
                            md={file !== false ? 10 : 12}
                            className="shadow-sm bg-white rounded"
                          >
                            <FontAwesomeIcon
                              icon={file !== false ? faXmark : faPlusSquare}
                              className="fa-2x me-3"
                              style={{ cursor: 'pointer' }}
                              hidden={file === false ? true : false}
                              onClick={() => {
                                setFile(false)
                                setFileDocCoa(false)
                              }}
                            />
                            <small style={{ fontSize: '12px' }}>{file}</small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Row>
                      <TextField
                        labelsm="md"
                        name="coa_pd"
                        value={values.coa_pd}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        type="date"
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'uncheck'
                            ? true
                            : false
                        }
                        col={6}
                      />
                      <TextField
                        labelsm="ed"
                        name="coa_ed"
                        value={values.coa_ed}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        type="date"
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'uncheck'
                            ? true
                            : false
                        }
                        col={6}
                      />
                      <TextField
                        labelsm="md"
                        name="label_pd"
                        value={values.label_pd}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        type="date"
                        col={6}
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'uncheck'
                            ? true
                            : false
                        }
                      />
                      <TextField
                        labelsm="ed"
                        name="label_ed"
                        value={values.label_ed}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        type="date"
                        col={6}
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'uncheck'
                            ? true
                            : false
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !file}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateItemCoaPrDraftDetail
