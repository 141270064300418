import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Accordion, Card, Col, Row, Table } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { BadgeStatusSj } from '../../../components/atoms/StatusLabel'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import {
  convertDateFormat,
  formatDateDayMonthYear
} from '../../../utils/FormatDate'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'

const tableSecondarySj = {
  borderColor: 'transparent'
}

const labelFormStyle = {
  fontSize: '12px'
}

const InboundHistoryDetail = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [inboundHistoryDetail, setInboundHistoryDetail] = useState({})
  const { po_info, arrival_info, vehicle, item_coa, inbounds, receiptDetail } =
    inboundHistoryDetail
  useEffect(() => {
    if (!state) {
      return navigate(-1)
    }
    fetchInboundHistoryDetail(state.code)
  }, [state])
  // event handler
  const fetchInboundHistoryDetail = async (prId) => {
    try {
      setIsLoading(true)
      const response = await api.get(`inbound/history/${prId}`)
      if (response.data.success) {
        setInboundHistoryDetail(response.data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className="mb-5">
      {isLoading && JSON.stringify(inboundHistoryDetail) === '{}' && (
        <div className="text-center">
          <Loader />
        </div>
      )}
      {!isLoading && JSON.stringify(inboundHistoryDetail) !== '{}' && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-10">
                <Breadcrumb
                  label={po_info.pr_code_erp ?? po_info.po_code}
                  link="/inbound/history"
                  back="INBOUND HISTORY"
                />
              </div>
            </div>
            <div className="row">
              <AccordionComponent>
                <Accordion.Header>PO INFO</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={2}>
                      <Label label="#po" value={po_info.po_code} />
                    </Col>
                    <Col md={2}>
                      <Label
                        label="po type"
                        value={firstLetterUpperCase(po_info.pr_type)}
                      />
                    </Col>
                    <Col md={4}>
                      <Label label="branch" value={po_info.branch ?? '-'} />
                    </Col>
                    <Col md={4}>
                      <Label label="supplier" value={po_info.supplier ?? '-'} />
                    </Col>
                    <Col md={6}>
                      <Label
                        label="accepted warehouse"
                        value={po_info.acc_warehouse ?? '-'}
                      />
                    </Col>
                    <Col md={6}>
                      <Label
                        label="pr destination"
                        value={po_info.dest_warehouse ?? '-'}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </AccordionComponent>
            </div>
            <div className="row mt-4">
              <AccordionComponent>
                <Accordion.Header>ARRIVAL INFO</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={3}>
                      <Label label="#do" value={arrival_info.do} />
                    </Col>
                    <Col md={3}>
                      <Label
                        label="pib date"
                        value={formatDateDayMonthYear(
                          arrival_info.pib_date,
                          '-'
                        )}
                      />
                    </Col>
                    <Col md={3}>
                      <Label
                        label="expedition"
                        value={arrival_info.expedition}
                      />
                    </Col>
                    <Col md={3}>
                      <Label label="#aju" value={arrival_info.aju} />
                    </Col>
                    <Col md={3}>
                      <Label label="pic name" value={arrival_info.pic_name} />
                    </Col>
                    <Col md={3}>
                      <Label
                        label="pic number"
                        value={arrival_info.pic_number}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </AccordionComponent>
            </div>
            <div className="row mt-4">
              <AccordionComponent>
                <Accordion.Header>VEHICLE</Accordion.Header>
                <Accordion.Body>
                  <Table
                    responsive="sm"
                    className="shadow-sm bg-white rounded"
                    size="sm"
                    hover
                    style={{ fontSize: '14px' }}
                  >
                    <thead className="table-secondary" style={tableSecondarySj}>
                      <tr>
                        <th className="p-2 align-middle">No</th>
                        <th className="p-2 align-middle">Plate No</th>
                        <th className="p-2 align-middle">SIM</th>
                        <th className="p-2 align-middle">Validity</th>
                        <th className="p-2 align-middle">Notes</th>
                        <th className="p-2 align-middle">STNK</th>
                        <th className="p-2 align-middle">Validity</th>
                        <th className="p-2 align-middle">Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicle.length === 0 ? (
                        <RowEmpty colSpan={7} />
                      ) : (
                        vehicle.map((data, index) => (
                          <tr key={index}>
                            <td className="p-2 align-middle">{index + 1}</td>
                            <td className="p-2 align-middle">
                              {data.plate_no || '-'}
                            </td>
                            <td className="p-2 align-middle">
                              <Link
                                to={data.sim_doc || '#'}
                                className="text-decoration-none"
                              >
                                {data.sim_doc
                                  ? data.sim_doc.split('/').pop()
                                  : '-'}
                              </Link>
                            </td>
                            <td className="p-2 align-middle">
                              {data.sim_validity ? 'Valid' : 'Invalid'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.sim_notes ? (
                                <p className="mt-3">{data.sim_notes}</p>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className="p-2 align-middle">
                              <Link
                                to={data.stnk_doc || '#'}
                                className="text-decoration-none"
                              >
                                {data.stnk_doc
                                  ? data.stnk_doc.split('/').pop()
                                  : '-'}
                              </Link>
                            </td>
                            <td className="p-2 align-middle">
                              {data.stnk_validity ? 'Valid' : 'Invalid'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.stnk_notes ? (
                                <p className="mt-3">{data.stnk_notes}</p>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </AccordionComponent>
            </div>
            <div className="row mt-4">
              <AccordionComponent>
                <Accordion.Header>ITEM & COA</Accordion.Header>
                <Accordion.Body>
                  <Table
                    responsive="sm"
                    className="shadow-sm bg-white rounded"
                    size="sm"
                    hover
                    style={{ fontSize: '14px' }}
                  >
                    <thead className="table-secondary" style={tableSecondarySj}>
                      <tr>
                        <th className="p-2 align-middle">No</th>
                        <th className="p-2 align-middle">Item</th>
                        <th className="p-2 align-middle">Batch</th>
                        <th className="p-2 align-middle">Total Pack</th>
                        <th className="p-2 align-middle">Total Qty</th>
                        <th className="p-2 align-middle">COA Document</th>
                        <th className="p-2 align-middle">COA PD</th>
                        <th className="p-2 align-middle">COA ED</th>
                        <th className="p-2 align-middle">LABEL PD</th>
                        <th className="p-2 align-middle">LABEL ED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item_coa.length === 0 && <RowEmpty colSpan={7} />}
                      {item_coa.length > 0 &&
                        item_coa.map((data, index) => (
                          <tr key={index}>
                            <td className="p-2 align-middle">{index + 1}</td>
                            <td className="p-2 align-middle">
                              {data.item_name}
                            </td>
                            <td className="p-2 align-middle">{data.batch}</td>
                            <td className="p-2 align-middle">
                              {data.total_pack
                                ? `${data.total_pack} ${data.uom}`
                                : '-'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.total_qty
                                ? `${data.total_qty} ${data.uom}`
                                : '-'}
                            </td>
                            <td className="p-2 align-middle">
                              <Link
                                to={data.coa_doc}
                                className="text-decoration-none"
                              >
                                {data.coa_doc
                                  ? data.coa_doc.split('/').pop()
                                  : ''}
                              </Link>
                            </td>
                            <td className="p-2 align-middle">
                              {data.coa_pd
                                ? formatDateDayMonthYear(data.coa_pd, '-')
                                : '-'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.coa_ed
                                ? formatDateDayMonthYear(data.coa_ed, '-')
                                : '-'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.label_pd
                                ? formatDateDayMonthYear(data.label_pd, '-')
                                : '-'}
                            </td>
                            <td className="p-2 align-middle">
                              {data.label_ed
                                ? formatDateDayMonthYear(data.label_ed, '-')
                                : '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </AccordionComponent>
            </div>
          </div>
          <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
          <div className="container mt-4">
            {inbounds.length === 0 && (
              <p className="text-center fw-bold">No Data Inbound</p>
            )}
            {inbounds.length > 0 &&
              inbounds.map((inbound, index) => (
                <ListInbound key={inbound.id} inbound={inbound} />
              ))}
          </div>
          <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12">
                <h6 className="fw-bold">ITEM RECEIPT DETAILS</h6>
                <Table
                  responsive="sm"
                  hover
                  className="shadow-sm bg-white rounded mt-3"
                  size="sm"
                  style={{ fontSize: '13px', width: '79vw' }}
                >
                  <thead className="table-secondary" style={tableSecondarySj}>
                    <tr>
                      <th className="p-2 align-middle">Item</th>
                      <th className="p-2 align-middle">Batch</th>
                      <th className="p-2 align-middle">DO Pack</th>
                      <th className="p-2 align-middle">DO Qty</th>
                      <th className="p-2 align-middle">Accepted Pack</th>
                      <th className="p-2 align-middle">Accepted Qty</th>
                      <th className="p-2 align-middle">Quarantine Pack</th>
                      <th className="p-2 align-middle">Quarantine Qty</th>
                      <th className="p-2 align-middle">Rejected Pack</th>
                      <th className="p-2 align-middle">Rejected Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptDetail.length === 0 && <RowEmpty colSpan={10} />}
                    {receiptDetail.length > 0 &&
                      receiptDetail.map((data, index) => (
                        <tr key={index}>
                          <td className="p-2 align-middle">{data.item}</td>
                          <td className="p-2 align-middle">{data.batch}</td>
                          <td className="p-2 align-middle">{data.do_pack}</td>
                          <td className="p-2 align-middle">{data.do_qty}</td>
                          <td className="p-2 align-middle">{data.acc_pack}</td>
                          <td className="p-2 align-middle">{data.acc_qty}</td>
                          <td className="p-2 align-middle">
                            {data.quarantine_pack}
                          </td>
                          <td className="p-2 align-middle">
                            {data.quarantine_qty}
                          </td>
                          <td className="p-2 align-middle">
                            {data.damaged_pack}
                          </td>
                          <td className="p-2 align-middle">
                            {data.damaged_qty}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary-orange">
                    Send to ERP
                  </button>
                </div>
              </div>
              <div className="col-12 mt-5">
                <Card>
                  <Card.Body>
                    <Row>
                      <Label
                        label="created by"
                        value={po_info.createdBy ?? '-'}
                        col={3}
                      />
                      <Label
                        label="created at"
                        value={
                          po_info.createdAt
                            ? convertDateFormat(po_info.createdAt)
                            : '-'
                        }
                        col={3}
                      />
                      <Label
                        label="last updated by"
                        value={po_info.updatedBy ?? '-'}
                        col={3}
                      />
                      <Label
                        label="last updated at"
                        value={
                          po_info.updatedAt
                            ? convertDateFormat(po_info.updatedAt)
                            : '-'
                        }
                        col={3}
                      />
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
function AccordionComponent({ children }) {
  return (
    <>
      <Accordion
        className="inbound-history"
        defaultActiveKey={['0']}
        alwaysOpen
      >
        <Accordion.Item eventKey="0">{children}</Accordion.Item>
      </Accordion>
    </>
  )
}
function ListInbound({ inbound }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex justify-content-end">
          <h6 className="fw-bold" style={{ marginRight: '3rem' }}>
            {inbound.plate_no} •{''}
            {inbound.rnr && `${firstLetterUpperCase(inbound.rnr)}`}
            {inbound.pnp && `, ${firstLetterUpperCase(inbound.pnp)}`}
            {inbound.mover && `, ${firstLetterUpperCase(inbound.mover)}`}
          </h6>
          {/* <h6 className="fw-bold" style={{ marginRight: '3rem' }}>
            {inbound.plate_no} • {firstLetterUpperCase(inbound.rnr)},{' '}
            {firstLetterUpperCase(inbound.pnp)}, {''}{' '}
            {inbound.mover ? firstLetterUpperCase(inbound.mover) : ''}
          </h6> */}
        </div>
      </div>
      <div className="col-12">
        <h5>
          <FontAwesomeIcon icon={faHistory} style={{ color: '#828282' }} />
          <span className="ml-2">{inbound.ticket_id}</span>
        </h5>
      </div>
      {inbound.stagings.map((staging, index) => (
        <ListStagingArea key={index} staging={staging} />
      ))}
    </div>
  )
}
function ListStagingArea({ staging }) {
  const tableSecondarySj = {
    borderColor: 'transparent'
  }
  return (
    <div className="col-12 mt-4">
      <h6 className="fw-bold">{staging.name}</h6>
      <Table
        responsive="sm"
        className="shadow-sm bg-white rounded mt-3"
        size="sm"
        hover
        style={{ fontSize: '14px', width: '79vw' }}
      >
        <thead className="table-secondary" style={tableSecondarySj}>
          <tr>
            <th className="p-2 align-middle">Area Block</th>
            <th className="p-2 align-middle">Item</th>
            <th className="p-2 align-middle">Batch</th>
            <th className="p-2 align-middle">Pack</th>
            <th className="p-2 align-middle">Condition</th>
            <th className="p-2 align-middle">Currenct Location</th>
            <th className="p-2 align-middle">Destination Area</th>
          </tr>
        </thead>
        <tbody>
          {staging.items.length === 0 && <RowEmpty colSpan={7} />}
          {staging.items.length > 0 &&
            staging.items.map((item, index) => (
              <tr key={index}>
                <td className="p-2 align-middle">{item.block}</td>
                <td className="p-2 align-middle">{item.item_name}</td>
                <td className="p-2 align-middle">{item.batch}</td>
                <td className="p-2 align-middle">
                  {item.total_pack ? `${item.total_pack} ${item.uom}` : '-'}
                </td>
                <td className="p-2 align-middle">
                  <BadgeStatusSj
                    text={item.condition === 'good' ? 'baik' : 'rusak'}
                    bgColor={item.condition === 'good' ? 'success' : 'danger'}
                  />
                </td>
                <td className="p-2 align-middle">
                  {item.current_location ?? '-'}
                </td>
                <td className="p-2 align-middle">{item.dest_area}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}
export default InboundHistoryDetail
