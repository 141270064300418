import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../../config/AxiosInstance'
import * as Common from '../../../../utils/Common'
import { MapEditorStagingAreaSchema } from '../../../../utils/validationSchema'
import CustomButton from '../../../Button/CustomButton'

function FormUpdateStagingArea(props) {
  const [stagings, setStagings] = useState([])
  const [blocks, setBlocks] = useState([])
  const [detail, setDetail] = useState({})
  useEffect(() => {
    if (props?.warehouseId && props?.show) {
      fetchShowMapData(props?.dataShowMap)
      fetchStagings(props.warehouseId)
    }
  }, [props.warehouseId])

  useEffect(() => {
    handleSelectStaging(detail?.staging_id)
  }, [detail])

  const fetchShowMapData = async (id) => {
    try {
      const res = await api.get(`/settings/map/editor/staging/${id}`)
      if (res.data.success) {
        setDetail(res.data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchStagings = async (warehouseId, controller) => {
    try {
      // read data warehouse
      const resWarehouse = await api.get(`settings/map/editor/${warehouseId}`)

      if (resWarehouse.data.data) {
        const res = await api.get(`master/stagings/show`, {
          params: {
            dest_warehouse: resWarehouse.data.data.warehouse_id
          }
        })
        const optionValue = []
        if (res.data.data.length > 0) {
          res.data.data.map((staging) =>
            optionValue.push({
              value: staging.id,
              label: staging.name
            })
          )
          setStagings(optionValue)
        }
      }
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  const handleSelectStaging = async (e) => {
    try {
      if (!e) {
        return
      }
      // read data block
      const res = await api.get(`master/stagings/${detail.staging_id}/blocks`)
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((block) =>
          optionValue.push({
            value: block.id,
            label: block.block_num
          })
        )
        setBlocks(optionValue)
      }
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }
  return (
    <Formik
      initialValues={{
        staging_id: stagings.find(
          (staging) => staging.value === detail.staging_id
        ),
        blok_id: blocks.find((block) => block.label === detail.block_num)
      }}
      enableReinitialize={true}
      validationSchema={MapEditorStagingAreaSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => {
        props.onHandleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched
      }) => {
        return (
          <Form className="d-grid gap-2" onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <p className="text-muted text-uppercase fw-bold">
                  Blok Detail <sup style={{ color: 'red' }}>*</sup>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className="text-muted text-uppercase"
                    style={{ fontSize: '13px' }}
                  >
                    Staging Area
                  </Form.Label>
                  <Select
                    options={stagings}
                    onBlur={() => {
                      setFieldTouched('staging_id')
                    }}
                    // onInputChange={handleInputRackChange}
                    onChange={(e) => {
                      setFieldValue('staging_id', e)
                      handleSelectStaging(e)
                    }}
                    value={
                      JSON.stringify(values.staging_id) === '{}'
                        ? null
                        : values.staging_id
                    }
                    placeholder="Search Staging..."
                    isClearable
                    isSearchable
                    isDisabled={true}
                  />
                  {touched.staging_id && !!errors.staging_id && (
                    <p className="text-danger mt-2">{errors.staging_id}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className="text-muted text-uppercase"
                    style={{ fontSize: '13px' }}
                  >
                    BLOCK
                  </Form.Label>
                  <Form.Select
                    aria-label="block_num"
                    disabled={true}
                    style={{ height: '38px' }}
                  >
                    <option value={detail.block_num}>{detail.block_num}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer className="mt-3">
              <CustomButton
                text={'Remove'}
                variant={'white'}
                classNameValue={'text-black'}
                onClick={() => props.handleRemoveCoordinateMap(detail.id)}
              />
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormUpdateStagingArea
