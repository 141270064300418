import { Form, Row, Modal, Col } from 'react-bootstrap'
import * as Common from '../../../utils/Common'
import { Formik } from 'formik'
import api from '../../../config/AxiosInstance'
import { UserAddSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'

function ModalAddUser(props) {
  const handleSubmit = async (values) => {
    try {
      const response = await api.post('/users/add', values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          validationSchema={UserAddSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      id="username"
                      label="username"
                      name="username"
                      value={values.username}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                      placeholder="Input"
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      id="password"
                      label="PASSWORD"
                      name="password"
                      value={values.password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                      placeholder="******"
                      type="password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Add'}
                    />
                  </Modal.Footer>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddUser
