import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, ModalHeader, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { AreaAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalUpdateArea(props) {
  const [selectedData, setSelectedData] = useState({
    area_name: '',
    code: ''
  })
  const [loading, setLoading] = useState(true)

  const loadData = async (id) => {
    setLoading(true)
    try {
      const res = await api.get(`/master/area/${id}`)
      setSelectedData({
        area_name: res.data.data.name,
        code: res.data.data.code
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) loadData(props.id)
  }, [props.id, props.show])

  const handleSubmit = async (values, actions) => {
    try {
      const res = await api.post(`/master/area/${props.id}`, values)
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <ModalHeader closeButton>
        <Modal.Title>Update Area</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <Formik
            initialValues={
              !selectedData
                ? {
                    code: '',
                    area_name: ''
                  }
                : {
                    area_name: selectedData.area_name,
                    code: selectedData.code
                  }
            }
            enableReinitialize={true}
            validationSchema={AreaAddEditSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              isValid,
              dirty,
              touched
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={4}>
                      <TextField
                        label="code"
                        name="code"
                        value={values.code}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        disabled={true}
                        placeholder="Enter code"
                      />
                    </Col>
                    <Col md={8}>
                      <TextField
                        label="name"
                        name="area_name"
                        value={values.area_name}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                        placeholder="Enter name"
                      />
                    </Col>
                  </Row>
                  <Modal.Footer>
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Update'}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateArea
