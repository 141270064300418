import { Modal, Form, Col, Row } from 'react-bootstrap'
import React from 'react'
import * as Common from '../../../utils/Common'
import { Formik } from 'formik'
import api from '../../../config/AxiosInstance'
import { expeditionRentAddEditSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Inactive' }
]

function ModalAddRent(props) {
  const handleSubmit = async (values) => {
    try {
      const response = await api.post('/master/expedition/add', values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Rent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            address: '',
            status: 'true',
            type: 'false'
          }}
          validationSchema={expeditionRentAddEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="name"
                      name="name"
                      value={values.name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input Name"
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="phone number"
                      name="phone"
                      value={values.phone}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input Phone Number"
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                      label="address"
                      name="address"
                      value={values.address}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input Address"
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <SelectInput
                      label="Status"
                      name="status"
                      id="status"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={statuses}
                      required={true}
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Add'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddRent
