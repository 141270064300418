import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import '../../../styles/style.css'
import api from '../../../config/AxiosInstance'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import * as Common from '../../../utils/Common'
import { convertDateFormat } from '../../../utils/FormatDate'
import Pagination from '../../../components/atoms/Pagination/Index'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useRef } from 'react'
import RowEmpty from '../../../components/atoms/RowEmpty'
import Select from 'react-select'
import { DELIVERY_TYPES } from '../../../helpers/constants'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

function Index() {
  const [isLoading, setIsLoading] = useState(false)
  const [prDraftList, setPrDraftList] = useState([])
  const [driver, setDriver] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [poName, setPoName] = useState(0)

  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const refSrcWarehouses = useRef()
  const [selectedCustomers, setSelectedCustomers] = useState('')
  const [customers, setCustomers] = useState([])
  const refSrcCustomers = useRef()
  const [selectedSjDraft, setSelectedSjDraft] = useState('')
  const [sjDraft, setSjDraft] = useState([])
  const refSrcSjDraft = useRef()
  const [selectedSo, setSelectedSo] = useState('')
  const [so, setSo] = useState([])
  const refSrcSo = useRef()
  const [deliveryType, setDeliveryType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const { per_page, current_page } = meta
  useEffect(() => {
    fetchDcList(
      per_page,
      current_page,
      selectedWarehouse,
      selectedCustomers,
      selectedSjDraft,
      selectedSo,
      startDate,
      endDate,
      deliveryType,
      driver
    )
  }, [per_page, current_page])

  useEffect(() => {
    getWarehouses()
    getCustomers()
    getSjDraft()
    getSo()
  }, [])

  const getWarehouses = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/erp/sales-order/all-source-wr')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.warehouse, label: data.warehouse })
      )
      setWarehouses(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getCustomers = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/erp/sales-order/all-customer')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.customer, label: data.customer })
      )
      setCustomers(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getSjDraft = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/outbound/sjdraft/all-draft')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.draft_code, label: data.draft_code })
      )
      setSjDraft(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getSo = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/erp/sales-order/all')
      const { data } = res.data
      data.map((data) => options.push({ value: data.so, label: data.so }))
      setSo(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  async function fetchDcList(
    per_page,
    current_page,
    selectedWarehouse,
    selectedCustomers,
    selectedSjDraft,
    selectedSo,
    startDate,
    endDate,
    deliveryType,
    driver
  ) {
    setIsLoading(true)
    try {
      const res = await api.get(
        `outbound/dcdrafts/list?per_page=${per_page}&page=${current_page}&src=${selectedWarehouse}&customer=${selectedCustomers}&erp_sj_no=${selectedSjDraft}&so=${selectedSo}&start_date=${startDate}&end_date=${endDate}&delivery_type=${deliveryType}&driver=${driver}`
      )
      const { metadata, data } = res.data
      setPrDraftList(data)
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  // handle page
  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({ ...prevMeta, per_page: perPageValue }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const navigate = useNavigate()
  const navigateToDetail = (dc) => {
    const encodedName = encodeURIComponent(dc.dc_id)
    navigate(`/outbound/dc/${encodedName}`)
  }

  // handle selected
  async function handleSelected() {
    setIsLoading(true)
    try {
      const page = 1
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: page
      }))
      await fetchDcList(
        per_page,
        current_page,
        selectedWarehouse,
        selectedCustomers,
        selectedSjDraft,
        selectedSo,
        startDate,
        endDate,
        deliveryType,
        driver
      )
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  function handleReset() {
    setMeta({
      per_page: 10,
      current_page: 1,
      total_row: 0,
      total_page: 0
    })
    refSrcWarehouses.current?.clearValue()
    refSrcCustomers.current?.clearValue()
    refSrcSo.current?.clearValue()
    refSrcSjDraft.current?.clearValue()
    setStartDate('')
    setEndDate('')
    setDeliveryType('')
    setDriver('')
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={6}>
            <h3 style={headerTitleStyle}>Delivery Confirmation (DC) Draft</h3>
          </Col>
        </Row>
        {/* FILTER */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>SOURCE</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedWarehouse}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcWarehouses,
                      value: ''
                    }
                  }
                  setSelectedWarehouse(e?.value)
                }}
                options={warehouses}
                isSearchable
                ref={refSrcWarehouses}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>CUSTOMER</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedCustomers}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcCustomers,
                      value: ''
                    }
                  }
                  setSelectedCustomers(e?.value)
                }}
                options={customers}
                isSearchable
                ref={refSrcCustomers}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#ERP SJ NO.</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedSo}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcSo,
                      value: ''
                    }
                  }
                  setSelectedSo(e?.value)
                }}
                options={so}
                isSearchable
                ref={refSrcSo}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mb-4">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#SO</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedSjDraft}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcSjDraft,
                      value: ''
                    }
                  }
                  setSelectedSjDraft(e?.value)
                }}
                options={sjDraft}
                isSearchable
                ref={refSrcSjDraft}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>START DATE</small>
            </Form.Label>
            <Form.Control
              type="date"
              name="start_date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
            />
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>END DATE</small>
              </Form.Label>
              <Form.Control
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DELIVERY TYPE</small>
              </Form.Label>
              <Form.Select
                aria-label="Select Warehouse"
                name="delivery_type"
                id="delivery_type"
                onChange={(e) => setDeliveryType(e.target.value)}
                value={deliveryType}
              >
                <option value="">All</option>
                {DELIVERY_TYPES.map((devType, index) => (
                  <option value={devType.value} key={devType.id}>
                    {devType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>DRIVER</small>
            </Form.Label>
            <Form.Control
              type="text"
              name="driver"
              id="driver"
              placeholder="Input"
              onChange={(e) => setDriver(e.target.value)}
              value={driver}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="text-end">
            <Button
              type="button"
              className="btn btn-secondary text-white me-2"
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
            <Button onClick={() => handleSelected()} style={btnOrange}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              responsive="sm"
              className="shadow-sm mb-5"
              style={{ fontSize: '13px' }}
              hover
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle ">Act</th>
                  <th className="p-2 align-middle">WMS DC No.</th>
                  <th className="p-2 align-middle">ERP SJ No.</th>
                  <th className="p-2 align-middle">ERP DC No.</th>
                  <th className="p-2 align-middle">#SO</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Customer</th>
                  <th className="p-2 align-middle">Source</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Delivery Date</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={5}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!isLoading && prDraftList.length === 0 && (
                  <RowEmpty colSpan={5} />
                )}
                {!isLoading &&
                  prDraftList?.map((data, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>
                            <button
                              type="button"
                              className="btn btn-secondary ml-2"
                              onClick={() => {
                                navigateToDetail(data)
                              }}
                              code={poName}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                          </td>
                          <td>{data.wms_dc_no}</td>
                          <td>{data.erp_sj_no}</td>
                          <td>{data.wms_dc_no}</td>
                          <td>{data.so}</td>
                          <td>{data.driver}</td>
                          <td>{data.customer}</td>
                          <td>{data.src}</td>
                          <td>{data.delivery_type}</td>
                          <td>{convertDateFormat(data.delivery_date)}</td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} className="p-3">
                    <div className="d-flex align-items-center">
                      <span className="mr-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="show-page"
                        style={{ width: '60px' }}
                        value={meta.per_page}
                        onChange={handleOnChangeMeta}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="ml-2">
                        Entries | {meta.current_page} to {meta.per_page} of{' '}
                        {meta.total_row} entries
                      </span>
                    </div>
                  </td>
                  <td colSpan={5} className="p-3">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Index
