import Swal from 'sweetalert2'
import '../styles/style.css'
export function showPopUpErrorMessage(message, isSuccessMessage) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer
      toast.onmouseleave = Swal.resumeTimer
    }
  })

  Toast.fire({
    icon: 'error',
    title: message
  })

  return Toast
}

export function showPopUpSuccessMessage(
  message = 'Success!',
  isSuccessMessage
) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer
      toast.onmouseleave = Swal.resumeTimer
    }
  })

  Toast.fire({
    icon: 'success',
    title: message
  })

  return Toast
}

export function showPopUpErrorMessageApi(
  message = 'Error while trying to connect to Server. Please try again.',
  isSuccessMessage
) {
  return showPopUpErrorMessage(message).fire()
}

export function showPopUpConfirmation(
  message = 'Are you sure?',
  confirm = 'Save',
  cancel = 'Cancel',
  functionValue
) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary-orange',
      cancelButton: 'btn btn-transparent'
    },
    buttonsStyling: false
  })
  return swalWithBootstrapButtons
    .fire({
      title: message,
      showCancelButton: true,
      confirmButtonText: confirm,
      cancelButtonText: cancel,
      reverseButtons: true
    })
    .then((result) => {
      if (result.isConfirmed) {
        Swal.close()
        return false
        // swalWithBootstrapButtons.fire({
        //   title: 'Deleted!',
        //   text: 'Your file has been deleted.',
        //   icon: 'success'
        // })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // Swal.close()
        functionValue()
        return
      }
    })
}
