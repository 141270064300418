import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'react-bootstrap'
import '../../../styles/style.css'
function ModalActionDenied(props) {
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-5">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: 'grey', fontSize: '100px' }}
              className="ml-2"
            />
          </div>
          <div className="text-center mt-3">
            <p>Action Denied</p>
            <p className="fw-bold">
              Please select data with same customer, so and delivery date
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-primary-orange"
            onClick={props.onHide}
            style={{ border: 'none' }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalActionDenied
