import { faClose, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pusher from 'pusher-js'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import styles from '../Outbound/Plan/outbound_plan.module.css'
import NavbarPage from './Navbar'
import Sidebar from './Sidebar'
const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [state] = useContext(UserContext)
  const [note, setNote] = useState(false)
  const [indicator, setIndicator] = useState(false)
  const navigate = useNavigate()
  const pusher = new Pusher('b4fd69a93b89d910f024', {
    cluster: 'ap1'
  })
  var channelNoticationIndicator = pusher.subscribe(
    `notif-om-${state.username?.roles[0].warehouse_id}-indicator`
  )
  var channelNewNotification = pusher.subscribe(
    `notif-om-${state.username?.roles[0].warehouse_id}`
  )
  channelNewNotification.bind(`new-notification`, function (data) {
    setNote(data)
  })
  channelNoticationIndicator.bind(`notification-indicator`, function (data) {
    setIndicator(Boolean(data))
  })

  useEffect(() => {
    if (note) {
      const timeout = setTimeout(() => {
        setNote(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [note])
  return (
    <>
      <Container
        fluid
        className="p-0 min-vh-100"
        style={{ overflowX: 'hidden' }}
      >
        <Row className="min-vh-100">
          {/* {collapsed && (
            <Col xs={2} className="shadow-sm bg-white rounded" fixed="true">
              <Sidebar />
            </Col>
          )} */}
          <Col
            xs={collapsed ? 2 : 1}
            className="shadow-sm bg-white rounded"
            fixed="true"
          >
            <Sidebar collapsed={collapsed} />
          </Col>
          <Col>
            <Row>
              <NavbarPage
                onClick={() => setCollapsed(!collapsed)}
                test={collapsed}
                indicator={indicator}
              />
            </Row>
            <Row className="mt-3 p-3">
              <div
                className={`position-absolute top-30 ${!note ? 'd-none' : ''}`}
                style={{ left: '71%', zIndex: '999' }}
              >
                <Card
                  className={`${styles.errorNotification}`}
                  style={{ cursor: 'pointer', width: '30rem' }}
                >
                  <Card.Body>
                    <Row>
                      <Col md={1}>
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faExclamation}
                            className="mt-1"
                            style={{
                              fontSize: '15px',
                              color: 'white'
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={11}>
                        <Row className="d-flex align-items-center">
                          <Col sm={10}>
                            <p
                              className="text-light ml-4 text-truncate"
                              style={{ width: '80%' }}
                            >
                              {note?.message}
                            </p>
                          </Col>
                          <Col sm={2}>
                            <div className="d-flex flex-row justify-content-end">
                              <div className="d-flex flex-column justify-content-center">
                                <div
                                  className="d-flex flex-row gap-3"
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    marginTop: '-1rem'
                                  }}
                                >
                                  <span
                                    className="text-light"
                                    onClick={() => navigate(note?.link)}
                                  >
                                    View
                                  </span>
                                  <FontAwesomeIcon
                                    icon={faClose}
                                    style={{
                                      fontSize: '12px',
                                      color: 'white',
                                      marginTop: '.3rem',
                                      cursor: 'pointer'
                                    }}
                                    role="button"
                                    onClick={() => setNote(null)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              <Outlet />
            </Row>
            <Row className="p-3">
              <Col md={6} className="text-start">
                <small>@2024 Bahtera Adi Jaya</small>
              </Col>
              <Col md={6} className="text-end">
                <small>
                  Powered by <strong className="text-danger">RAPIER</strong>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Dashboard
