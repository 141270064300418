import { Col } from 'react-bootstrap'

function ActionButton({ children }) {
  return (
    <Col
      md={8}
      className="d-flex flex-row justify-content-end gap-2 align-items-center"
      style={{ height: '30px' }}
    >
      {children}
    </Col>
  )
}

export default ActionButton
