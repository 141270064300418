import { useEffect, useState } from 'react'
import { Col, Container, FormControl, Row, Table } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common.js'
import Loader from '../../atoms/Loader'
import Pagination from '../../atoms/Pagination/Index.jsx'

function MasterAccess() {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [url, setUrl] = useState('')
  const [access, setAccess] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const { per_page, current_page } = meta

  const loadAccess = async (name, code, url, per_page, current_page) => {
    setLoading(true)
    try {
      const res = await api.get(`/cms/access/list`, {
        params: { name, code, url, per_page, page: current_page }
      })
      setLoading(false)

      setMeta(res.data.metadata)
      setAccess(res.data.data)
      setPageCount(res.data.metadata.total_page || 0)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    loadAccess(name, code, url, per_page, current_page)
  }, [name, code, url, per_page, current_page])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  return (
    <Container>
      <h3 style={headerTitleStyle}>Master Access</h3>
      <Row className="mt-3">
        <Col md={4}>
          <FormControl
            type="text"
            onChange={(e) => setCode(e.target.value)}
            placeholder="search code..."
          ></FormControl>
        </Col>
        <Col md={4}>
          <FormControl
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="search name..."
          ></FormControl>
        </Col>
        <Col md={4}>
          <FormControl
            type="text"
            onChange={(e) => setUrl(e.target.value)}
            placeholder="search url..."
          ></FormControl>
        </Col>
      </Row>
      <Row className="mt-3">
        <Table
          responsive="sm"
          hover
          className="shadow-sm bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle">Icon</th>
              <th className="p-2 align-middle">Access Head</th>
              <th className="p-2 align-middle">Access Code</th>
              <th className="p-2 align-middle">Access Name</th>
              <th className="p-2 align-middle">Type</th>
              <th className="p-2 align-middle">URL</th>
              <th className="p-2 align-middle">Menu</th>
              <th className="p-2 align-middle">Sequence</th>
              <th className="p-2 align-middle">Actions</th>
            </tr>
          </thead>
          <thead>
            {loading && (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading &&
              access.map((acc) => {
                return (
                  <tr key={acc.id}>
                    <td className="p-2 align-middle">{acc?.icon}</td>
                    <td className="p-2 align-middle">{acc?.head}</td>
                    <td className="p-2 align-middle">{acc?.code}</td>
                    <td className="p-2 align-middle">{acc?.name}</td>
                    <td className="p-2 align-middle">{acc?.type}</td>
                    <td className="p-2 align-middle">{acc?.slug}</td>
                    <td className="p-2 align-middle">{acc?.sidebar}</td>
                    <td className="p-2 align-middle">{acc?.sequence}</td>
                    <td className="p-2 align-middle text-center">-</td>
                  </tr>
                )
              })}
          </thead>
          <tfoot>
            <tr>
              <td colSpan={5} className="p-3">
                <div className="d-flex">
                  <div className="mt-2 mr-2">Show</div>
                  <select
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '13%' }}
                    value={meta.per_page}
                    onChange={(e) => handleOnChangeMeta(e)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span className="mt-2 ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={4} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    page={current_page}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  )
}

export default MasterAccess
