import {
  faEye,
  faEyeSlash,
  faLock,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import baj_logo from '../../assets/baj_logo.png'
import api from '../../config/AxiosInstance.js'
import { setAuthToken } from '../../config/api'
import { UserContext } from '../../context/userContext'
import * as Common from '../../utils/Common.js'
import { loginSchema } from '../../utils/validationSchema.js'

const Index = () => {
  const [, dispatch] = useContext(UserContext)
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const handleSubmit = async (values) => {
    try {
      const response = await api.post('/users/login', values)

      if (response.data.success) {
        setAuthToken(response.data.data.token)
        dispatch({
          type: 'login',
          payload: response.data.data
        })

        navigate('/dashboard')
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <Container fluid className="Containerx p-5">
        <Stack gap={2} className="col-md-8 mx-auto">
          <Col style={{ marginLeft: '-90px' }}>
            <img src={baj_logo} alt="baj" style={{ width: '30%' }} />
          </Col>
          <Col>
            <strong>
              <small>Warehouse Management System</small>
            </strong>
          </Col>
          <Col xl className="mt-3">
            <Card className="p-3">
              <Card.Body className="p-4">
                <Card.Title className="text-body">
                  <h1>Login</h1>
                </Card.Title>
                <small className="mt-3 text-muted">
                  Sign In to your account
                </small>
                <Formik
                  initialValues={{
                    username: '',
                    password: ''
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values)
                  }}
                >
                  {({
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty
                  }) => {
                    return (
                      <Form className="mt-3" onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Form.Group>
                            <InputGroup>
                              <InputGroup.Text id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faUser} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="username"
                                value={values.username}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={!!errors.username}
                              />
                              {touched.username && !!errors.username && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.username}
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                            <InputGroup className="mt-3">
                              <InputGroup.Text id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faLock} />
                              </InputGroup.Text>
                              <Form.Control
                                type={!showPassword ? 'password' : 'text'}
                                placeholder="Password"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="password"
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.password && errors.password}
                              />
                              <div
                                className="password-toggle-icon position-absolute"
                                onClick={togglePasswordVisibility}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </div>
                            </InputGroup>
                            {touched.password && errors.password && (
                              <p className="text-danger mt-2">
                                {errors.password}
                              </p>
                            )}
                          </Form.Group>
                        </Row>
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          disabled={!isValid || !dirty}
                        >
                          Login
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Stack>
      </Container>
    </>
  )
}

export default Index
