import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../../../components/atoms/Loader/index'
import Pagination from '../../../components/atoms/Pagination/Index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import MapEditorDetail from './Detail'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MapEditor = () => {
  const [page, setPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [metaData, setMetaData] = useState({
    per_page: 0,
    current_page: 0,
    total_row: 0,
    total_page: 0
  })
  const [pageCount, setpageCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [mapEditors, setMapEditors] = useState([])
  const [editData, setEditData] = useState({})
  const [step, setStep] = useState(0)
  const handlePageClick = async (data) => {
    const current_page = data.selected + 1
    setMetaData({
      ...metaData,
      current_page: current_page
    })
    setCurrentPage(current_page)
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    fetchMapEditors(page, currentPage)
  }, [page, currentPage])

  function handleOnChangeMeta(e) {
    setMetaData({
      ...metaData,
      [e.target.name]: e.target.value
    })
    setPage(e.target.value)
  }
  function handleDetailData(id) {
    setEditData(id)
  }
  function handleResetDetailData() {
    setEditData({})
    if (Number(step) === 1) {
      setStep(0)
    }
  }
  function handleStep(value) {
    setStep(value)
  }
  async function fetchMapEditors(page, currentPage) {
    setIsLoading(true)
    try {
      const res = await api.get(
        `/settings/map/editor/warehouse/all?per_page=${page}&page=${currentPage}`
      )

      if (res.data.data.length > 0) {
        setMapEditors(res.data.data)
        setMetaData(res.data.metadata)
        setpageCount(res.data.metadata.total_page || 0)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className="container">
      {Object.keys(editData).length === 0 ? (
        <>
          <h3 style={headerTitleStyle}>Map Editor</h3>
          <div className="row mt-3">
            <div className="col-12">
              <Table
                hover
                className='shadow-sm mb-5 bg-white rounded"'
                size="sm"
                height="150px"
                style={{ fontSize: '14px' }}
              >
                <thead className="table-secondary">
                  <tr>
                    <th className="p-2 align-middle">No</th>
                    <th className="p-2 align-middle">Destination Warehouse</th>
                    <th className="p-2 align-middle text-end" width="15%">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={3}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {!isLoading && mapEditors.length === 0 && (
                    <RowEmpty colSpan={3} />
                  )}
                  {!isLoading &&
                    mapEditors.length > 0 &&
                    mapEditors?.map((mapEditor, i) => (
                      <TbodyRow
                        data={mapEditor}
                        key={mapEditor.id}
                        row={i + 1}
                        onHandleDetailData={() => handleDetailData(mapEditor)}
                      />
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={1} className="p-3">
                      <div className="d-flex align-items-center">
                        <span className="mr-2">Show</span>
                        <select
                          name="per_page"
                          className="form-select"
                          aria-label="show-page"
                          style={{ width: '60px' }}
                          value={page}
                          onChange={(e) => handleOnChangeMeta(e)}
                        >
                          {[10, 25, 50, 100].map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                        <span className="ml-2">
                          Entries | {metaData.current_page} to{' '}
                          {metaData.per_page} of {metaData.total_row} entries
                        </span>
                      </div>
                    </td>
                    <td colSpan={2} className="p-3">
                      <div className="d-flex justify-content-end">
                        <Pagination
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                        />
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </>
      ) : (
        <>
          <MapEditorDetail
            data={editData}
            step={step}
            onHandleResetDetailData={() => handleResetDetailData()}
            onHandleStep={handleStep}
          />
        </>
      )}
    </div>
  )
}

function TbodyRow({ data, onHandleDetailData, row }) {
  const iconStyle = {
    transform: 'rotate(0deg)',
    fontSize: '12px'
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{row}</td>
      <td className="p-2 align-middle">{data.warehouse_name}</td>
      <td className="p-2 align-middle text-end">
        <button
          className="btn btn-primary-orange btn-sm"
          onClick={() => onHandleDetailData()}
        >
          <div className="d-flex flex-row gap-2">
            <div className="d-flex flex-column justify-content-center items-content-center">
              <FontAwesomeIcon
                icon={faPencil}
                className="fa-flip-horizontal"
                style={iconStyle}
              />
            </div>
            <span className="ml-2">Update Map</span>
          </div>
        </button>
      </td>
    </tr>
  )
}
export default MapEditor
