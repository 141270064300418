/**
 *
 * @param {*} number
 * @returns
 */
export function formatThousandSeparator(number = 0) {
  return new Intl.NumberFormat('id-ID', {
    currency: 'IDR'
  }).format(number)
}
export function firstLetterUpperCase(text = '') {
  return text.toLowerCase().replace(/(?:^|\s)\S/g, function (str) {
    return str.toUpperCase()
  })
}
