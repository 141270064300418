import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import api from '../../../config/AxiosInstance.js'
import * as Common from '../../../utils/Common.js'
import { UpdateSoSjSchema } from '../../../utils/validationSchema.js'
import CustomButton from '../../Button/CustomButton'
import SelectInput from '../../Input/SelectInput'
import TextField from '../../Input/TextInput'
import Select from 'react-select'

function ModalUpdatePickupCommand(props) {
  // state
  const [selectBatch, setSelectBatch] = useState({})
  const [errorPack, setErrorPack] = useState({
    error: false,
    message: ''
  })
  const [detailDataPickupCommand, setDetailDataPickupCommand] = useState({
    item_name: '',
    item_code: '',
    item_alias: '',
    batch: '',
    uom: '',
    total_pack: 0
  })
  const [detailSoRequest, setDetailSoRequest] = useState({
    batches: [],
    conversion_factor: 0
  })
  const [availablePack, setAvailablePack] = useState(0)
  const { item_name, item_code, item_alias, batch, uom, total_pack } =
    detailDataPickupCommand
  const { batches, conversion_factor } = detailSoRequest

  const searchBatchId = batches?.filter(
    (batchValue) => batchValue.batch === batch
  )[0]

  const batchItems = batches?.reduce((acc, batch) => {
    acc.push({ value: batch.item_batch_id, label: batch.batch })
    return acc
  }, [])

  useEffect(() => {
    if (props.show) {
      setDetailDataPickupCommand(
        props?.pickupCommands?.filter(
          (pickupCommand) => pickupCommand.id === props?.pickupCommandId
        )[0]
      )
      setDetailSoRequest(
        props?.soRequests?.filter(
          (soRequest) =>
            soRequest.item_code ===
            props?.pickupCommands?.filter(
              (pickupCommand) => pickupCommand.id === props?.pickupCommandId
            )[0].item_code
        )[0]
      )
      if (errorPack.error) {
        setErrorPack({
          error: false,
          message: ''
        })
      }
    }
  }, [props.show])

  useEffect(() => {
    if (detailSoRequest) {
      setSelectBatch(searchBatchId)
      setAvailablePack(
        searchBatchId?.slots.reduce((acc, batch) => acc + batch.total_pack, 0)
      )
    }
  }, [detailSoRequest])
  const handleSubmit = async (values, actions) => {
    try {
      const updateValues = {
        item_alias: values.item_alias,
        item_code: values.item,
        item_name,
        uom,
        item_batch_id: values.batch,
        batch: selectBatch?.batch,
        exp_date: selectBatch?.exp_date,
        total_pack: values.pack,
        total_qty: values.pack * conversion_factor
      }
      const res = await api.post('/erp/sales-order/check_slot', updateValues)
      if (res.status === 200) {
        props.onHandleUpdatePickupCommands(
          props?.pickupCommandId,
          res.data.data
        )
        props.onHide()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleInputPack = (value) => {
    const qtyItemSoRequest =
      props?.soRequests.filter(
        (soRequest) => soRequest.item_code === detailDataPickupCommand.item_code
      )[0]?.available_qty ?? 0
    const qtyPickupCommand =
      props?.pickupCommands.filter(
        (pickupCommand) =>
          pickupCommand.item_code === detailDataPickupCommand.item_code &&
          pickupCommand.id !== detailDataPickupCommand.id
      )[0]?.available_qty ?? 0
    if (
      value * detailSoRequest.conversion_factor >
      qtyItemSoRequest - qtyPickupCommand
    ) {
      setErrorPack({
        error: true,
        message: `Your input ${value} more than ${
          qtyItemSoRequest - qtyPickupCommand
        } available and available total pack on batch ${
          availablePack * detailSoRequest.conversion_factor
        }, please input less than equal ${
          qtyItemSoRequest - qtyPickupCommand
        } available`
      })
      return
    }
    if (availablePack < value) {
      setErrorPack({
        error: true,
        message: `${availablePack} available pack`
      })
    } else {
      setErrorPack({
        error: false,
        message: ''
      })
    }
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Update Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            item: item_code,
            item_alias: item_alias,
            batch: searchBatchId?.item_batch_id,
            pack: Number(total_pack)
          }}
          enableReinitialize={true}
          validationSchema={UpdateSoSjSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <div className="col-12">
                  <Form.Label className="fw-normal">
                    <small className="text-uppercase">Item</small>
                  </Form.Label>
                  <Form.Select aria-label="item" name="item" disabled={true}>
                    <option value={item_code}>{item_name}</option>
                  </Form.Select>
                </div>
                <div className="col-12">
                  <Form.Label className="fw-normal">
                    <small className="text-uppercase">Item Alias</small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={values.item_alias}
                    disabled={true}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    {/* <SelectInput
                      label="Batch"
                      name="batch"
                      id="batch"
                      value={batchItems.find(
                        (option) => option.value === values.batch
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('batch', selectedOption?.value)
                        setSelectBatch(
                          selectedOption
                            ? batches?.filter(
                                (batch) =>
                                  batch.item_batch_id === selectedOption?.value
                              )[0]
                            : {}
                        )
                      }}
                      errors={errors}
                      options={batchItems}
                      bold
                    /> */}
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Batch</small>
                    </Form.Label>
                    <Select
                      options={batchItems}
                      value={batchItems.find(
                        (option) => option.value === values.batch
                      )}
                      onChange={(selectedOption) => {
                        setFieldValue('batch', selectedOption?.value)
                        setSelectBatch(
                          selectedOption
                            ? batches?.filter(
                                (batch) =>
                                  batch.item_batch_id === selectedOption?.value
                              )[0]
                            : {}
                        )
                      }}
                      isClearable
                      isSearchable
                    />
                    {JSON.stringify(selectBatch) === '{}' && !!errors.batch && (
                      <p className="text-danger mt-2">{errors.batch}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">EXP Date</small>
                    </Form.Label>
                    <Form.Control type="text" disabled={true} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <TextField
                      id="pack"
                      label="Pack"
                      name="pack"
                      value={values.pack}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      isInvalid={touched.pack && !!errors.pack}
                      required={true}
                    />
                    {/* <Form.Label className="fw-normal">
                      <small className="text-uppercase">Pack</small>
                    </Form.Label>

                    {/* <Form.Control
                      type="number"
                      onBlur={handleBlur}
                      value={values.pack}
                      name="pack"
                      onChange={(e) => {
                        setFieldValue('pack', Number(e.target.value))
                        handleInputPack(Number(e.target.value))
                      }}
                    /> */}
                    {/* {touched.pack && !!errors.pack && selectBatch && (
                      <p className="text-danger mt-2">{errors.pack}</p>
                    )}
                    {errorPack.error && (
                      <p className="text-danger mt-2 fw-bold">
                        {errorPack.message}
                      </p>
                    )} */}
                  </div>
                  <div className="col-6">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Qty</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={values.pack * conversion_factor}
                      disabled={true}
                    />
                    {errorPack.error && (
                      <p className="text-danger mt-2 fw-bold">
                        {errorPack.message}
                      </p>
                    )}
                  </div>
                </div>
                <Modal.Footer className="mt-3">
                  <button className="btn" onClick={() => props.onHide()}>
                    Cancel
                  </button>
                  <CustomButton
                    onClick={handleSubmit}
                    variant={
                      !isValid || !dirty || errorPack.error
                        ? 'secondary'
                        : 'warning'
                    }
                    disabled={!isValid || !dirty || errorPack.error}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdatePickupCommand
