import Spinner from 'react-bootstrap/Spinner'

function index({ children }) {
  return (
    <div className="text-center">
      <Spinner animation="border" />
      {children}
    </div>
  )
}

export default index
