import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import ModalUpdateSector from '../../../components/modal/sector/ModalUpdateSector'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const MasterSector = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sectors, setSectors] = useState([])
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [editId, setEditId] = useState(0)
  const { per_page, current_page } = meta

  async function fetchSectors(per_page, current_page) {
    setIsLoading(true)
    try {
      const res = await api.get('master/warehouses', {
        params: { per_page, page: current_page }
      })
      const { metadata, data } = res.data

      setSectors(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSectors(per_page, current_page)
  }, [per_page, current_page])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  // handle edit
  function handleEdit(id) {
    setShowModalUpdate(true)
    setEditId(id)
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Sector</h3>
      <div className="row mt-3">
        <div className="col-12">
          <h4
            className="text-muted text-uppercase"
            style={{ fontSize: '14px' }}
          >
            Sector List
          </h4>
        </div>
      </div>
      <div className="row mt-2">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle">Code</th>
              <th className="p-2 align-middle">Name</th>
              <th className="p-2 align-middle">City</th>
              <th className="p-2 align-middle">Type</th>
              <th className="p-2 align-middle">Customer</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            )}
            {!isLoading && sectors.length === 0 && <RowEmpty colSpan={6} />}
            {!isLoading &&
              sectors?.map((sector) => (
                <TbodyRow
                  data={sector}
                  key={sector.id}
                  onHandleEdit={() => handleEdit(sector.id)}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className="p-3">
                <div className="d-flex align-items-center">
                  <span className="mr-2">Show</span>
                  <select
                    name="per_page"
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '60px' }}
                    value={meta.per_page}
                    onChange={(e) => handleOnChangeMeta(e)}
                  >
                    {[10, 25, 50, 100].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={3} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={meta.total_page}
                    onPageChange={handlePageChange}
                    page={meta.current_page}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalUpdateSector
        show={showModalUpdate}
        onHide={() => {
          setShowModalUpdate(false)
        }}
        id={editId}
        refreshData={() => fetchSectors(per_page, current_page)}
      />
    </div>
  )
}
function TbodyRow({ data, onHandleEdit }) {
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{data.warehouse_code ?? '-'}</td>
      <td className="p-2 align-middle">{data.warehouse_name ?? '-'}</td>
      <td className="p-2 align-middle">{data.location?.area_name ?? '-'}</td>
      <td className="p-2 align-middle">{data.warehouse_type ?? '-'}</td>
      <td className="p-2 align-middle">
        {data.warehouse_customer?.warehouse_name ?? '-'}
      </td>
      <td className="p-2 align-middle text-end">
        <button
          className="btn btn-secondary ml-2"
          onClick={() => onHandleEdit()}
        >
          <FontAwesomeIcon
            icon={faPen}
            style={{ fontSize: '15px', rotate: '90deg' }}
            className="fa-flip-horizontal"
          />
        </button>
      </td>
    </tr>
  )
}
export default MasterSector
