import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { UserEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalUpdateUser(props) {
  const [data, setData] = useState({
    username: '',
    password: ''
  })

  const loadData = async (id) => {
    try {
      const res = await api.get(`/users/${id}`)

      setData({
        username: res.data.data.username
      })
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) {
      loadData(props.id)
    }
  }, [props.id, props.show])

  const handleSubmit = async (values) => {
    try {
      const res = await api.post(`/users/${props.id}`, values)
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            username: data.username,
            password: ''
          }}
          enableReinitialize={true}
          validationSchema={UserEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      id="username"
                      label="username"
                      name="username"
                      value={values.username}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      id="password"
                      label="password"
                      name="password"
                      value={values.password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                      placeholder="******"
                      type='password'
                    />
                  </Col>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Update'}
                    />
                  </Modal.Footer>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateUser
