import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import api from '../../config/AxiosInstance'
import '../../styles/style.css'
import * as Common from '../../utils/Common'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const Notification = () => {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    fetchNotifications()
  }, [])
  const fetchNotifications = async () => {
    try {
      const res = await api.get('/notifications/user')
      if (res.data.success) {
        setNotifications(res.data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const splitDescription = (data) => {
    const words = data.description.split(' ')
    let link = ''
    if (data.type === 'sj') {
      link = `/outbound/sj/detail/${encodeURIComponent(
        words[words.length - 1]
      )}?notif_id=${data.id}`
    }
    if (data.type === 'outbound') {
      link = `/outbound/plan/detail/${data.outbound_id}?notif_id=${data.id}`
    }
    if (data.type === 'inbound') {
      link = `/inbound/purchase-receipt/${data.pr_id}`
    }
    return {
      description: words.slice(0, -1).join(' '),
      code: words[words.length - 1],
      link
    }
  }
  return (
    <Container>
      <Row>
        <Col className="d-flex align-items-center gap-3 mb-4">
          <h4>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => navigate('/dashboard')}
              style={{ cursor: 'pointer', color: 'grey' }}
            />
          </h4>
          <h4 style={headerTitleStyle}>Notifications</h4>
        </Col>
      </Row>
      <Row>
        <Table
          responsive="sm"
          className="shadow-sm bg-white"
          hover
          style={{ fontSize: '14px', fontWeight: '600' }}
        >
          <tbody>
            {notifications.length === 0 && (
              <tr>
                <td className="p-4 text-center fw-bold">No Notifications</td>
              </tr>
            )}
            {notifications.length > 0 &&
              notifications.map((notification, i) => (
                <tr key={notification.id}>
                  <td
                    className={`p-4 ${
                      !notification?.is_read ? 'background-unread' : ''
                    }`}
                  >
                    <h6>{notification.time}</h6>
                    <p>
                      {splitDescription(notification)?.description}
                      <Link
                        className="text-decoration-none ms-2"
                        to={splitDescription(notification)?.link}
                      >
                        {splitDescription(notification)?.code}
                      </Link>
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  )
}

export default Notification
