import { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { setAuthToken } from '../config/api'
import { UserContext } from '../context/userContext'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

function ProtectedMoverRoutes() {
  const navigate = useNavigate()
  const [state, dispatch] = useContext(UserContext)

  useEffect(() => {
    if (!state.login) {
      navigate('/')
    }
  }, [state])

  return (
    <>
      <Outlet />
    </>
  )
}

export default ProtectedMoverRoutes
