import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faBars, faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Pusher from 'pusher-js'
import React, { useContext, useState } from 'react'
import { Badge, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import ModalChangePassword from '../../components/modal/changepassword/ModalChangePassword'
import { UserContext } from '../../context/userContext'
import { firstLetterUpperCase } from '../../utils/TextFormatter'
const NavbarPage = ({ onClick, indicator }) => {
  const [showModal, setShowModal] = useState(false)

  const [state, dispatch] = useContext(UserContext)
  // const pusher = new Pusher('b4fd69a93b89d910f024', {
  //   cluster: 'ap1'
  // })
  // var channel = pusher.subscribe(
  //   `notif-om-${state.username?.roles[0].warehouse_id}-indicator`
  // )
  // channel.bind(`notification-indicator`, function (data) {
  //   console.log(data)

  //   setIndicator(Boolean(data))
  // })

  const navigate = useNavigate()
  function handleLogOut() {
    Swal.fire({
      title: 'Are you sure to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log out',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: 'logout' })
      }
    })
  }

  return (
    <>
      <Navbar className="shadow-sm bg-white">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto ms-4">
            <Nav.Link onClick={onClick}>
              <FontAwesomeIcon icon={faBars} />
            </Nav.Link>
          </Nav>
          <Nav className="d-flex flex-row justify-content-end">
            <div className="d-flex flex-row gap-2">
              <div
                className="cursor-pointer position-relative"
                role="button"
                onClick={() => {
                  navigate('/notification')
                }}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{ fontSize: '24px', cursor: 'pointer' }}
                  className="mt-2 me-3"
                />
                {indicator && (
                  <Badge
                    bg="danger"
                    className="dot-badge rounded-circle"
                  ></Badge>
                )}
              </div>
              <div className="d-flex flex-row gap-1">
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="mt-2"
                  style={{ fontSize: '24px' }}
                />
                <NavDropdown
                  title={firstLetterUpperCase(state.username.username)}
                  className="me-5 dropdown-navbar-page"
                  id="collapsible-nav-dropdown "
                  style={{ fontSize: '14px' }}
                >
                  <NavDropdown.Item onClick={() => setShowModal(true)}>
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogOut}>
                    <p className="text-danger fw-bold">Logout</p>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ModalChangePassword
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}

export default NavbarPage
