import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import api from '../../../../config/AxiosInstance'
import '../../../../styles/style.css'
import * as Common from '../../../../utils/Common'
import Loader from '../../../atoms/Loader'
import Pagination from '../../../atoms/Pagination/Index'
import ModalAddManageRole from '../../../modal/managerole/ModalAddManageRole'
import ModalUpdateManageRole from '../../../modal/managerole/ModalUpdateManageRole'

function ManageRole() {
  const [filterName, setFilterName] = useState('')
  const [filterCode, setFilterCode] = useState('')
  const [filterCity, setFilterCity] = useState('')
  const [filterAddress, setFilterAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [manageRoles, setManageRoles] = useState([])
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [pageCount, setPageCount] = useState(0)
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [editId, setEditId] = useState(null)
  const { per_page, current_page } = meta

  useEffect(
    function () {
      const controller = new AbortController()
      const delayTimer = setTimeout(() => {
        fetchManageRoles(
          filterName,
          filterCode,
          filterCity,
          filterAddress,
          per_page,
          current_page,
          controller
        )
      }, 1000)
      // clean up effect function
      return function () {
        controller.abort()
        clearTimeout(delayTimer)
      }
    },
    [filterName, filterCode, filterCity, filterAddress, per_page, current_page]
  )

  const fetchManageRoles = async (
    filterName,
    filterCode,
    filterCity,
    filterAddress,
    per_page,
    current_page,
    controller = ''
  ) => {
    setIsLoading(true)
    try {
      const res = await api.get('cms/manage-role/list', {
        params: {
          name: filterName,
          code: filterCode,
          city: filterCity,
          address: filterAddress,
          per_page,
          page: current_page
        },
        signal: controller.signal
      })
      if (res.data.success) {
        const { metadata, data } = res.data
        setManageRoles(data)
        setMeta(metadata)
        setPageCount(metadata.total_page || 0)
      }
    } catch (err) {
      setIsLoading(false)
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    } finally {
      setIsLoading(false)
    }
  }

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(id) {
    setShowModalUpdate((show) => !show)
    setEditId(id)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-7">
          <h3 className="header-title-style">Manage User Role</h3>
        </div>
        <div className="col-5">
          <div className="d-flex justify-content-end h-100 align-items-center mt-1">
            <button
              className="btn btn-primary-orange"
              onClick={() => setShowModalAdd(true)}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle" width="25%">
                Name
              </th>
              <th className="p-2 align-middle">Code</th>
              <th className="p-2 align-middle">Role</th>
              <th className="p-2 align-middle">City</th>
              <th className="p-2 align-middle">Address</th>
              <th className="p-2 align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Control
                  type="text"
                  id="name"
                  onChange={(e) => setFilterName(e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  id="code"
                  onChange={(e) => {
                    setFilterCode(e.target.value)
                  }}
                />
              </td>
              <td></td>
              <td>
                <Form.Control
                  type="text"
                  id="city"
                  onChange={(e) => setFilterCity(e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  id="address"
                  onChange={(e) => setFilterAddress(e.target.value)}
                />
              </td>
              <td></td>
            </tr>
            {isLoading && (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            )}
            {!isLoading && manageRoles.length === 0 && (
              <tr>
                <td colSpan={6} className="fw-bold text-center">
                  No Data
                </td>
              </tr>
            )}
            {!isLoading &&
              manageRoles.length > 0 &&
              manageRoles?.map((manageRole) => (
                <TbodyRow
                  data={manageRole}
                  key={manageRole.id}
                  handleEdit={() => handleEdit(manageRole.id)}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className="p-3">
                <div className="d-flex">
                  <span className="mt-2 mr-2">Show</span>
                  <select
                    name="per_page"
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '13%' }}
                    value={meta.per_page}
                    onChange={(e) => handleOnChangeMeta(e)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span className="mt-2 ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={3} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    page={current_page}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddManageRole
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        loadPage={() =>
          fetchManageRoles(
            filterName,
            filterCode,
            filterCity,
            filterAddress,
            per_page,
            current_page
          )
        }
      />
      <ModalUpdateManageRole
        show={showModalUpdate}
        onHide={() => {
          setShowModalUpdate(false)
        }}
        data={editId}
        loadPage={() =>
          fetchManageRoles(
            filterName,
            filterCode,
            filterCity,
            filterAddress,
            per_page,
            current_page
          )
        }
      />
    </div>
  )
}

function TbodyRow({ data, handleEdit }) {
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{data.user?.username ?? '-'}</td>
      <td className="p-2 align-middle">{data.role?.role_code ?? '-'}</td>
      <td className="p-2 align-middle">{data.role?.role_name ?? '-'}</td>
      <td className="p-2 align-middle">{data.location?.area_name ?? '-'}</td>
      <td className="p-2 align-middle">
        {data.warehouse?.warehouse_name ?? '-'}
      </td>
      <td className="p-2 align-middle">
        <button
          className="btn btn-secondary ml-2"
          onClick={() => handleEdit(data.id)}
        >
          <FontAwesomeIcon
            icon={faPen}
            style={{ fontSize: '15px', rotate: '90deg' }}
            className="fa-flip-horizontal"
          />
        </button>
      </td>
    </tr>
  )
}
export default ManageRole
