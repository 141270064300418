import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useRef, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import api from '../../../../config/AxiosInstance'
import { UserContext } from '../../../../context/userContext'
import '../../../../styles/style.css'
import * as Common from '../../../../utils/Common'
import {
  firstLetterUpperCase,
  formatThousandSeparator
} from '../../../../utils/TextFormatter'
import CustomButton from '../../../Button/CustomButton'
const deliveryTypes = [
  {
    value: 'internal',
    label: 'Internal'
  },
  {
    value: 'sewa',
    label: 'External/sewa'
  },
  {
    value: 'pick_up',
    label: 'Self Pick Up'
  }
]
function ModalUpdateVehicleOutboundPlan(props) {
  // style
  // state
  // event handler
  const {
    updateVehicle,
    handleUpdateVehicle,
    totalVolume,
    handleUploadFile,
    outboundPlanId,
    onHide,
    refreshData,
    isTr
  } = props
  const [vehicles, setVehicles] = useState([])
  const [drivers, setDrivers] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitHandling, setSubmitHandling] = useState(false)
  const simRef = useRef(null)
  const memoRef = useRef(null)
  const stnkRef = useRef(null)
  const [username] = useContext(UserContext)

  useEffect(() => {
    if (updateVehicle.step === 2 && updateVehicle.type !== 'pick_up') {
      fetchVehicles(updateVehicle.type)
    }
    if (updateVehicle.step === 3) {
      fetchDrivers(updateVehicle.type)
    }
  }, [updateVehicle.step])
  const fetchVehicles = async (type) => {
    try {
      setLoading(true)
      const res = await api.get('/outbound/outbound_plan/vehicle', {
        params: { type: type }
      })
      if (res.data.success) {
        setVehicles(res.data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }
  const handleDisabled = () => {
    if (updateVehicle.type !== 'pick_up') {
      if (updateVehicle.step === 2 && updateVehicle.vehicle_id === '') {
        return true
      }
      if (updateVehicle.step === 3 && updateVehicle.driver_id === '') {
        return true
      }
    }
    if (
      updateVehicle.step === 2 &&
      updateVehicle.type === 'pick_up' &&
      (!updateVehicle.sim || !updateVehicle.memo || !updateVehicle.stnk)
    ) {
      return true
    }
    if (submitHandling) {
      return true
    }

    return false
  }
  const handleText = () => {
    if (updateVehicle.step === 2 && updateVehicle.type !== 'pick_up') {
      return 'Submit'
    }
    if (submitHandling) {
      return 'Loading...'
    }
    return 'Next'
  }
  const fetchDrivers = async (type) => {
    try {
      setLoading(true)
      const res = await api.get('/outbound/outbound_plan/driver', {
        params: {
          type,
          warehouse_id: username?.username?.roles[0].warehouse_id
        }
      })
      if (res.data.success) {
        setDrivers(res.data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }
  const handleRemoveFile = (type) => {
    const typeRefs = {
      sim: simRef,
      memo: memoRef,
      stnk: stnkRef
    }
    let typeRef = typeRefs[type] || null
    if (typeRef.current.value) {
      typeRef.current.value = ''
    }
    handleUploadFile(type, 'delete')
  }
  const getStepMessage = (step, type) => {
    if (step === 1) return 'Select Delivery type'
    if (step === 2) return type === 'pick_up' ? 'Upload File' : 'Select Vehicle'
    if (step === 3 && type !== 'pick_up') return 'Select Driver'
    return null
  }

  const handleButtonSubmit = async (step, type) => {
    const submitData = async (url, data, type) => {
      try {
        setSubmitHandling(true)
        const res =
          type === 'formData'
            ? await api.postForm(url, data)
            : await api.post(url, data)

        if (res.data.success) {
          refreshData()
          onHide()
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      } finally {
        setSubmitHandling(false)
      }
    }

    if (step <= 3) {
      if (step === 2 && type === 'pick_up') {
        const formData = new FormData()
        for (const key in updateVehicle) {
          if (key !== 'step') {
            formData.append(
              key === 'type' ? 'delivery_type' : key,
              updateVehicle[key]
            )
          }
        }
        await submitData(
          `/outbound/outbound_plan/${outboundPlanId}/update-vehicle`,
          formData,
          'formData'
        )
        return
      }

      if (step === 3 && type !== 'pick_up') {
        const data = {
          delivery_type: updateVehicle.type,
          vehicle_id: updateVehicle.vehicle_id,
          driver_id: updateVehicle.driver_id,
          sim: updateVehicle.sim,
          memo: updateVehicle.memo,
          stnk: updateVehicle.stnk
        }
        await submitData(
          `/outbound/outbound_plan/${outboundPlanId}/update-vehicle`,
          data,
          'form'
        )
        return
      }

      return handleUpdateVehicle('step', step + 1)
    }
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Update Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">
          {getStepMessage(updateVehicle.step, updateVehicle.type)}
        </p>
        {updateVehicle.step === 1 &&
          deliveryTypes
            .filter((deliveryType) =>
              isTr ? deliveryType.value !== 'pick_up' : deliveryType
            )
            .map((deliveryType, index) => (
              <Form.Check
                key={index}
                label={<p className="ms-2 mt-1">{deliveryType.label}</p>}
                name="driver"
                type={'radio'}
                id={deliveryType.value}
                value={deliveryType.value}
                className="outbound-driver"
                checked={updateVehicle.type === deliveryType.value}
                onChange={(e) => handleUpdateVehicle('type', e.target.value)}
              />
            ))}
        {updateVehicle.step === 2 && updateVehicle.type !== 'pick_up' && (
          <>
            {loading && (
              <div className="d-flex flex-row justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  variant="warning"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mt-1"
                />
                <span className="ms-2 fw-bold">Loading...</span>
              </div>
            )}
            {!loading &&
              vehicles.map((vehicle, index) => (
                <Form.Check
                  key={index}
                  label={
                    <p
                      className={`ms-2 mt-1 cursor-pointer ${
                        vehicle.volume < totalVolume && 'text-danger'
                      }`}
                      role="button"
                    >
                      {`${vehicle.plate_no} - ${vehicle.vehicle_type} - ${
                        vehicle.capacity
                      } Kg - ${formatThousandSeparator(vehicle.volume)} `}
                      {''}cm <sup>3</sup>
                    </p>
                  }
                  name="vehicle"
                  type={'radio'}
                  id={vehicle.id}
                  value={vehicle.id}
                  style={{ cursor: 'pointer' }}
                  role="button"
                  className="outbound-driver"
                  checked={updateVehicle.vehicle_id === vehicle.id}
                  onChange={(e) =>
                    handleUpdateVehicle('vehicle_id', e.target.value)
                  }
                />
              ))}
          </>
        )}
        {updateVehicle.step === 2 && updateVehicle.type === 'pick_up' && (
          <>
            <Form.Group className="mb-3">
              <Form.Label className="text-muted fw-bold">
                SIM <sup style={{ color: 'red' }}>*</sup>
              </Form.Label>
              <div
                style={{
                  maxWidth: `${updateVehicle.sim ? '100%' : '35px'}`,
                  border: '1px solid #EFEFEF',
                  padding: '.5rem',
                  height: `${updateVehicle.sim ? '50px' : '35px'}`
                }}
                className="shadow cursor-pointer"
                role="button"
                onClick={() => {
                  if (!updateVehicle.sim) simRef.current.click()
                }}
              >
                <div
                  className={`d-flex flex-column h-100 justify-content-center ${
                    updateVehicle.sim ? 'ms-2' : 'ms-1'
                  }`}
                >
                  <div className="d-flex flex-row gap-2">
                    <FontAwesomeIcon
                      icon={updateVehicle.sim ? faClose : faPlus}
                      style={{ fontSize: '15px' }}
                      className="mt-1"
                      onClick={(e) => {
                        if (updateVehicle.sim) {
                          e.stopPropagation()
                          handleRemoveFile('sim')
                        }
                      }}
                    />
                    {updateVehicle.sim && (
                      <span
                        className="fw-bold text-muted"
                        style={{ fontSize: '12px', marginTop: '.1rem' }}
                      >
                        {updateVehicle.sim.name ??
                          updateVehicle.sim.split('/').pop()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Form.Control
                type="file"
                className="d-none"
                name="sim"
                id="file-sim"
                ref={simRef}
                accept="image/png, image/jpeg, image/jpg, image/svg, image/webp"
                onChange={(e) => handleUploadFile(e, 'upload')}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="text-muted fw-bold">
                Memo Pengambilan Barang <sup style={{ color: 'red' }}>*</sup>
              </Form.Label>
              <div
                style={{
                  maxWidth: `${updateVehicle.memo ? '100%' : '35px'}`,
                  border: '1px solid #EFEFEF',
                  padding: '.5rem',
                  height: `${updateVehicle.memo ? '50px' : '35px'}`
                }}
                className="shadow cursor-pointer"
                role="button"
                onClick={() => {
                  if (!updateVehicle.memo) memoRef.current.click()
                }}
              >
                <div
                  className={`d-flex flex-column h-100 justify-content-center ${
                    updateVehicle.memo ? 'ms-2' : 'ms-1'
                  }`}
                >
                  <div className="d-flex flex-row gap-2">
                    <FontAwesomeIcon
                      icon={updateVehicle.memo ? faClose : faPlus}
                      style={{ fontSize: '15px' }}
                      className="mt-1"
                      onClick={(e) => {
                        if (updateVehicle.memo) {
                          e.stopPropagation()
                          handleRemoveFile('memo')
                        }
                      }}
                    />
                    {updateVehicle.memo && (
                      <span
                        className="fw-bold text-muted"
                        style={{ fontSize: '12px', marginTop: '.1rem' }}
                      >
                        {updateVehicle.memo.name ??
                          updateVehicle.memo.split('/').pop()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Form.Control
                type="file"
                hidden={true}
                name="memo"
                id="file-memo"
                ref={memoRef}
                accept="image/png, image/jpeg, image/jpg, image/svg, image/webp"
                onChange={(e) => handleUploadFile(e, 'upload')}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="text-muted fw-bold">
                STNK <sup style={{ color: 'red' }}>*</sup>
              </Form.Label>
              <div
                style={{
                  maxWidth: `${updateVehicle.stnk ? '100%' : '35px'}`,
                  border: '1px solid #EFEFEF',
                  padding: '.5rem',
                  height: `${updateVehicle.stnk ? '50px' : '35px'}`
                }}
                className="shadow cursor-pointer"
                role="button"
                onClick={() => {
                  if (!updateVehicle.stnk) stnkRef.current.click()
                }}
              >
                <div
                  className={`d-flex flex-column h-100 justify-content-center ${
                    updateVehicle.stnk ? 'ms-2' : 'ms-1'
                  }`}
                >
                  <div className="d-flex flex-row gap-2">
                    <FontAwesomeIcon
                      icon={updateVehicle.stnk ? faClose : faPlus}
                      style={{ fontSize: '15px' }}
                      className="mt-1"
                      onClick={(e) => {
                        if (updateVehicle.stnk) {
                          e.stopPropagation()
                          handleRemoveFile('stnk')
                        }
                      }}
                    />
                    {updateVehicle.stnk && (
                      <span
                        className="fw-bold text-muted"
                        style={{ fontSize: '12px', marginTop: '.1rem' }}
                      >
                        {updateVehicle.stnk.name ??
                          updateVehicle.stnk.split('/').pop()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Form.Control
                type="file"
                className="d-none"
                name="stnk"
                id="file-stnk"
                ref={stnkRef}
                accept="image/png, image/jpeg, image/jpg, image/svg, image/webp"
                onChange={(e) => handleUploadFile(e, 'upload')}
              />
            </Form.Group>
          </>
        )}
        {updateVehicle.step === 3 && (
          <>
            {loading && (
              <div className="d-flex flex-row justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  variant="warning"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mt-1"
                />
                <span className="ms-2 fw-bold">Loading...</span>
              </div>
            )}
            {!loading && !drivers.length && (
              <div className="d-flex flex-row justify-content-center">
                <span className="fw-bold">Empty Driver</span>
              </div>
            )}
            {!loading &&
              drivers.length > 0 &&
              drivers.map((driver, index) => (
                <Form.Check
                  key={index}
                  label={
                    <p className="ms-2 cursor-pointer" role="button">
                      {firstLetterUpperCase(driver.name)}
                    </p>
                  }
                  name="driver"
                  type={'radio'}
                  id={driver.id}
                  value={driver.id}
                  style={{ cursor: 'pointer' }}
                  role="button"
                  className="outbound-driver"
                  checked={updateVehicle.driver_id === driver.id}
                  onChange={(e) =>
                    handleUpdateVehicle('driver_id', e.target.value)
                  }
                />
              ))}
          </>
        )}

        <Modal.Footer className="mt-3">
          <CustomButton
            variant={`${handleDisabled() ? 'secondary' : 'transparent'}`}
            classNameValue={`${handleDisabled() ? '' : 'btn-primary-orange'}`}
            disabled={handleDisabled()}
            onClick={() =>
              handleButtonSubmit(updateVehicle.step, updateVehicle.type)
            }
            text={handleText()}
          />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}
export default ModalUpdateVehicleOutboundPlan
