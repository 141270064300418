import { useEffect, useState } from 'react'
import { Col, Container, FormSelect, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import api from '../../../../../config/AxiosInstance'
import * as Common from '../../../../../utils/Common'
import Check from './components/Check'

function ManageAccess() {
  const roleId = JSON.parse(localStorage.getItem('username')).role?.id
  const [roles, setRoles] = useState([])
  const [menus, setMenus] = useState([])
  const [selectRole, setSelectRole] = useState(null)
  useEffect(() => {
    getListRoles()
  }, [])
  const getListRoles = async () => {
    try {
      const res = await api.get('/cms/role/list/all')
      if (res.status === 200) {
        setRoles(res.data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const getMenus = async (role_id) => {
    try {
      const res = await api.post('/cms/manage-access/list_by_role', { role_id })
      if (res.status === 200) {
        setMenus(res.data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const handleSelectRole = (e) => {
    const { value } = e.target
    // selected role
    setSelectRole(value)
    // fetch menu based role
    getMenus(value)
  }
  const handleChangeChecked = (type, e, idParent = null) => {
    const { checked, value } = e.target
    const parentChecked = menus.find(
      (menu) => menu.id === (!idParent ? value : idParent)
    )
    if (type === 'parent') {
      parentChecked.is_checked = checked
      if (parentChecked.submenu.length > 0) {
        parentChecked.submenu.forEach((submenu) => {
          submenu.is_checked = checked
        })
      }
    }
    if (type === 'child') {
      // read index submenu
      const index = parentChecked.submenu.findIndex((menu) => menu.id === value)
      parentChecked.submenu[index].is_checked = checked
      // read checked item length in submenu,
      // when property is_checked have all/part of parent checked set parent to checked,
      // and when all is_checked property have value false set to parent unchecked
      const checkedLength = parentChecked.submenu.filter(
        (menu) => menu.is_checked
      ).length
      parentChecked.is_checked = checkedLength ? true : false
    }
    setMenus((prevMenus) =>
      prevMenus.map((menu) =>
        menu.id === parentChecked.id ? parentChecked : menu
      )
    )
  }
  const handleRemoveAll = () => {
    const uncheckedAll = menus.map((menu) => {
      menu.is_checked = false
      if (menu.submenu.length > 0) {
        menu.submenu.forEach((submenu) => {
          submenu.is_checked = false
        })
      }
      return menu
    })
    setMenus(uncheckedAll)
  }
  const handleSave = async () => {
    try {
      let data = {
        role_id: selectRole,
        access_ids: []
      }
      menus.forEach((menu) => {
        if (menu.is_checked) {
          data.access_ids.push(menu.id)
        }
        if (menu.submenu.length > 0) {
          menu.submenu.forEach((submenu) => {
            if (submenu.is_checked) {
              data.access_ids.push(submenu.id)
            }
          })
        }
      })
      Swal.fire({
        text: 'Loading...',
        icon: 'warning',
        allowOutsideClick: false
      })
      // hit endpoint
      const res = await api.post('/cms/manage-access/bulk_add_by_code', data)
      if (res.status === 200) {
        // when role id same as selected update attribute access
        if (roleId === selectRole) {
          let dataLocalStorage = JSON.parse(localStorage.getItem('username'))
          dataLocalStorage = { ...dataLocalStorage, access: res.data.data }
          localStorage.setItem('username', JSON.stringify(dataLocalStorage))
        }
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      Swal.close()
    }
  }
  return (
    <Container style={{ minHeight: '100vh' }}>
      <Row>
        <h2 style={{ color: 'var(--primary-orange)' }}>Access Management</h2>
      </Row>
      <Row className="mt-4">
        <Col>
          <FormSelect
            name="role"
            id="role"
            onChange={(e) => handleSelectRole(e)}
          >
            <option value="">-- Select Role --</option>
            {roles?.length > 0 &&
              roles.map((role) => (
                <option value={role.id} key={role.id}>
                  {role.role_name}
                </option>
              ))}
          </FormSelect>
        </Col>
        <Col>
          <button className="btn btn-success me-3" onClick={handleSave}>
            Save
          </button>
          <button className="btn btn-primary-orange" onClick={handleRemoveAll}>
            Remove All
          </button>
        </Col>
      </Row>
      {selectRole && (
        <Row className="mt-4">
          <Container>
            <>
              {menus?.length > 0 &&
                menus.map((menu) => (
                  <Check
                    data={menu}
                    subMenu={menu.submenu}
                    key={menu.id}
                    handleChangeChecked={handleChangeChecked}
                  />
                ))}
            </>
          </Container>
        </Row>
      )}
    </Container>
  )
}

export default ManageAccess
