import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../config/AxiosInstance.js'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common.js'

function ModalAddManageRole(props) {
  const [optionUsernames, setOptionUsernames] = useState([])
  const [inputUsername, setInputUsername] = useState('')
  const [optionRoles, setOptionRoles] = useState([])
  const [inputRole, setInputRole] = useState('')
  const [optionAreas, setOptionAreas] = useState([])
  const [inputArea, setInputArea] = useState('')
  const [optionSectors, setOptionSectors] = useState([])
  const [inputSector, setInputSector] = useState('')

  useEffect(
    function () {
      const controller = new AbortController()
      fetchUsernames(inputUsername, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    },
    [inputUsername]
  )

  useEffect(
    function () {
      const controller = new AbortController()
      fetchRoles(inputRole, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    },
    [inputRole]
  )

  useEffect(
    function () {
      const controller = new AbortController()
      fetchAreas(inputArea, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    },
    [inputArea]
  )

  useEffect(
    function () {
      const controller = new AbortController()
      fetchSectors(inputSector, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    },
    [inputSector]
  )

  const fetchUsernames = async (inputUsername, controller) => {
    try {
      const res = await api.get(`/users/list/all?username=${inputUsername}`, {
        signal: controller.signal
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((user) =>
          optionValue.push({
            value: user.id,
            label: user.username
          })
        )
      }
      setOptionUsernames(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }
  const fetchRoles = async (inputRole, controller) => {
    try {
      const res = await api.get(`/cms/role/list/all?role_name=${inputRole}`, {
        signal: controller.signal
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((role) =>
          optionValue.push({
            value: role.id,
            label: role.role_name
          })
        )
      }
      setOptionRoles(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  const fetchAreas = async (inputArea, controller) => {
    try {
      const res = await api.get(`master/locations?q=${inputArea}`, {
        signal: controller.signal
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((area) =>
          optionValue.push({
            value: area.id,
            label: area.area_name
          })
        )
      }
      setOptionAreas(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }
  const fetchSectors = async (inputSector, controller) => {
    try {
      const res = await api.get(`master/warehouses/all?q=${inputSector}&is_parent=true`, {
        signal: controller.signal
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((sector) =>
          optionValue.push({
            value: sector.id,
            label: sector.warehouse_name
          })
        )
      }
      setOptionSectors(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  const handleInputUsernameChange = (newValue) => {
    setInputUsername(newValue)
  }

  const handleInputRoleChange = (newValue) => {
    setInputRole(newValue)
  }

  const handleInputAreaChange = (newValue) => {
    setInputArea(newValue)
  }

  const handleInputSectorChange = (newValue) => {
    setInputSector(newValue)
  }

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Username required'
    }
    if (!values.role) {
      errors.role = 'Role required'
    }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      username: null,
      role: null,
      area: null,
      sector: null
    },
    validate,
    onSubmit: async (value, { resetForm }) => {
      try {
        // override value form submit
        const values = {
          user_id: value.username.value,
          role_id: value.role.value,
          location_id: value.area?.value ?? '',
          warehouse_id: value.sector?.value ?? ''
        }
        const response = await api.post('cms/manage-role/add', values)

        if (response.data.success) {
          resetForm()
          props.onHide()
          props.loadPage()
          Common.showPopUpSuccessMessage(response.data.message)
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }
  })

  return (
    <Modal {...props} size="lg" centered>
      <Form className="d-grid gap-2" onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add User Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Username
                </Form.Label>
                <Select
                  id="username"
                  name="username"
                  options={optionUsernames}
                  onInputChange={handleInputUsernameChange}
                  // onChange={handleSelectUsername}
                  onChange={(e) => {
                    formik.setFieldValue('username', e)
                  }}
                  value={formik.values.username}
                  placeholder="Search option..."
                  isClearable
                  isSearchable
                />
              </Form.Group>
              {formik.errors.username && (
                <p style={{ color: 'red' }}>{formik.errors.username}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Role
                </Form.Label>
                <Select
                  id="role"
                  name="role"
                  options={optionRoles}
                  onInputChange={handleInputRoleChange}
                  onChange={(e) => {
                    formik.setFieldValue('role', e)
                  }}
                  value={formik.values.role}
                  // onChange={handleSelectRole}
                  placeholder="Search Option..."
                  isClearable
                  isSearchable
                />
              </Form.Group>
              {formik.errors.role && (
                <p style={{ color: 'red' }}>{formik.errors.role}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Area
                </Form.Label>
                <Select
                  id="area"
                  name="area"
                  options={optionAreas}
                  onInputChange={handleInputAreaChange}
                  onChange={(e) => {
                    formik.setFieldValue('area', e)
                  }}
                  value={formik.values.area}
                  // onChange={handleSelectArea}
                  placeholder="Search Option..."
                  isClearable
                  isSearchable
                />
              </Form.Group>
              {formik.errors.area && (
                <p style={{ color: 'red' }}>{formik.errors.area}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Sector
                </Form.Label>
                <Select
                  id="sector"
                  name="sector"
                  options={optionSectors}
                  onInputChange={handleInputSectorChange}
                  onChange={(e) => {
                    formik.setFieldValue('sector', e)
                  }}
                  // onChange={handleSelectSector}
                  placeholder="Search Option..."
                  value={formik.values.sector}
                  isClearable
                  isSearchable
                />
              </Form.Group>
              {formik.errors.sector && (
                <p style={{ color: 'red' }}>{formik.errors.sector}</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn text-muted"
            style={{ backgroundColor: 'transparent', border: 'none' }}
            onClick={() => props.onHide()}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary-orange"
            disabled={
              formik.values.username && formik.values.role ? false : true
            }
          >
            Create
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalAddManageRole
