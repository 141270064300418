import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'

const labelDetailStyle = {
  fontSize: '10px',
  letterSpacing: '0.5px'
}

const PurchaseOrderDetail = () => {
  const [detail, setDetail] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { code } = useParams()

  useEffect(() => {
    loadData(code)
  }, [code])

  const loadData = async (code) => {
    try {
      setIsLoading(true)
      const res = await api.get(`inbound/purchase-order/show?po=${code}`)
      const { data } = res.data
      setDetail(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const navigate = useNavigate()

  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <Breadcrumb
              label={detail.po_name}
              link="/inbound/purchase-order"
              back="PURCHASE ORDER"
              status={detail.status}
              type="ReceiveBill"
            />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <small className="fw-bold text-secondary" style={labelDetailStyle}>
              PRINCIPLE
            </small>
            <h5>{detail?.principle}</h5>
          </Col>
          <Col>
            <small className="fw-bold text-secondary" style={labelDetailStyle}>
              DESTINATION WAREHOUSE
            </small>
            <h5>{detail?.dest_warehouse}</h5>
          </Col>
          <Col>
            <small className="fw-bold text-secondary" style={labelDetailStyle}>
              PO DATE
            </small>
            <h5>{detail?.po_date}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5 className="text-secondary" style={{ letterSpacing: '0.5px' }}>
              PO ITEM
            </h5>
          </Col>
          <Col md={12}>
            <Table
              hover
              responsive="sm"
              size="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 text-end align-middle">PO Qty</th>
                </tr>
              </thead>
              <tbody>
                {detail?.items?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-3 align-middle" style={{ width: '3%' }}>
                      {index + 1}
                    </td>
                    <td className="p-3 align-middle">{item.name}</td>
                    <td className="p-3 align-middle text-end">{item.qty}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12}>
            <h5 className="text-secondary" style={{ letterSpacing: '0.5px' }}>
              PRP ITEM
            </h5>
          </Col>
          <Col md={12}>
            <Table
              hover
              responsive="sm"
              size="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 text-end align-middle">PO Qty</th>
                </tr>
              </thead>
              <tbody>
                {detail?.prp_items?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-3 align-middle" style={{ width: '3%' }}>
                      {index + 1}
                    </td>
                    <td className="p-3 align-middle" style={{ width: '45%' }}>
                      {item?.name}
                    </td>
                    <td className="p-3 text-end align-middle">{item?.qty}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PurchaseOrderDetail
