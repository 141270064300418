import { Form, Modal } from 'react-bootstrap'
import '../../../styles/style.css'
import CustomButton from '../../Button/CustomButton'
function ModalAddDriver(props) {
  // style
  // state
  // event handler
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Select Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form checkbox driver */}
        {props.drivers.map((driver, index) => (
          <Form.Check
            key={index}
            label={<p className="ml-3">{driver.name}</p>}
            name="driver"
            type={'radio'}
            id={`driver-${driver.id}`}
            value={driver.id}
            className={index > 0 ? 'outbound-driver mt-3' : 'outbound-driver'}
            onChange={(e) => props.onHandleChangeSelectDriver(e.target.value)}
          />
        ))}
        <Modal.Footer className="mt-3">
          <CustomButton
            onClick={props.onHandleSelectDriver}
            variant={!props.dataChangeSelectDriver ? 'secondary' : 'warning'}
            text={'Save'}
          />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}
export default ModalAddDriver
