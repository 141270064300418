import moment from 'moment/moment'

export function formatDate(params) {
  return moment(params).format('ll')
}

export function formatDateDayMonthYear(
  params,
  separator = '',
  monthText = false,
  needHour = false
) {
  if (separator != '') {
    return moment(params).format(
      `DD${separator}${monthText ? 'MMM' : 'MM'}${separator}YYYY ${
        needHour ? 'HH:mm' : ''
      }`
    )
  }
  return moment(params).format('DD MMM YYYY')
}

export function convertDateFormat(inputDate) {
  try {
    const parsedDate = new Date(inputDate)

    // Format the date part as "YYYY/MM/DD"
    const formattedDate = `${parsedDate.getFullYear()}-${(
      parsedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`

    // Format the time part as "HH:mm:ss"
    const formattedTime = `${parsedDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${parsedDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${parsedDate.getSeconds().toString().padStart(2, '0')}`

    // Return the combined result
    return `${formattedDate} ${formattedTime}`
  } catch (err) {
    return '-'
  }
}
