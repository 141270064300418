import { Col, Row, Table, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SLotLocationWorkOrderPlan = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  return (
    <>
      <Container>
        <Row>
          <h4 style={headerTitleStyle}>Slot Location</h4>
        </Row>
        <Row className="mb-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item sj-item">
                <Link to={'/'} className="text-decoration-none">
                  <span className="text-black fw-semibold">
                    WORK ORDER PLAN
                  </span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={'/'} className="text-decoration-none">
                  <span className="text-black fw-semibold">WOP23/02/B0021</span>
                </Link>
              </li>
              <li
                className="breadcrumb-item active fw-semibold"
                aria-current="page"
              >
                SLOT LOCATION
              </li>
            </ol>
          </nav>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex justify-content-between">
            <h5 className="fw-semibold text-uppercase text-secondary">
              slot location
            </h5>
            <h5>
              <span className="text-success">5</span>/5 PACK
            </h5>
          </Col>
        </Row>
        <Row>
          <Table
            hover
            size="sm"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-3 align-middle">No</th>
                <th className="p-3 align-middle">Item</th>
                <th className="p-3 align-middle">Item Alias</th>
                <th className="p-3 align-middle">Item Code</th>
                <th className="p-3 align-middle">Batch</th>
                <th className="p-3 align-middle">Total Pack</th>
                <th className="p-3 align-middle">Slot</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-3">1</td>
                <td className="p-3">CETIOL CC</td>
                <td className="p-3">-</td>
                <td className="p-3">8457-AU-002-1654</td>
                <td className="p-3">1234567891</td>
                <td className="p-3">5 Drum</td>
                <td className="p-3">B.N.A.D.16.3.02.1</td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </Container>
    </>
  )
}

export default SLotLocationWorkOrderPlan
