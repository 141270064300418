import {
  faCheck,
  faClose,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import Pagination from '../../components/atoms/Pagination/Index'
import api from '../../config/AxiosInstance'
import styles from '../../pages/Outbound/Plan/outbound_plan.module.css'
import * as Common from '../../utils/Common'
const MaterialRequest = () => {
  const initialMeta = {
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  }
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  const navigate = useNavigate()
  const { state } = useLocation()

  const [warehouses, setWarehouses] = useState([])
  const [successNotification, setSuccessNotification] = useState(false)
  const [filter, setFilter] = useState({
    target_warehouse: null,
    src_warehouse: null
  })
  const [meta, setMeta] = useState(initialMeta)
  const [materialRequests, setMaterialRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    fetchWarehouses()
    if (state?.sendNotificationSuccess) {
      setSuccessNotification(true)
      const time = setTimeout(() => {
        setSuccessNotification(false)
      }, 5000)
      return () => clearTimeout(time)
    }
  }, [state?.sendNotificationSuccess])
  useEffect(() => {
    fetchMaterialRequest()
  }, [meta.per_page, meta.current_page])
  const fetchWarehouses = async () => {
    try {
      const response = await api.get('/master/warehouses/all?is_parent=true')
      const { data } = response
      if (data.success) {
        setWarehouses(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchMaterialRequest = async () => {
    try {
      setIsLoading(true)
      const response = await api.get('/material-request/list', {
        params: {
          target_warehouse: filter.target_warehouse,
          src_warehouse: filter.src_warehouse,
          per_page: meta.per_page,
          page: meta.current_page
        }
      })
      const { data } = response

      if (data.success) {
        setMeta(data.metadata)
        setMaterialRequests(data.data)
        // if (filter.applyFilter) setFilter({ ...filter, applyFilter: false })
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={8}>
            <h1 style={headerTitleStyle}>Material Request</h1>
          </Col>
          {successNotification && (
            <Col md={4}>
              <div>
                <Card
                  className={`${styles.successNotification} me-4`}
                  style={{ cursor: 'pointer', maxWidth: '25.8vw' }}
                >
                  <Card.Body>
                    <Row>
                      <Col md={1}>
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="mt-1"
                            style={{
                              fontSize: '15px',
                              color: 'white'
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={11}>
                        <Row className="d-flex align-items-center">
                          <Col sm={10}>
                            <p className="text-light ms-2">Success!</p>
                          </Col>
                          <Col sm={2}>
                            <div
                              className="d-flex justify-content-end"
                              onClick={() => {
                                setSuccessNotification(false)
                              }}
                              role="button"
                            >
                              <FontAwesomeIcon
                                icon={faClose}
                                style={{
                                  fontSize: '15px',
                                  color: 'white',
                                  marginTop: '-.8rem',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Select
              name="source warehouse"
              value={filter.src_warehouse}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  src_warehouse: e.target.value
                }))
              }
            >
              <option value="">All</option>
              {warehouses.map((warehouse) => (
                <option
                  value={warehouse.warehouse_id}
                  key={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Select
              name="target warehouse"
              value={filter.target_warehouse}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  target_warehouse: e.target.value
                }))
              }
            >
              <option value="">All</option>
              {warehouses.map((warehouse) => (
                <option
                  value={warehouse.warehouse_id}
                  key={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}></Col>
          <Col md={6} className="text-end">
            <Button
              variant="transparent"
              className="btn text-secondary me-2"
              onClick={() => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  src_warehouse: '',
                  target_warehouse: ''
                }))
              }}
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => fetchMaterialRequest()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#MR</th>
                  <th className="p-3 align-middle">Source Warehouse</th>
                  <th className="p-3 align-middle">Target Warehouse</th>
                  <th className="p-3 align-middle">Delivery Date</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <td colSpan={5} className="text-center">
                    <div className="d-flex flex-row justify-content-center p-2">
                      <Loader />
                    </div>
                  </td>
                )}
                {!isLoading && materialRequests.length === 0 && (
                  <td colSpan={5} className="text-center fw-bold">
                    No data
                  </td>
                )}
                {!isLoading &&
                  materialRequests.length > 0 &&
                  materialRequests.map((item) => (
                    <>
                      <tr key={item.id}>
                        <td className="p-3 align-middle">{item.code ?? '-'}</td>
                        <td className="p-3 align-middle">
                          {item.src_name ?? '-'}
                        </td>
                        <td className="p-3 align-middle">
                          {item.target_name ?? '-'}
                        </td>
                        <td className="p-3 align-middle">
                          {item.delivery_date
                            ? moment(item.delivery_date).format('DD-MM-YYYY')
                            : '-'}
                        </td>
                        <td className="p-3 align-middle">
                          <button
                            className="btn btn-secondary"
                            onClick={() =>
                              navigate(`/material-request/detail/${item.id}`)
                            }
                          >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </button>
                          <button
                            className="btn btn-primary-blue ms-2"
                            onClick={() =>
                              navigate(`/material-request/create/${item.id}`)
                            }
                          >
                            +TR
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-3">
                    <div
                      className="d-flex flex-row gap-2"
                      style={{ marginTop: '-1rem' }}
                    >
                      <span className="mr-2 mt-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="show-page"
                        style={{ width: '60px' }}
                        value={meta.per_page}
                        onChange={handleOnChangeMeta}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="mt-2">
                        Entries | {meta.current_page} to {meta.per_page} of{' '}
                        {meta.total_row} entries
                      </span>
                    </div>
                  </td>
                  <td colspan={4} className="p-3">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        pageCount={meta.total_page}
                        onPageChange={handlePageChange}
                        page={meta.current_page}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MaterialRequest
