import { Col, Container, Form } from 'react-bootstrap'
import '../../../../../../styles/style.css'
function Check(props) {
  const { data, subMenu, handleChangeChecked } = props
  return (
    <div className="mb-4" key={data.id}>
      <Form.Check type="checkbox" id={data.id}>
        <Form.Check.Input
          type="checkbox"
          id={data.id}
          checked={data.is_checked}
          value={data.id}
          className="access-management"
          onChange={(e) => handleChangeChecked('parent', e)}
        />
        <Form.Check.Label htmlFor={data.id}>{data.menu}</Form.Check.Label>
      </Form.Check>
      {subMenu.length > 0 && (
        <Container className="mt-3">
          {subMenu.map((item, index) => (
            <Col md={12} className="mb-4" key={item.id}>
              <Form.Check.Input
                type="checkbox"
                id={item.id}
                className="access-management me-2"
                value={item.id}
                checked={item.is_checked}
                onChange={(e) => handleChangeChecked('child', e, data.id)}
              />
              <Form.Check.Label htmlFor={item.id}>{item.menu}</Form.Check.Label>
            </Col>
          ))}
        </Container>
      )}
    </div>
  )
}

export default Check
