import { faCheck, faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead, Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import RowEmpty from '../../../components/atoms/RowEmpty'
import ModalActionDenied from '../../../components/modal/CustomerDeliveryPlan/ModalActionDenied'
import styles from './index.module.css'

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

function Index() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [showModalActionDenied, setShowModalActionDenied] = useState(false)
  const [filter, setFilter] = useState({
    branch: '',
    src: '',
    customer: '',
    so: '',
    po: '',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add('5', 'days').format('YYYY-MM-DD')
  })
  const [checkedDraft, setCheckedDraft] = useState([])
  const [customerDeliveryPlans, setCustomerDeliveryPlans] = useState([])
  const [branches, setBranches] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [isClearableWarehouse, setIsClearableWarehouse] = useState(true)
  const [isSearchableWarehouse, setIsSearchableWarehouse] = useState(true)
  const [isLoadingWarehouse, setIsLoadingWarehouse] = useState(false)
  const [customers, setCustomers] = useState([])
  const [isClearableCustomer, setIsClearableCustomer] = useState(true)
  const [isSearchableCustomer, setIsSearchableCustomer] = useState(true)
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false)
  const [so, setSo] = useState([])
  const refSo = useRef(AsyncTypeahead)
  const filterSo = () => true
  const [isLoadingSo, setIsLoadingSo] = useState(false)

  useEffect(() => {
    const timeoutScroll = setTimeout(() => window.scrollTo(0, 0), 0)
    const timeoutCloseNotification = setTimeout(() => {
      navigate('', { state: null })
    }, 10000)
    getBranches()
    getWarehouses()
    getCustomers()
    getCustomerDeliveryPlans(filter)
    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => {
      clearTimeout(timeoutCloseNotification)
      clearTimeout(timeoutScroll)
    }
  }, [])
  // event
  const handleFilter = (name, value) => {
    if (name === 'startDate' || name === 'endDate') {
      let newValue = value

      if (value === '') {
        newValue =
          name === 'startDate'
            ? moment().format('YYYY-MM-DD')
            : moment().add(5, 'days').format('YYYY-MM-DD')
      }

      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: newValue
      }))
    } else {
      if (name !== 'so') {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: value ?? ''
        }))
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          [name]: value[0]?.so ?? ''
        }))
      }
    }
  }
  const handleChecked = (event, value) => {
    const { checked } = event.target
    if (checked) {
      // checked what value have in array
      if (checkedDraft.length === 0) {
        setCheckedDraft([...checkedDraft, value])
      } else {
        if (
          checkedDraft[0].customer === value.customer &&
          checkedDraft[0].so === value.so &&
          checkedDraft[0].delivery_date === value.delivery_date
        ) {
          setCheckedDraft([...checkedDraft, value])
        } else {
          event.target.checked = false
          setShowModalActionDenied(true)
        }
      }
    } else {
      setCheckedDraft(
        checkedDraft.filter((item) => item.item_code !== value.item_code)
      )
    }
  }
  const handleRedirect = () => {
    navigate('/outbound/sj')
  }
  const handleCloseNotification = (e) => {
    e.stopPropagation()
    navigate('', { state: null })
  }
  const handleResetFilter = () => {
    const valueFilter = {
      branch: '',
      src: '',
      customer: '',
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add('5', 'days').format('YYYY-MM-DD'),
      so: '',
      po: ''
    }
    setFilter(valueFilter)
    // clear value so on suggestion when value exists
    refSo.current.clear()
    getCustomerDeliveryPlans(valueFilter)
  }
  const handleApplyFilter = () => {
    getCustomerDeliveryPlans(filter)
    if (checkedDraft) {
      setCheckedDraft([])
    }
  }

  const getBranches = async () => {
    try {
      const res = await api.get('/erp/sales-order/all-branch')
      setBranches(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getWarehouses = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/erp/sales-order/all-source-wr')
      res.data.data.map((data) => {
        options.push({ value: data.warehouse, label: data.warehouse })
      })
      setWarehouses(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const getCustomers = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/erp/sales-order/all-customer')
      res.data.data.map((data) => {
        options.push({ value: data.customer, label: data.customer })
      })
      setCustomers(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  async function handleSearchSo(query) {
    try {
      setIsLoadingSo(true)

      if (query.length >= 3) {
        getSo(query)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingSo(false)
    }
  }
  const getSo = async (query) => {
    try {
      const res = await api.get('/erp/sales-order/all', {
        params: { q: query }
      })
      setSo(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getCustomerDeliveryPlans = async (filter) => {
    const params = {
      ...filter,
      src: filter.src?.value,
      customer: filter.customer?.value,
      so: filter.so
    }
    try {
      setLoading(true)
      const res = await api.get('/erp/sales-order', { params })
      setCustomerDeliveryPlans(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }
  const handleCreateSj = () => {
    const resultCustomerDeliveryPlan = checkedDraft.reduce((acc, current) => {
      const { customer, delivery_date, item_code, so } = current

      if (!acc.customer) {
        acc.customer = customer
        acc.delivery_date = delivery_date
        acc.items = []
        acc.so = so
      }

      acc.items.push(item_code)

      return acc
    }, {})
    navigate('/outbound/sj/create', { state: { resultCustomerDeliveryPlan } })
  }

  const getCheckboxProps = (customerDeliveryPlan) => {
    if (customerDeliveryPlan.available_qty === 0) {
      return { defaultChecked: true, disabled: true }
    }
    return {
      onChange: (e) =>
        handleChecked(e, {
          customer: customerDeliveryPlan.customer,
          so: customerDeliveryPlan.name,
          delivery_date: customerDeliveryPlan.delivery_date,
          item_code: customerDeliveryPlan.item_code
        })
    }
  }
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    })
  }

  return (
    <Container>
      <Row className="mb-3">
        <Col md={8}>
          <h3 style={headerTitleStyle}>Customer Delivery Plan</h3>
        </Col>
        {location.state && (
          <Col md={4}>
            <Card
              className={`${styles.successNotification}`}
              onClick={() => handleRedirect()}
              style={{ cursor: 'pointer' }}
            >
              <Card.Body>
                <Row>
                  <Col md={1}>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="mt-2"
                        style={{
                          fontSize: '15px',
                          color: 'white'
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={11}>
                    <Row className="d-flex align-items-center">
                      <Col sm={10}>
                        <p className="text-light ml-4 mt-1">
                          SJ Draft created!
                        </p>
                      </Col>
                      <Col sm={2}>
                        <div
                          className="d-flex justify-content-end"
                          onClick={(e) => {
                            handleCloseNotification(e)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            style={{
                              fontSize: '15px',
                              color: 'white',
                              marginTop: '-.8rem',
                              cursor: 'pointer'
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              Branch
            </Form.Label>
            <Form.Select
              aria-label="branch"
              name="branch"
              onChange={(e) => handleFilter('branch', e.target.value)}
              value={filter.branch}
            >
              <option value="">All</option>
              {branches.map((branch, index) => (
                <option key={index} value={branch.branch}>
                  {branch.branch}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              Source Warehouse
            </Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={isLoadingWarehouse}
              isClearable={isClearableWarehouse}
              isSearchable={isSearchableWarehouse}
              onChange={(e) => handleFilter('src', e)}
              value={filter.src}
              name="warehouse"
              options={warehouses}
              styles={customStyles}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              Customer
            </Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={isLoadingCustomer}
              isClearable={isClearableCustomer}
              isSearchable={isSearchableCustomer}
              onChange={(e) => handleFilter('customer', e)}
              value={filter.customer}
              name="customer"
              options={customers}
              styles={customStyles}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              Start Date
            </Form.Label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              className="form-control"
              value={filter.start_date}
              onChange={(e) => handleFilter('start_date', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              End Date
            </Form.Label>
            <input
              type="date"
              name="endDate"
              id="endDate"
              className="form-control"
              value={filter.end_date}
              onChange={(e) => handleFilter('end_date', e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              #SO
            </Form.Label>
            <AsyncTypeahead
              id="basic-typeahead-single"
              labelKey="so"
              isLoading={isLoadingSo}
              filterBy={filterSo}
              onSearch={handleSearchSo}
              onChange={(e) => {
                handleFilter('so', e)
              }}
              value={filter.so}
              options={so}
              placeholder="Choose a so ..."
              ref={refSo}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              #PO
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter #PO"
              id="po"
              name="po"
              onChange={(e) => handleFilter('po', e.target.value)}
              value={filter.po}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <div className="d-flex h-100 align-items-center justify-content-end">
            <Button variant="transparent" onClick={() => handleResetFilter()}>
              Reset Filter
            </Button>
            <Button
              className="btn-primary-orange"
              style={{ border: 'none' }}
              onClick={() => handleApplyFilter()}
            >
              Apply Filter
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-3">
        <Col md={6}>
          <p className="text-muted">
            Please select item with same SO, Customer and Delivery Date to
            create SJ Draft
          </p>
        </Col>
        <Col md={6}>
          <div className="d-flex align-items-end h-100 justify-content-end">
            <Button
              className={
                checkedDraft.length ? 'btn-primary-blue' : 'btn-secondary'
              }
              disabled={checkedDraft.length ? false : true}
              onClick={() => handleCreateSj()}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: 'white' }}
                className="ml-2"
              />
              <span className="ml-2">Create SJ Draft</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded table-responsive"
          size="sm"
          style={{ fontSize: '14px', overflowX: 'auto' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle"></th>
              <th className="p-2 align-middle" width="20%">
                Customer
              </th>
              <th className="p-2 align-middle">#SO</th>
              <th className="p-2 align-middle">Item</th>
              <th className="p-2 align-middle">Qty</th>
              <th className="p-2 align-middle">UOM</th>
              <th className="p-2 align-middle">Permintaan Kirim</th>
              <th className="p-2 align-middle">#PO</th>
              <th className="p-2 align-middle">Sales</th>
              <th className="p-2 align-middle">Alamat</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <div className="text-center">
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
            {!loading && !customerDeliveryPlans.length && (
              <RowEmpty colSpan={10} />
            )}
            {!loading &&
              customerDeliveryPlans.length > 0 &&
              customerDeliveryPlans.map((customerDeliveryPlan, index) => (
                <tr key={index}>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    <input
                      type="checkbox"
                      className="access-management form-check-input"
                      style={{ cursor: 'pointer' }}
                      {...getCheckboxProps(customerDeliveryPlan)}
                    />
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.customer}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.name}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.item_name}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.qty}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.uom}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {moment(customerDeliveryPlan.delivery_date).format(
                      'DD-MM-YYYY'
                    )}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.po_number ?? '-'}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {customerDeliveryPlan.sales_person}
                  </td>
                  <td
                    className="p-2 align-middle"
                    style={{
                      backgroundColor: customerDeliveryPlan.item_alias
                        ? '#FF8F8F'
                        : 'white'
                    }}
                  >
                    {!customerDeliveryPlan.address && '-'}
                    {customerDeliveryPlan.address && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: customerDeliveryPlan.address
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={10}>
                <div className="p-2 d-flex">
                  <p className="text-muted">
                    Showing {customerDeliveryPlans.length} Entries
                  </p>
                  <div className="text-muted d-flex ml-4">
                    <div
                      style={{
                        backgroundColor: '#FF8F8F',
                        height: '15px',
                        width: '15px',
                        marginTop: '.2rem'
                      }}
                    ></div>
                    <span className="ml-2">Need Item Alias</span>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
      <ModalActionDenied
        show={showModalActionDenied}
        onHide={() => setShowModalActionDenied(false)}
      />
    </Container>
  )
}

export default Index
