import { Modal, Row, Form, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import { ChangePasswordSchema } from '../../../utils/validationSchema'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalChangePassword(props) {
  async function handleSubmit(values) {
    try {
      const params = {
        old_password: values.CurrentPassword,
        password: values.NewPassword,
        password_confirmation: values.ConfirmPassword
      }
      const res = await api.post('/users/change-password', params)

      if (res.data.success) {
        props.onHide()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <>
      <Modal {...props} centered>
        <Formik
          initialValues={{
            CurrentPassword: '',
            NewPassword: '',
            ConfirmPassword: ''
          }}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={ChangePasswordSchema}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col>
                      <TextField
                        type="password"
                        label="current password"
                        name="CurrentPassword"
                        value={values.CurrentPassword}
                        placeholder="Enter current password"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        type="password"
                        label="new password"
                        name="NewPassword"
                        value={values.NewPassword}
                        placeholder="Enter new password"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        type="password"
                        label="confirm new password"
                        name="ConfirmPassword"
                        value={values.ConfirmPassword}
                        placeholder="Enter confirm password"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default ModalChangePassword
