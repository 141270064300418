import { Field, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import {
  AddPrTypeSchemaPo,
  AddPrTypeSchemaPr
} from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'

function ModalTypePr(props) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data } = props

  const filterPo = () => true
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [po, setPo] = useState('')

  async function handleSearchPo(query) {
    setLoading(true)
    try {
      const params = `?po=${query}`
      const res = await api.get(`inbound/purchase-order/all${params}`)
      setOptions(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const refPo = useRef(AsyncTypeahead)

  const handleSubmit = async (values) => {
    const type = values.type
    data
      ? navigate(
          `/inbound/purchase-order/create/${encodeURIComponent(data.name)}`,
          {
            state: { type: type }
          }
        )
      : navigate(`/inbound/purchase-order/create/${encodeURIComponent(po)}`, {
          state: { type: type }
        })
  }

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{data ? 'Pilih Tipe PR' : 'Select PO'}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        validationSchema={
          pathname === '/purchase-order' ? AddPrTypeSchemaPo : AddPrTypeSchemaPr
        }
        initialValues={{
          po: '',
          type: ''
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ handleSubmit, isValid, dirty, setFieldValue }) => {
          return (
            <>
              <Form onSubmit={handleSubmit} className="">
                <Modal.Body>
                  <Row style={{ fontSize: '14px' }}>
                    {data ? (
                      <>
                        <Col md={12}>
                          <Form.Label
                            className="fw-bold text-muted"
                            style={{ fontSize: '12px' }}
                          >
                            TYPE
                          </Form.Label>
                        </Col>
                        <Col>
                          <label className="me-5">
                            <Field type="radio" name="type" value="local" />
                            <span className="ms-2">Local</span>
                          </label>
                          <label>
                            <Field type="radio" name="type" value="import" />
                            <span className="ms-2">Import</span>
                          </label>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={12} className="mb-3">
                          <Form.Label
                            className="fw-bold text-muted"
                            style={{ fontSize: '12px' }}
                          >
                            #PO
                          </Form.Label>
                          <AsyncTypeahead
                            id="basic-typeahead-single"
                            labelKey="po"
                            isLoading={loading}
                            filterBy={filterPo}
                            onSearch={handleSearchPo}
                            onChange={(e) => {
                              setPo(e.length > 0 ? e[0].po : '')
                              setFieldValue('po', e.length > 0 ? e[0].po : '')
                              if (!e.length) {
                                setFieldValue('type', '')
                              }
                            }}
                            options={options}
                            placeholder="Choose a po ..."
                            ref={refPo}
                          />
                        </Col>
                        <Col md={12}>
                          <Form.Label
                            className="fw-bold text-muted"
                            style={{ fontSize: '12px' }}
                          >
                            TYPE
                          </Form.Label>
                        </Col>
                        <Col>
                          <label className="me-5">
                            <Field
                              type="radio"
                              name="type"
                              value="local"
                              disabled={po ? false : true}
                            />
                            <span className="ms-2">Local</span>
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="type"
                              value="import"
                              disabled={po ? false : true}
                            />
                            <span className="ms-2">Import</span>
                          </label>
                        </Col>
                      </>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer className="mt-4">
                  <CustomButton
                    onClick={handleSubmit}
                    variant={!isValid || !dirty ? 'secondary' : 'warning'}
                    disabled={!isValid || !dirty}
                    text={'Save'}
                  />
                </Modal.Footer>
              </Form>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalTypePr
