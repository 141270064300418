import React from 'react'
import { Form } from 'react-bootstrap'

const index = ({ label, value, col, address, classNameValue = 'fw-bold' }) => {
  return (
    <Form.Group className={`mb-3 col-${col}`} controlId="po">
      <Form.Label
        className={`text-uppercase ${classNameValue}`}
        style={{ fontSize: '13px', letterSpacing: '0.5px' }}
      >
        {label}
      </Form.Label>
      {address ? (
        <div
          dangerouslySetInnerHTML={{
            __html: value
          }}
          style={{ backgroundColor: '#e0e0e0', padding: '1rem' }}
          className="w-100 rounded"
        />
      ) : (
        <Form.Control
          value={value}
          readOnly={true}
          className="form-control-readonly"
        />
      )}
    </Form.Group>
  )
}

export default index
