import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import ModalAddBatch from '../../../components/modal/batch/ModalAddBatch'
import ModalUpdateBatch from '../../../components/modal/batch/ModalUpdateBatch'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { formatDateDayMonthYear } from '../../../utils/FormatDate'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '14px'
}

const iconStyle = {
  fontSize: '25px',
  rotate: '265deg'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}

const MasterBatch = () => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [batches, setBatches] = useState([])
  const [meta, setMeta] = useState(initialMeta)
  const [editId, setEditId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const { per_page, current_page } = meta

  useEffect(
    function () {
      fetchBatches(per_page, current_page, query)
    },
    [isAdd, isUpdate, per_page, current_page]
  )

  async function fetchBatches(per_page, current_page, query) {
    setLoading(true)
    try {
      const params = {
        q: query || '',
        per_page,
        page: current_page
      }

      const response = await api.get('master/batch', { params })
      const { data, metadata } = response.data

      setBatches(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  const handleEdit = (id) => {
    setShowModalUpdate((show) => !show)
    setEditId(id)
  }

  const handleClickSearch = () => {
    fetchBatches(per_page, current_page, query)
  }

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      fetchBatches(per_page, current_page, query)
    }
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Master Batch</h3>
      <div className="row">
        <div className="col-7">
          <h4 className="text-muted mt-3 text-uppercase" style={titleStyle}>
            Batch List
          </h4>
        </div>
        <div className="col-5">
          <div className="d-flex justify-content-end">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="search"
                aria-describedby="basic-addon2"
                onKeyDown={handleSearch}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div className="input-group-append" style={{ cursor: 'pointer' }}>
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  onClick={handleClickSearch}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={iconStyle}
                    className="fa-flip-horizontal"
                  />
                </span>
              </div>
            </div>
            <div>
              <button
                className="btn btn-primary-orange ms-2"
                onClick={() => setShowModalAdd(true)}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle text-center">No</th>
              <th className="p-2 align-middle">Batch</th>
              <th className="p-2 align-middle">Item Name</th>
              <th className="p-2 align-middle">Date Patch</th>
              <th className="p-2 align-middle">Exp. Date</th>
              <th className="p-2 align-middle">Note</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={7}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && batches.length === 0 && <RowEmpty colSpan={7} />}
            {!loading &&
              batches?.map((item, i) => (
                <TbodyRow
                  data={item}
                  key={item.id}
                  number={i + 1}
                  handleEdit={() => handleEdit(item.id)}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4} className="p-3">
                <div className="d-flex align-items-center">
                  <span className="mr-2">Show</span>
                  <select
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '80px' }}
                    value={meta.per_page}
                    onChange={(e) => handleOnChangeMeta(e)}
                  >
                    {[10, 25, 50, 100].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className="mt-2 ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={3} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={meta.total_page}
                    onPageChange={handlePageChange}
                    page={meta.current_page}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddBatch
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        onAdd={() => setIsAdd((add) => !add)}
      />
      <ModalUpdateBatch
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        data={editId}
        setIsUpdate={setIsUpdate}
      />
    </div>
  )
}

function TbodyRow({ data, handleEdit, number }) {
  return (
    <tr key={data.id}>
      <td className="p-2 text-center align-middle">{number}</td>
      <td className="p-2 align-middle">{data.batch}</td>
      <td className="p-2 align-middle">{data.item?.item_name}</td>
      <td className="p-2 align-middle">
        {data.date_patch ? formatDateDayMonthYear(data.date_patch) : '-'}
      </td>
      <td className="p-2 align-middle">
        {data.exp_date ? formatDateDayMonthYear(data.exp_date) : '-'}
      </td>
      <td className="p-2 align-middle">{data.note ?? '-'}</td>
      <td className="p-2 align-middle text-end">
        <button className="btn btn-secondary ml-2" onClick={handleEdit}>
          <FontAwesomeIcon icon={faEye} style={{ fontSize: '15px' }} />
        </button>
      </td>
    </tr>
  )
}

export default MasterBatch
