import React from 'react'
import { Modal } from 'react-bootstrap'
import 'react-toggle/style.css'
import '../../../styles/style.css'
import CustomButton from '../../Button/CustomButton'

function ModalDownloadQr(props) {
  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>QR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex w-100 justify-content-center">
          <img
            src={props.paletData.src}
            alt={props.paletData.paletName}
            className="img-fluid"
            width={'300'}
            height={'300'}
          />
        </div>
        <h5 className="fw-bold text-center">
          {props.type ?? 'Palet'} #{props.paletData.paletName}
        </h5>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <div className="d-flex w-100 justify-content-center">
          <CustomButton
            onClick={() =>
              downloadQr(props.paletData.src, props.paletData.paletName)
            }
            variant={'warning'}
            text={'Download QR'}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}
const downloadQr = (imageSrc, imageName) => {
  const link = document.createElement('a')
  link.href = imageSrc
  link.download = imageName + '.png'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export default ModalDownloadQr
