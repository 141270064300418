import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { setAuthToken } from '../config/api'
import { UserContext } from '../context/userContext'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

function PrivateRoutes() {
  const navigate = useNavigate()
  const [state, dispatch] = useContext(UserContext)
  const { pathname } = useLocation()
  const slugs = state.username.access
    .reduce((acc, current) => {
      if (current.submenu.length) {
        current.submenu.forEach((submenu) => {
          if (submenu.slug === 'inbound/purchase-receipt') {
            acc.push('inbound/purchase-order*')
          }
          acc.push(submenu.slug ? submenu.slug + '*' : null)
        })
      }

      acc.push(current.slug ? current.slug + '*' : null)
      return acc
    }, [])
    .filter((value) => value)
  const regexPatterns = slugs.map((pattern) => {
    const regexString = pattern.replace(/\*/g, '.*')
    return new RegExp(`^${regexString}$`)
  })
  // Check if pathValue matches any pattern
  const isMatch = (pathValue) => {
    return regexPatterns.some((regex) => regex.test(pathValue))
  }
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token)
    } else {
      navigate('/')
    }
  }, [state])

  return (
    <>
      {/* <Outlet /> */}
      {pathname !== '/dashboard' &&
      pathname !== '/' &&
      pathname !== '/notification' &&
      pathname !== '/arrival/receive-at-warehouse-plan' &&
      !isMatch(pathname.substring(1, pathname.length)) ? (
        <Navigate to="/dashboard" replace />
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PrivateRoutes
