import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Nav, Row, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import { ActiveInActive } from '../../../components/atoms/StatusLabel'
import ModalAddDriver from '../../../components/modal/driver/ModalAddDriver'
import ModalEditDriver from '../../../components/modal/driver/ModalEditDriver'

const DRIVER_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external'
}

const MasterDriver = () => {
  const [modalAdd, setModalAdd] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [editId, setEditId] = useState(0)
  const [drivers, setDrivers] = useState([])
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(DRIVER_TYPE.INTERNAL)

  const { per_page, current_page } = meta

  useEffect(() => {
    fetchDrivers()
  }, [per_page, current_page, activeKey])

  const fetchDrivers = async () => {
    setLoading(true)
    try {
      const res = await api.get(
        `master/driver/list${
          activeKey === DRIVER_TYPE.EXTERNAL ? '-external' : ''
        }`,
        {
          params: { per_page, page: current_page }
        }
      )
      const { metadata, data } = res.data
      setMeta(metadata)
      setDrivers(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({ ...prevMeta, per_page: perPageValue }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  const handleSelectActive = (key) => {
    setActiveKey(key)
  }

  const handleEdit = (id) => {
    setModalEdit(true)
    setEditId(id)
  }

  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  return (
    <>
      <Container>
        <Row>
          <h3 style={headerTitleStyle}>Master Driver</h3>
        </Row>
        <Row className="p-2">
          <Nav
            variant="underline"
            onSelect={handleSelectActive}
            defaultActiveKey={activeKey}
            className="ml-2"
          >
            <Nav.Item>
              <Nav.Link eventKey={DRIVER_TYPE.INTERNAL} className="text-muted">
                Internal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={DRIVER_TYPE.EXTERNAL} className="text-muted">
                External
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr />
        </Row>
        <Row className="mt-3">
          <Col md={4}>
            <small className="text-secondary text-uppercase">
              <strong>Driver List</strong>
            </small>
          </Col>
          <Col md={8} className="text-end">
            <Button
              className="btn btn-warning me-2 text-white"
              onClick={() => setModalAdd(true)}
            >
              Add
            </Button>
            <ModalAddDriver
              show={modalAdd}
              onHide={() => setModalAdd(false)}
              refreshData={fetchDrivers}
              activeKey={activeKey}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              responsive="sm"
              hover
              className="shadow-sm mb-5 bg-white rounded"
              size="sm"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">Name</th>
                  <th className="p-2 align-middle">
                    {activeKey === DRIVER_TYPE.INTERNAL ? 'Address' : 'SIM'}
                  </th>
                  <th className="p-2 align-middle">
                    {activeKey === DRIVER_TYPE.INTERNAL
                      ? 'Phone Number'
                      : 'KTP'}
                  </th>
                  <th className="p-2 align-middle">Status</th>
                  <th className="p-2 align-middle text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  drivers.map((data, i) => (
                    <tr key={i}>
                      <td className="p-2 align-middle">{data?.name}</td>
                      <td className="p-2 align-middle">
                        {activeKey === DRIVER_TYPE.INTERNAL ? (
                          data?.address
                        ) : (
                          <Link
                            to={data?.sim_doc}
                            className="text-decoration-none"
                          >
                            {data.sim_doc ? data.sim_doc.split('/').pop() : '-'}
                          </Link>
                        )}
                      </td>
                      <td className="p-2 align-middle">
                        {activeKey === DRIVER_TYPE.INTERNAL ? (
                          data?.phone_number
                        ) : (
                          <Link
                            to={data?.ktp_doc}
                            className="text-decoration-none"
                          >
                            {data?.ktp_doc
                              ? data?.ktp_doc.split('/').pop()
                              : '-'}
                          </Link>
                        )}
                      </td>
                      <td className="p-2 align-middle">
                        <ActiveInActive
                          text={data.status ? 'active' : 'inactive'}
                        />
                      </td>
                      <td className="p-2 align-middle text-end">
                        <button
                          className="btn btn-secondary ml-2"
                          onClick={() => handleEdit(data?.id)}
                          aria-label={`Edit ${data?.name}`}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            style={{ fontSize: '15px', rotate: '90deg' }}
                            className="fa-flip-horizontal"
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3} className="p-3">
                    <div className="d-flex align-items-center">
                      <span className="mr-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="Show entries"
                        style={{ width: '60px' }}
                        value={meta.per_page}
                        onChange={handleOnChangeMeta}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="ml-2">
                        Entries | {meta.current_page} to {meta.per_page} of{' '}
                        {meta.total_row} entries
                      </span>
                    </div>
                  </td>
                  <td colSpan={2} className="p-3">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        pageCount={meta.total_page}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
      <ModalEditDriver
        show={modalEdit}
        onHide={() => setModalEdit(false)}
        id={editId}
        refreshData={fetchDrivers}
        activeKey={activeKey}
      />
    </>
  )
}

export default MasterDriver
