import { Form, Modal } from 'react-bootstrap'
import '../../../styles/style.css'
import { formatThousandSeparator } from '../../../utils/TextFormatter'
import CustomButton from '../../Button/CustomButton'
function ModalAddVehicle(props) {
  // style
  // state
  // event handler
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Select Car</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form checkbox driver */}
        {props.vehicles.map((vehicle, index) => (
          <Form.Check
            key={index}
            label={
              <p
                className={`ml-3 ${
                  vehicle?.volume < props.totalVolume ? 'text-danger' : ''
                }`}
              >
                {`${vehicle.plate_no} - ${
                  vehicle.vehicle_type
                } - ${formatThousandSeparator(
                  vehicle.capacity
                )} Kg - ${formatThousandSeparator(vehicle.volume)} cm3`}
              </p>
            }
            name="vehicle"
            type={'radio'}
            id={`vehicle-${vehicle.id}`}
            value={vehicle.id}
            className={`outbound-vehicle ${index > 0 ? 'mt-3' : ''}`}
            onChange={(e) => props.onHandleChangeSelectVehicle(e.target.value)}
          />
        ))}
        <Modal.Footer className="mt-3">
          <CustomButton
            onClick={props.onHandleSelectVehicle}
            variant={!props.dataChangeSelectVehicle ? 'secondary' : 'warning'}
            text={'Save'}
          />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}
export default ModalAddVehicle
