import { Form, Row, Modal, Col } from 'react-bootstrap'
import * as common from '../../../utils/Common'
import { Formik } from 'formik'
import api from '../../../config/AxiosInstance'
import { RoleAddEditSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'

function ModalAddRole(props) {
  const handleSubmit = async (values) => {
    try {
      const res = await api.post(`/cms/role/add`, values)

      if (res.data.success) {
        props.onHide()
        props.refreshData()
        common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errmsg = err?.response?.data?.errors?.message || 'An error occurred'
      common.showPopUpErrorMessage(errmsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            role_code: '',
            role_name: ''
          }}
          validationSchema={RoleAddEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <TextField
                      label="role code"
                      name="role_code"
                      value={values.role_code}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input code"
                    />
                  </Col>
                  <Col md={8}>
                    <TextField
                      label="role name"
                      name="role_name"
                      value={values.role_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input role name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Add'}
                    />
                  </Modal.Footer>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddRole
