import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function ModalCancelSjDraft(props) {
  const navigate = useNavigate()
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-5">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: 'grey', fontSize: '100px' }}
              className="ml-2"
            />
          </div>
          <div className="text-center mt-3">
            <h5>Cancel Creating Ticket?</h5>
            <h6 className="fw-bold">Draft will be discarded</h6>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={props.onHide}>
            Continue creating
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              navigate('/outbound/customer/delivery/plan/', { state: null })
            }
          >
            Discard
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalCancelSjDraft
