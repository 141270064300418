import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Col, Container, FormControl, Row, Table } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import Loader from '../../atoms/Loader'
import Pagination from '../../atoms/Pagination/Index'
import ModalAddRole from '../../modal/role/ModalAddRole'
import ModalUpdateRole from '../../modal/role/ModalUpdateRole'

function MasterRole() {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const tableStyle = {
    fontSize: '15px'
  }

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [query, setQuery] = useState('')
  const [code, setCode] = useState('')
  const [roles, setRoles] = useState([])
  const [editId, setEditId] = useState(0)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const { per_page, current_page } = meta

  const delayTimerRef = useRef(null)

  const loadRole = async (per_page, current_page, query, code) => {
    setLoading(true)
    try {
      const res = await api.get('/cms/role/list', {
        params: {
          role_code: code,
          role_name: query,
          per_page,
          page: current_page
        }
      })
      setLoading(false)

      const { metadata, data } = res.data

      setRoles(data)
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    clearTimeout(delayTimerRef.current)

    delayTimerRef.current = setTimeout(() => {
      loadRole(per_page, current_page, query, code)
    }, 500)

    return () => clearTimeout(delayTimerRef.current)
  }, [per_page, current_page, query, code])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(id) {
    setShowModalUpdate(true)
    setEditId(id)
  }

  return (
    <>
      <Container className="mb-4">
        <Row>
          <h4 style={headerTitleStyle} className="mb-3">
            Master Role
          </h4>
        </Row>
        <Row>
          <Col md={2}>
            <FormControl
              type="text"
              name="role_code"
              placeholder="Search Code..."
              onChange={(e) => setCode(e.target.value)}
            ></FormControl>
          </Col>
          <Col md={10}>
            <FormControl
              type="text"
              name="role_name"
              placeholder="Search Name..."
              onChange={(e) => setQuery(e.target.value)}
            ></FormControl>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h4 className="text-muted" style={{ fontSize: '20px' }}>
            ROLE LIST
          </h4>
          <button
            className="btn btn-primary-orange"
            onClick={() => setShowModalAdd(true)}
          >
            Add
          </button>
        </div>
        <Row className="mt-2">
          <Table
            responsive="sm"
            hover
            className="shadow-sm bg-white rounded"
            size="sm"
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">Role Code</th>
                <th className="p-2 align-middle">Role Name</th>
                <th className="p-2 align-middle text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading && roles.length === 0 && (
                <tr>
                  <td colSpan={3} className="fw-bold text-center">
                    No data
                  </td>
                </tr>
              )}
              {!loading &&
                roles.length > 0 &&
                roles.map((role) => {
                  return (
                    <tr key={role.id}>
                      <td className="p-2 align-middle" style={tableStyle}>
                        {role.role_code}
                      </td>
                      <td className="p-2 align-middle" style={tableStyle}>
                        {role.role_name}
                      </td>
                      <td className="p-2 align-middle text-center">
                        <button
                          className="btn btn-secondary ml-2"
                          onClick={() => handleEdit(role.id)}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{ fontSize: '15px', rotate: '90deg' }}
                            className="fa-flip-horizontal"
                          />
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} className="p-3">
                  <div className="d-flex">
                    <div className="mt-2 mr-2">Show</div>
                    <select
                      className="form-select"
                      aria-label="show-page"
                      style={{ width: '13%' }}
                      value={meta.per_page}
                      onChange={(e) => handleOnChangeMeta(e)}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <span className="mt-2 ml-2">
                      Entries | {meta.current_page} to {meta.per_page} of{' '}
                      {meta.total_row} entries
                    </span>
                  </div>
                </td>
                <td colSpan={2} className="p-3">
                  <div className="d-flex justify-content-end">
                    <Pagination
                      pageCount={pageCount}
                      onPageChange={handlePageChange}
                      page={current_page}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Row>
      </Container>
      <ModalAddRole
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        refreshData={() => loadRole(per_page, current_page, query, code)}
      ></ModalAddRole>
      <ModalUpdateRole
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        id={editId}
        refreshData={() => loadRole(per_page, current_page, query, code)}
      ></ModalUpdateRole>
    </>
  )
}

export default MasterRole
