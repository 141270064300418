import { faArrowCircleRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import L from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import React, { useEffect, useRef, useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import {
  FeatureGroup,
  ImageOverlay,
  MapContainer,
  Rectangle
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import ModalMapEditor from '../../../components/modal/mapeditor/ModalMapEditor'
import ModalUpdateMapEditor from '../../../components/modal/mapeditor/ModalUpdateMapEditor'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

function MapEditorDetail({
  data,
  step,
  onHandleResetDetailData,
  onHandleStep
}) {
  const iconStyle = {
    fontSize: '20px',
    cursor: 'pointer'
  }

  const [rectangleBounds, setRectangleBounds] = useState([])
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [file, setFile] = useState(null)
  const [selectLevel, setSelectLevel] = useState(null)
  const [showDataMap, setShowDataMap] = useState(0)
  const [dataMapEditor, setDataMapEditor] = useState([])
  const resetDetailData = () => onHandleResetDetailData()
  const hiddenFileInput = useRef(null)
  const arrLevel = [
    '1',
    'on-ground',
    'staging-area',
    'area-vas',
    'area-vas-pharma',
    'area-transit-vas',
    'area-transit-vas-pharma'
  ]
  const _created = (e) => {
    const { layerType, layer } = e
    if (layerType === 'rectangle') {
      const bounds = layer.getBounds()
      const southWest = bounds.getSouthWest()
      const northEast = bounds.getNorthEast()
      const southEast = bounds.getSouthEast()
      const northWest = bounds.getNorthWest()
      const coordinates = [
        [southWest.lat, southWest.lng],
        [northEast.lat, northEast.lng],
        [southEast.lat, southEast.lng],
        [northWest.lat, northWest.lng]
      ]
      setRectangleBounds(coordinates)
    }

    setShowModalAdd(true)
  }
  const _deleted = (e) => setRectangleBounds([])
  const _edited = (e) => {
    console.log(e)
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    try {
      const formData = new FormData()
      formData.append('warehouse_id', data.id)
      formData.append('map_image', event.target.files[0])
      await api.postForm('settings/map', formData)
      setFile(URL.createObjectURL(event.target.files[0]))
      Common.showPopUpSuccessMessage('Upload map successfully')
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSelectLevel = (e) => {
    setSelectLevel(e.match(/(\d+)/) ? Number(e) : e)
    arrLevel.filter((level) => level === e).length > 0
      ? document
          .querySelector('.leaflet-draw.leaflet-control')
          ?.classList.remove('d-none')
      : document
          .querySelector('.leaflet-draw.leaflet-control')
          ?.classList.add('d-none')
  }

  const listMapEditor = async (level, warehouse_id) => {
    try {
      if (![Number(level), 'on-ground', 'staging-area'].includes(level)) {
        if (dataMapEditor) {
          setDataMapEditor([])
        }
        return
      }
      let type = ''
      if (Number(level)) {
        type = 'rack'
      } else if (level === 'on-ground') {
        type = 'ground'
      } else if (level === 'staging-area') {
        type = 'staging'
      }
      const values = {
        level,
        warehouse_id,
        type
      }
      const res = await api.post(`settings/map/editor/slot/list`, values)
      setDataMapEditor(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  function handleRectangleClick(rectangleId) {
    if (step === 1) {
      setShowModalUpdate(true)
      setShowDataMap(rectangleId)
    }
    return
  }

  useEffect(
    function () {
      if (data) {
        setFile(data.warehouse_map)
      }
    },
    [data]
  )

  useEffect(
    function () {
      if (data && selectLevel) {
        listMapEditor(selectLevel, data.id)
      }
    },
    [selectLevel, data]
  )
  return (
    <>
      <div className="d-flex">
        <FontAwesomeIcon
          icon={faArrowCircleRight}
          style={iconStyle}
          className="fa-flip-horizontal mt-1"
          onClick={() =>
            !step
              ? resetDetailData()
              : Common.showPopUpConfirmation(
                  'Leave Page?',
                  'Continue Updating',
                  'Discard Change and Leave Page',
                  resetDetailData
                )
          }
        />
        <h5 className="fw-bold ml-3" style={{ marginTop: '.2rem' }}>
          Map Editor ({data.warehouse_name})
        </h5>
      </div>

      <div className="row mt-4">
        <div className="col-8">
          <div className="card">
            <div className="card-body card-map-pnp">
              <div className="leaflet-map-container">
                <MapContainer
                  center={[500, 500]}
                  zoom={1}
                  crs={L.CRS.Simple}
                  scrollWheelZoom={false}
                  style={{ height: '100%', width: '100%' }}
                >
                  {step === 1 &&
                    [Number('1'), 'on-ground', 'staging-area'].includes(
                      selectLevel
                    ) && (
                      <FeatureGroup>
                        <EditControl
                          position="topright"
                          onEdited={_edited}
                          onCreated={_created}
                          onDeleted={_deleted}
                          draw={{
                            rectangle: true,
                            circle: false,
                            circlemarker: false,
                            marker: false,
                            polyline: false,
                            polygon: false
                          }}
                        />
                      </FeatureGroup>
                    )}
                  <ImageOverlay
                    url={file ?? 'http://localhost:3000/map.jpeg'}
                    bounds={[
                      [0, 0],
                      [1000, 1000]
                    ]}
                  />
                  {dataMapEditor.length > 0 &&
                    dataMapEditor?.map((value, i) => (
                      <Rectangle
                        bounds={value.coordinate}
                        eventHandlers={{
                          click: () => handleRectangleClick(value.id)
                        }}
                        color="transparent"
                        fillColor={
                          value.status === 'empty' ? '#B4C6E7' : '#C12727'
                        }
                        fillOpacity={0.5}
                        key={i}
                      ></Rectangle>
                    ))}
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {dataMapEditor?.filter((mapEditor) => mapEditor.status === 'full')
            .length > 0 || step === 1 ? (
            <>
              <ListGroup>
                <ListGroup.Item
                  className="p-3"
                  disabled={true}
                  style={{ backgroundColor: '#F1F2F7' }}
                >
                  Level
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('staging-area')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Staging Area</span>
                    </div>
                    {selectLevel === 'staging-area' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('area-vas')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Area VAS</span>
                    </div>
                    {selectLevel === 'area-vas' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('area-vas-pharma')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Area VAS Pharma</span>
                    </div>
                    {selectLevel === 'area-vas-pharma' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('area-transit-vas')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Area Transit VAS</span>
                    </div>
                    {selectLevel === 'area-transit-vas' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('area-transit-vas-pharma')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Area Transit VAS Pharma</span>
                    </div>
                    {selectLevel === 'area-transit-vas-pharma' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('on-ground')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>On Ground</span>
                    </div>
                    {selectLevel === 'on-ground' && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('1')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 1</span>
                    </div>
                    {selectLevel === 1 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('2')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 2</span>
                    </div>
                    {selectLevel === 2 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('3')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 3</span>
                    </div>
                    {selectLevel === 3 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('4')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 4</span>
                    </div>
                    {selectLevel === 4 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('5')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 5</span>
                    </div>
                    {selectLevel === 5 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  className="p-3"
                  onClick={(e) => handleSelectLevel('6')}
                >
                  <div className="row">
                    <div className="col-10">
                      <span>Level 6</span>
                    </div>
                    {selectLevel === 6 && (
                      <div className="col-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ fontSize: '18px' }}
                        ></FontAwesomeIcon>
                      </div>
                    )}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </>
          ) : (
            <>
              <button
                className="btn btn-primary-orange d-block w-100 fw-bold"
                onClick={() => onHandleStep(1)}
              >
                Continue
              </button>
              <button
                className="btn btn-outline-secondary mt-2 w-100 fw-bold"
                onClick={handleClick}
              >
                Change Image
              </button>
              <input
                type="file"
                name="change_image"
                id="change_image"
                accept="image/*"
                className="d-none"
                onChange={handleChange}
                ref={hiddenFileInput}
              />
            </>
          )}
        </div>
      </div>
      <ModalMapEditor
        show={showModalAdd}
        onHide={() => {
          setShowModalAdd(false)
          document
            .querySelector(".leaflet-interactive[stroke='#3388ff']")
            ?.remove()
        }}
        rectangleBoundValue={rectangleBounds}
        selectLevel={selectLevel}
        warehouseId={data.id}
        onHandleListMapEditor={() => listMapEditor(selectLevel, data.id)}
      />
      <ModalUpdateMapEditor
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        onSelectlevel={selectLevel}
        dataShowMap={showDataMap}
        warehouseId={data.id}
        onHandleListMapEditor={() => listMapEditor(selectLevel, data.id)}
      />
    </>
  )
}
export default MapEditorDetail
