import * as Yup from 'yup'

const CategoryAddEditSchema = Yup.object().shape({
  category_name: Yup.string()
    .min(2, 'Category name minimum 2 characters')
    .max(100, 'Category name maximum 100 characters')
    .required('Category name is required'),
  status: Yup.string().required('Status is required')
})

const UserAddSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, 'Username minimum 2 characters')
    .max(100, 'Username maximum 100 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(6, 'Password minimum 6 characters')
    .max(16, 'Password maximum 16 characters')
    .required('Password is required')
})

const UserEditSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, 'Username minimum 2 characters')
    .max(100, 'Username maximum 100 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(6, 'Password minimum 6 characters')
    .max(16, 'Password maximum 16 characters')
})

const RoleAddEditSchema = Yup.object().shape({
  role_code: Yup.string()
    .min(2, 'Role code minimum 2 characters')
    .max(100, 'Role code maximum 100 characters')
    .required('Role code is required'),
  role_name: Yup.string()
    .min(2, 'Role name minimum 2 characters')
    .max(100, 'Role name maximum 100 characters')
    .required('Role name is required')
})

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Username minimum 3 character')
    .required('Username is required'),
  password: Yup.string().required('Password is required')
})

const expeditionRentAddEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Expedition/rent name minimum 2 characters')
    .max(100, 'Expedition/rent name maximum 100 characters')
    .required('Expedition/rent name is required'),
  phone: Yup.number()
    .typeError('Phone number must be a number')
    .required('Phone number is required'),
  address: Yup.string()
    .min(2, 'Address minimum 2 characters')
    .max(100, 'Address maximum 100 characters')
    .required('Address is required'),
  status: Yup.string().required('Status is required')
})

const BatchAddEditSchema = Yup.object().shape({
  item_id: Yup.string().required('Item id is required'),
  exp_date: Yup.date().required('Expired date is required'),
  manufacturing_date: Yup.date().required('Manufacturing date is required'),
  batch: Yup.string()
    .required('Batch is required')
    .min(2, 'Batch minimum 2 character')
    .max(100, 'Batch maximum 100 character'),
  note: Yup.string().max(200, 'Note maximum 200 character')
})

const VehicleAddEditSchema = Yup.object().shape({
  plate_no: Yup.string()
    .min(2, 'Plate number minimum 2 characters')
    .max(20, 'Plate number maximum 20 characters')
    .required('Plate number is required'),
  vehicle_type: Yup.string()
    .min(2, 'Vehicle type minimum 2 characters')
    .max(100, 'Vehicle type maximum 100 characters')
    .required('Vehicle type is required'),
  volume: Yup.number().required('Volume is required'),
  length: Yup.number().required('Length is required'),
  width: Yup.number().required('Width is required'),
  height: Yup.number().required('Height is required'),
  capacity: Yup.number().required('Capacity is required'),
  status: Yup.string().required('Status is required'),
  typeVehicle: Yup.boolean(),
  stnk_doc: Yup.mixed().when(['typeVehicle'], {
    is: (typeVehicle) => typeVehicle,
    then: (schema) =>
      schema
        .required('File STNK is required')
        .test('formatFile', 'Only files below are allowed', (value) => {
          if (value && value.type) {
            const supportedFormats = [
              'image/jpeg',
              'image/png',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            return supportedFormats.includes(value.type)
          }
          return true
        })
        .test('fileSize', 'File size must not be more than 3MB', (value) => {
          if (value && value.size) {
            return value.size <= 3145728
          }
          return true
        }),
    otherwise: (schema) => schema.notRequired()
  })
})

const AreaAddEditSchema = Yup.object().shape({
  code: Yup.string()
    .min(1, 'Area code minimum 1 character')
    .max(50, 'Area code maximum 50 character')
    .required('Area code name is required'),
  area_name: Yup.string()
    .min(1, 'Area name minimum 1 character')
    .max(100, 'Area name maximum 100 character')
    .required('Area name is required')
})

const BranchUpdateSchema = Yup.object().shape({
  code: Yup.string().required('Code branch is required'),
  name: Yup.string().required('Name branch is required'),
  description: Yup.string()
    .min(3, 'Description branch minimum 3 character')
    .max(200, 'Description branch maximum 200 character')
    .required('Description branch is required')
})

const SectorUpdateSchema = Yup.object().shape({
  warehouse_customer: Yup.object(),
  warehouse_code: Yup.string().required(),
  warehouse_name: Yup.string().required(),
  warehouse_location: Yup.string().required(),
  warehouse_type: Yup.string().required()
})

const StagingAreaAddSchema = Yup.object().shape({
  staging_name: Yup.string()
    .min(2, 'Staging area name minimum 2 characters')
    .max(200, 'Staging area name maximum 200 characters')
    .required('Staging area name is required'),
  block_total: Yup.number().required('Block total is required'),
  mover: Yup.boolean().required('Mover is required')
})

const DriverAddSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name minimum 2 characters')
    .max(200, 'Name maximum 200 characters')
    .required('Name is required'),
  status: Yup.string().required('Status is required'),
  typeDriver: Yup.string().required('Type driver is required'),
  phone_number: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(8, 'Phone number minimum 8 digits')
        .max(15, 'Phone number maximum 15 digits')
        .required('Phone number is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  address: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(2, 'Address minimum 2 characters')
        .max(250, 'Address maximum 200 characters')
        .required('Address is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  username: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(2, 'Username minimum 2 characters')
        .max(100, 'Username maximum 100 characters')
        .required('Username is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  password: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(6, 'Password minimum 6 characters')
        .max(16, 'Password maximum 16 characters')
        .required('Password is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  sim_doc: Yup.mixed().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'external',
    then: (schema) =>
      schema
        .required('File SIM is required')
        .test('formatFile', 'Only files below are allowed', (value) => {
          if (value && value.type) {
            const supportedFormats = [
              'image/jpeg',
              'image/png',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            return supportedFormats.includes(value.type)
          }
          return true
        })
        .test('fileSize', 'File size must not be more than 3MB', (value) => {
          if (value && value.size) {
            return value.size <= 3145728
          }
          return true
        }),
    otherwise: (schema) => schema.notRequired()
  }),
  ktp_doc: Yup.mixed().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'external',
    then: (schema) =>
      schema
        .required('File KTP is required')
        .test('formatFile', 'Only files below are allowed', (value) => {
          if (value && value.type) {
            const supportedFormats = [
              'image/jpeg',
              'image/png',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            return supportedFormats.includes(value.type)
          }
          return true
        })
        .test('fileSize', 'File size must not be more than 3MB', (value) => {
          if (value && value.size) {
            return value.size <= 3145728
          }
          return true
        }),
    otherwise: (schema) => schema.notRequired()
  })
})

const DriverEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name minimum 2 characters')
    .max(200, 'Name maximum 200 characters')
    .required('Name is required'),
  status: Yup.string().required('Status is required'),
  typeDriver: Yup.string().required('Type driver is required'),
  phone_number: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(8, 'Phone number minimum 8 digits')
        .max(15, 'Phone number maximum 15 digits')
        .required('Phone number is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  address: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(2, 'Address minimum 2 characters')
        .max(250, 'Address maximum 200 characters')
        .required('Address is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  username: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(2, 'Username minimum 2 characters')
        .max(100, 'Username maximum 100 characters')
        .required('Username is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  password: Yup.string().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'internal',
    then: (schema) =>
      schema
        .min(6, 'Password minimum 6 characters')
        .max(16, 'Password maximum 16 characters'),

    otherwise: (schema) => schema.notRequired()
  }),
  sim_doc: Yup.mixed().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'external',
    then: (schema) =>
      schema
        .required('File SIM is required')
        .test('formatFile', 'Only files below are allowed', (value) => {
          if (value && value.type) {
            const supportedFormats = [
              'image/jpeg',
              'image/png',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            return supportedFormats.includes(value.type)
          }
          return true
        })
        .test('fileSize', 'File size must not be more than 3MB', (value) => {
          if (value && value.size) {
            return value.size <= 3145728
          }
          return true
        }),
    otherwise: (schema) => schema.notRequired()
  }),
  ktp_doc: Yup.mixed().when(['typeDriver'], {
    is: (typeDriver) => typeDriver === 'external',
    then: (schema) =>
      schema
        .required('File KTP is required')
        .test('formatFile', 'Only files below are allowed', (value) => {
          if (value && value.type) {
            const supportedFormats = [
              'image/jpeg',
              'image/png',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ]
            return supportedFormats.includes(value.type)
          }
          return true
        })
        .test('fileSize', 'File size must not be more than 3MB', (value) => {
          if (value && value.size) {
            return value.size <= 3145728
          }
          return true
        }),
    otherwise: (schema) => schema.notRequired()
  })
})

const RackAddEditSchema = Yup.object().shape({
  rack_name: Yup.string()
    .min(2, 'Rack name minimum 2 characters')
    .max(100, 'Rack name maximum 100 characters')
    .required('Rack name is required')
    .typeError('Rack name must be a string'),
  total_beam: Yup.number().required('Total beam is required'),
  total_level: Yup.number().required('Total level is required')
})

const PurchaseReceiptCreateImportSchema = Yup.object().shape({
  do: Yup.string()
    .min(2, 'DO minimum 2 characters')
    .max(100, 'DO maximum 100 characters')
    .required('DO is required'),
  pib_date: Yup.date().required('PIB date is required'),
  aju: Yup.string().required('AJU is required'),
  expedition: Yup.string()
    .min(2, 'Expedition minimum 2 characters')
    .max(100, 'Expedition maximum 100 characters')
    .required('Expedition is required'),
  pic_name: Yup.string()
    .min(2, 'PIC name minimum 2 characters')
    .max(100, 'PIC name maximum 100 characters')
    .required('PIC name is required'),
  pic_number: Yup.number().required('PIC number is required')
})

const PurchaseReceiptCreateLocalSchema = Yup.object().shape({
  do: Yup.string()
    .min(2, 'DO minimum 2 characters')
    .max(100, 'DO maximum 100 characters')
    .required('DO is required'),
  expedition: Yup.string()
    .min(2, 'Expedition minimum 2 characters')
    .max(100, 'Expedition maximum 100 characters')
    .required('Expedition is required'),
  pic_name: Yup.string()
    .min(2, 'PIC name minimum 2 characters')
    .max(100, 'PIC name maximum 100 characters')
    .required('PIC name is required'),
  pic_number: Yup.number().required('PIC number is required')
})

const ModalUpdateVehiclePR = Yup.object().shape({
  sim_validity: Yup.string().required('Please select an option'),
  stnk_validity: Yup.string().required('Please select an option'),
  sim_doc: Yup.string().required('Please upload sim document'),
  stnk_doc: Yup.string().required('Please upload stnk document')
  // carrier_permit_validity: Yup.string().required('Please select an option')
})

const ChangePasswordSchema = Yup.object().shape({
  CurrentPassword: Yup.string()
    .min(8, 'Current password minimum 8 characters')
    .max(16, 'Current password maximum 16 characters')
    .required('Current password is required'),
  NewPassword: Yup.string()
    .min(8, 'New password minimum 8 characters')
    .max(16, 'New password maximum 16 characters')
    .required('New password is required'),
  ConfirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('NewPassword')],
      'New password dan confirm password harus sama'
    )
    .min(8, 'Confirm password minimum 8 characters')
    .max(16, 'Confirm password maximum 16 characters')
    .required('Confirm password is required')
})

const CreateSoSjSchema = Yup.object().shape({
  item: Yup.string().required('item is required'),
  batch: Yup.string().required(),
  pack: Yup.number()
    .min(1, 'pack minimum 1')
    .required('pack is required, minimum 1')
})

const UpdateSoSjSchema = Yup.object().shape({
  batch: Yup.string().required('batch is required'),
  pack: Yup.number()
    .min(1, 'pack minimum 1')
    .required('pack is required, minimum 1')
})

const CreateMasterPalet = Yup.object().shape({
  palet_id: Yup.string().required('Palet ID is required')
})

const updateCOA = Yup.object().shape({
  coa_pd: Yup.date().required('COA PD is required'),
  coa_ed: Yup.date().required('COA ED is required')
})

const dimensionSchema = Yup.object().shape({
  length: Yup.number().required('Length is required'),
  width: Yup.number().required('Width is required'),
  height: Yup.number().required('Height is required')
})

const deliveryConfirmationUpdateItem = Yup.object().shape({
  qty: Yup.number().required('Pack qty is required')
})
const MapEditorSchema = Yup.object().shape({
  rack: Yup.object().required('Rack is required'),
  beam: Yup.object().required('Beam is required'),
  slot: Yup.object().required('Slot is required'),
  area: Yup.object().required('Area is required'),
  type_id: Yup.array()
    .of(Yup.object().required('Type item code or category is required')) // Adjust the inner type as needed
    .min(1, 'Type item code or category is required')
})
const MapEditorGroundSchema = Yup.object().shape({
  rack: Yup.object().required('Rack is required'),
  beam: Yup.object().required('Beam is required'),
  slot: Yup.object().required('Slot is required'),
  area: Yup.object().required('Area is required')
})
const MapEditorStagingAreaSchema = Yup.object().shape({
  staging_id: Yup.object().required('Staging is required'),
  blok_id: Yup.object().required('Blok is required')
})
const AddItemProblemSjSchema = Yup.object().shape({
  item_code: Yup.string().required('Item code is required'),
  item_batch_id: Yup.string().required('Item batch is required'),
  total_pack: Yup.number()
    .min(1, 'Pack minimum 1')
    .required('Total Pack is required, minimum 1')
})
const AddPrTypeSchemaPr = Yup.object().shape({
  po: Yup.string().required('PO is required'),
  type: Yup.string().required('Type is required')
})
const AddPrTypeSchemaPo = Yup.object().shape({
  // po: Yup.string().required('PO is required'),
  type: Yup.string().required('Type is required')
})
const AddItemMaterialRequest = Yup.object().shape({
  item_id: Yup.string().required('Item is required'),
  batch_id: Yup.string().required('Batch is required'),
  pack: Yup.number().min(1, 'Pack minimum 1').required('Pack is required')
})
export {
  AddItemMaterialRequest,
  AddItemProblemSjSchema,
  AddPrTypeSchemaPo,
  AddPrTypeSchemaPr,
  AreaAddEditSchema,
  BatchAddEditSchema,
  BranchUpdateSchema,
  CategoryAddEditSchema,
  ChangePasswordSchema,
  CreateMasterPalet,
  CreateSoSjSchema,
  DriverAddSchema,
  DriverEditSchema,
  MapEditorGroundSchema,
  MapEditorSchema,
  MapEditorStagingAreaSchema,
  ModalUpdateVehiclePR,
  PurchaseReceiptCreateImportSchema,
  PurchaseReceiptCreateLocalSchema,
  RackAddEditSchema,
  RoleAddEditSchema,
  SectorUpdateSchema,
  StagingAreaAddSchema,
  UpdateSoSjSchema,
  UserAddSchema,
  UserEditSchema,
  VehicleAddEditSchema,
  deliveryConfirmationUpdateItem,
  dimensionSchema,
  expeditionRentAddEditSchema,
  loginSchema,
  updateCOA
}
