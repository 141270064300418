import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomButton from '../../Button/CustomButton'

const labelCheckboxActive = {
  border: '1px solid transparent',
  backgroundColor: '#F2f2f2',
  padding: '1rem',
  marginTop: '.3rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const labelCheckboxUnactive = {
  border: '1px solid transparent',
  backgroundColor: '#C9CACD',
  padding: '1rem',
  marginTop: '.3rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const ModalAddSelectStagingArea = (props) => {
  const { id, data } = props
  const [selectedStaging, setSelectedStaging] = useState({
    stagings: []
  })
  const [staging, setStagings] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  async function fetchStagingArea(id) {
    if (id !== undefined) {
      try {
        const res = await api.get(`master/stagings/show?dest_warehouse=${id}`)
        const results = res.data
        setStagings(results.data || [])
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  useEffect(() => {
    fetchStagingArea(id)
  }, [id])

  const handleSelectStaging = (e) => {
    const checked = e.target.checked
    if (checked) {
      setSelectedStaging({
        ...selectedStaging,
        [e.target.name]: [...selectedStaging.stagings, e.target.value]
      })
    } else {
      setSelectedStaging({
        ...selectedStaging,
        [e.target.name]: selectedStaging.stagings.filter(
          (stagingArea) => stagingArea !== e.target.value
        )
      })
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSubmit(true)
      const res = await api.post(
        `inbound/pr-draft/assign-staging/${data.id}`,
        selectedStaging
      )
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
        fetchStagingArea(id)
        setSelectedStaging({
          stagings: []
        })
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsSubmit(false)
    }
  }
  const propsHandleSubmit = () => {
    if (isSubmit || selectedStaging.stagings.length === 0) {
      return {
        variant: 'secondary',
        disabled: true,
        text: isSubmit ? 'Loading...' : 'Save'
      }
    }
    return {
      variant: 'warning',
      text: 'Save',
      onClick: () => handleSubmit()
    }
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Select Staging Area</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <p style={{ color: '#C12727', paddingTop: '5px' }} className="fw-bold">
          Please select carefully as this action cannot be repeated
        </p>
        {staging.map((data, index) => (
          <div
            key={index}
            className={index > 0 ? 'mt-2 form-check' : 'form-check'}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <input
                className="staging-area form-check-input"
                type="checkbox"
                name="stagings"
                value={data.id}
                id={data.id}
                disabled={data.disabled}
                onChange={(e) => handleSelectStaging(e)}
              />
              <label
                className="ms-3 rounded-2 fw-bold"
                htmlFor={data.id}
                style={
                  data.disabled ? labelCheckboxUnactive : labelCheckboxActive
                }
              >
                {data.name}
              </label>
            </div>
          </div>
        ))}
        <Modal.Footer className="mt-3">
          <CustomButton {...propsHandleSubmit()} />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddSelectStagingArea
