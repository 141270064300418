import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CustomButton from '../../../components/Button/CustomButton'
import BreadCrumb from '../../../components/Label/Breadcrumb/index'
import Label from '../../../components/Label/Input'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import ActionButton from '../Plan/ActionButton'
const DetailTransfer = () => {
  const { id } = useParams()
  const [detail, setDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }

  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }

  useEffect(() => {
    fetchDetailTransfer()
  }, [id])
  const fetchDetailTransfer = async () => {
    try {
      setIsLoading(true)
      const res = await api.get(`/outbound/transfer/detail/${id}`)
      const { data } = res
      if (data.success) {
        setDetail(data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col md={4}>
            <BreadCrumb
              label={detail?.draft_code}
              link="/outbound/transfer"
              back="TR"
              type="BadgeStatusPrListInbounds"
              status={detail?.status}
            />
          </Col>
          {detail?.status === 'submit' && (
            <ActionButton>
              <div
                style={{ marginTop: '2.3rem' }}
                className="d-flex flex-row gap-2"
              >
                <>
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#F89D1D' }}
                    text={'Download TR'}
                    // onClick={() =>
                    //   handleDownloadPrint('sj', 'download', detail?.id)
                    // }
                  />
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#203568' }}
                    text={'Print TR'}
                    // onClick={() =>
                    //   handleDownloadPrint('sj', 'print', detail?.id)
                    // }
                  />
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#F89D1D' }}
                    text={'Download C.O.A'}
                    // onClick={() =>
                    //   handleDownloadPrint('coa', 'download', detail?.id)
                    // }
                  />
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#203568' }}
                    text={'Print C.O.A'}
                    // onClick={() =>
                    //   handleDownloadPrint('coa', 'print', detail?.id)
                    // }
                  />
                </>
              </div>
            </ActionButton>
          )}
        </Row>
        <Row className="mb-4">
          <Col md={4}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.src_warehouse} />
          </Col>
          <Col md={4}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.target_warehouse} />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>delivery date</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={moment(detail?.delivery_date).format('DD-MM-YYYY')}
            />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>#MR</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.mr} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">REQUEST</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle">Qty</th>
                </tr>
              </thead>
              <tbody>
                {!detail?.requests.length && (
                  <tr>
                    <td className="p-3" colSpan={4}>
                      No Data
                    </td>
                  </tr>
                )}
                {detail?.requests.length &&
                  detail?.requests.map((request, index) => (
                    <tr key={request.item_code}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{request.item_name}</td>
                      <td className="p-3">{request.item_code}</td>
                      <td className="p-3">{request.qty}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">PICK UP COMMAND</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle text-end">Batch</th>
                  <th className="p-3 align-middle text-end">Expired</th>
                  <th className="p-3 align-middle text-end">Total Qty</th>
                  <th className="p-3 align-middle text-end">Total Pack</th>
                  <th className="p-3 align-middle text-center">Location</th>
                  {detail?.status !== 'submit' && (
                    <th className="p-3 align-middle">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!detail?.pickup_command.length && (
                  <tr>
                    <td className="p-3" colSpan={9}>
                      No Data
                    </td>
                  </tr>
                )}
                {detail?.pickup_command.length &&
                  detail?.pickup_command.map((pickup_command, index) => (
                    <tr key={pickup_command.item_code}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{pickup_command.item_name}</td>
                      <td className="p-3">{pickup_command.item_code}</td>
                      <td className="p-3 text-end">{pickup_command.batch}</td>
                      <td className="p-3 text-end">
                        {moment(pickup_command.exp_date).format('DD-MM-YYYY')}
                      </td>
                      <td className="p-3 text-end">
                        {pickup_command.qty} {pickup_command.uom}
                      </td>
                      <td className="p-3 text-end">
                        {pickup_command.total_pack}
                      </td>
                      <td className="p-3">
                        <div
                          style={{ maxWidth: '80%' }}
                          className="text-truncate text-primary text-center"
                          title={pickup_command.location}
                        >
                          {pickup_command.location}
                        </div>
                      </td>
                      {detail?.status !== 'submit' && (
                        <td className="p-3">
                          <div className="d-flex flex-row gap-2 justify-content-end">
                            <button className="btn btn-secondary" disabled>
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button className="btn btn-secondary" disabled>
                              <FontAwesomeIcon icon={faPencil} />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {detail?.status !== 'unassigned' && (
          <>
            <Row className="bg-white rounded">
              <Col md={12} className="mb-3">
                <h5 className="fw-bold text-uppercase">Progress Detail</h5>
              </Col>
              <ul className="list-unstyled">
                {detail?.progress_detail?.length > 0 &&
                  detail?.progress_detail?.map((progress) => (
                    <li>
                      <div className="d-flex flex-row gap-3 progress-detail">
                        <div className="circle-progress-detail"></div>
                        <p>
                          {moment(progress?.datetime).format(
                            'DD MMMM YYYY HH:mm'
                          )}
                        </p>
                        <div
                          className="d-flex flex-column justify-content-center"
                          style={{ height: '13px' }}
                        >
                          <div className="d-flex flex-row gap-3">
                            <div className="circle-small-progress-detail mt-2 ms-1"></div>
                            <span style={{ fontSize: '13px' }}>
                              {progress?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ul
                        className="list-unstyled"
                        style={{ marginLeft: '2rem' }}
                      >
                        {progress?.detail?.length > 0 &&
                          progress?.detail?.map((detail) => (
                            <li
                              style={{
                                color: `${
                                  detail.color ? detail.color : 'black'
                                } `
                              }}
                              className="mb-3"
                            >
                              {detail?.description}
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </Row>
            <Row>
              <Label
                label="PNP Checker"
                value={detail?.checker?.pnp ?? '-'}
                col={4}
              />
              <Label
                label="RNR Checker"
                value={detail?.checker?.rnr ?? '-'}
                col={4}
              />
            </Row>
          </>
        )}
        <Row>
          <Card className="border-0 shadow-sm">
            <Card.Body className="d-flex justify-content-center gap-3 p-4">
              <Col md={3}>
                <Form.Label
                  style={titleStyle}
                  className="fw-bold text-secondary text-uppercase"
                >
                  <small>created by</small>
                </Form.Label>
                <Form.Control
                  disabled
                  placeholder={detail?.created_by ?? '-'}
                />
              </Col>
              <Col md={3}>
                <Form.Label
                  style={titleStyle}
                  className="fw-bold text-secondary text-uppercase"
                >
                  <small>created at</small>
                </Form.Label>
                <Form.Control
                  disabled
                  placeholder={moment(detail?.createdAt).format(
                    'DD-MM-YYYY, HH:mm'
                  )}
                />
              </Col>
              <Col md={3}>
                <Form.Label
                  style={titleStyle}
                  className="fw-bold text-secondary text-uppercase"
                >
                  <small>last update by</small>
                </Form.Label>
                <Form.Control
                  disabled
                  placeholder={detail?.updated_by ?? '-'}
                />
              </Col>
              <Col md={3}>
                <Form.Label
                  style={titleStyle}
                  className="fw-bold text-secondary text-uppercase"
                >
                  <small>last update at</small>
                </Form.Label>
                <Form.Control
                  disabled
                  placeholder={moment(detail?.updatedAt).format(
                    'DD-MM-YYYY, HH:mm'
                  )}
                />
              </Col>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  )
}

export default DetailTransfer
