import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import api from '../../../config/AxiosInstance'
import { OUTBOUND_TYPES } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import Pagination from '../../atoms/Pagination/Index'
import RowEmpty from '../../atoms/RowEmpty'
import { OutboundHistory } from '../../atoms/StatusLabel'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const History = () => {
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const [outboundHistories, setOutboundHistories] = useState([])
  const [filters, setFilters] = useState({
    outboundType: ''
  })
  const { per_page, current_page } = meta
  const { outboundType } = filters

  const navigate = useNavigate()

  useEffect(() => {
    fetchOutboundHistories()
  }, [per_page, current_page])

  const fetchOutboundHistories = async () => {
    setLoading(true)
    try {
      const res = await api.get('outbound/history/list', {
        params: {
          per_page: per_page,
          page: current_page,
          outbound_type: outboundType === 'pick_up' ? 'pick up' : outboundType
        }
      })
      const { metadata, data } = res.data
      setOutboundHistories(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const handleMetaChange = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const navigateToDetail = (data) => {
    const encodedName = encodeURIComponent(data.source_doc_no)
    navigate(`/outbound/history/detail/${encodedName}`)
  }

  const handleApplyFilter = async () => {
    setLoading(true)
    try {
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: 1
      }))
      await fetchOutboundHistories()
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const OutboundTypeOption = useMemo(
    () =>
      OUTBOUND_TYPES.map((outboundType) => (
        <option value={outboundType.value} key={outboundType.id}>
          {outboundType.label}
        </option>
      )),
    []
  )

  return (
    <Container>
      <Row>
        <Col>
          <h4 className="fw-bold" style={headerTitleStyle}>
            Outbound History
          </h4>
        </Col>
      </Row>
      {/* FILTER */}
      <Row className="mb-5">
        <Col md={3} className="mt-3">
          <Form.Group className="fw-normal text-muted" style={titleStyle}>
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>Type</small>
            </Form.Label>
            <Form.Select
              aria-label="Select Delivery Type"
              name="outboundType"
              id="outboundType"
              onChange={handleFilterChange}
              value={outboundType}
            >
              <option value="">All</option>
              {OutboundTypeOption}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3} style={{ marginTop: '35px' }}>
          <Button style={btnOrange} onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            hover
            size="sm"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle ">Source Doc No.</th>
                <th className="p-2 align-middle">ERP Doc No.</th>
                <th className="p-2 align-middle">Type</th>
                <th className="p-2 align-middle text-end">Submit</th>
                <th className="p-2 align-middle text-end">Status</th>
                <th className="p-2 align-middle text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : outboundHistories.length > 0 ? (
                outboundHistories.map((data, i) => (
                  <tr key={i}>
                    <td className="p-2 align-middle">{data.source_doc_no}</td>
                    <td className="p-2 align-middle">{data.erp_doc_no}</td>
                    <td className="p-2 align-middle text-uppercase">
                      {data.outbound_type}
                    </td>
                    <td className="p-2 align-middle text-end">
                      {!data.submit
                        ? '-'
                        : moment(data.submit).format('DD-MM-YYYY, HH:mm')}
                    </td>
                    <td className="p-2 align-middle text-end">
                      <OutboundHistory text={data.status} />
                    </td>
                    <td className="p-2 align-middle text-end">
                      <button
                        className="btn btn-secondary ml-2"
                        onClick={() => navigateToDetail(data)}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <RowEmpty colSpan={6} />
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="p-3">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Show</span>
                    <select
                      name="per_page"
                      className="form-select"
                      aria-label="show-page"
                      style={{ width: '60px' }}
                      value={meta.per_page}
                      onChange={handleMetaChange}
                    >
                      {[10, 25, 50, 100].map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <span className="ml-2">
                      Entries | {meta.current_page} to {meta.per_page} of{' '}
                      {meta.total_row} entries
                    </span>
                  </div>
                </td>
                <td colSpan={2} className="p-3">
                  <div className="d-flex justify-content-end">
                    <Pagination
                      pageCount={meta.total_page}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default History
