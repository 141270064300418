import React from 'react'
import { Form } from 'react-bootstrap'

const smLabel = {
  fontSize: '9px',
  lineHeight: '12px',
  color: '#828282'
}


const index = ({
  label,
  type = 'text',
  placeholder = '',
  name,
  value,
  handleChange,
  handleBlur,
  touched,
  errors,
  required = false,
  step = 'any',
  disabled = false,
  col,
  bold = 'bold',
  labelsm,
  as = 'input',
}) => {
  return (
    <Form.Group className={`mb-3 col-${col}`} controlId={name}>
      <Form.Label
        className={`text-muted text-uppercase fw-${bold ? 'bold' : 'normal'}`}
        style={{ fontSize: '13px', letterSpacing: '0.5px' }}
      >
        {label}
        {labelsm && <small style={smLabel}>{labelsm}</small>}
        {required === true && <sup style={{ color: 'red' }}>*</sup>}
      </Form.Label>
      <Form.Control
        type={type}
        as={as}
        placeholder={placeholder}
        name={name}
        required={required}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        step={step}
        isInvalid={touched[name] && !!errors[name]}
        disabled={disabled}
      />
      {touched[name] && !!errors[name] && (
        <Form.Control.Feedback type="invalid">
          {errors[name]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default index
