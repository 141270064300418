import { faPen, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import ModalAddRack from '../../../components/modal/racks/ModalAddRack'
import ModalUpdateRack from '../../../components/modal/racks/ModalUpdateRack'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px'
}

const MasterRack = () => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [racks, setRacks] = useState([])
  const [warehouseId, setWarehouseId] = useState(0)
  const [editId, setEditId] = useState(0)
  const [isSelected, setSelected] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const { per_page, current_page } = meta
  const navigate = useNavigate()
  useEffect(() => {
    fetchWarehouses()
    fetchRacks()
  }, [])

  useEffect(() => {
    fetchRacks()
  }, [per_page, current_page, isSelected])

  const fetchWarehouses = useCallback(async () => {
    try {
      const res = await api.get('master/warehouses/all?is_parent=true')
      setWarehouses(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }, [])

  const fetchRacks = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        warehouse_id: warehouseId || '',
        per_page,
        page: current_page
      }
      const res = await api.get('master/racks', { params })
      const { metadata, data } = res.data
      setRacks(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }, [per_page, current_page, warehouseId])

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  const handleSelected = (id) => {
    setSelected((selected) => !selected)
    setDisabledAdd(id && id !== 0 ? false : true)
  }

  const handleEdit = (id) => {
    setShowModalUpdate(true)
    setEditId(id)
  }

  const handleToggle = async (id, status, rack) => {
    try {
      await api.patch(`master/racks/${id}`, {
        ...rack,
        is_active: !status
      })
      fetchRacks()
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Rack</h3>
      <div className="row">
        <div className="col-11">
          <Form.Label className="fw-normal text-muted" style={titleStyle}>
            <small>WAREHOUSE</small>
          </Form.Label>
          <Form.Select
            aria-label="Select Warehouse"
            name="warehouse"
            id="warehouse"
            onChange={(e) => setWarehouseId(e.target.value)}
          >
            <option value={0}>All</option>
            {warehouses.map((warehouse) => (
              <option value={warehouse.id} key={warehouse.id}>
                {warehouse.warehouse_name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="col-1">
          <div
            className="d-flex h-100 align-items-center justify-content-end"
            style={{ marginTop: '.9rem' }}
          >
            <button
              className="btn btn-primary-orange"
              onClick={() => handleSelected(warehouseId)}
            >
              Select
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: '1px solid #F1F2F7',
          marginTop: '2.5rem'
        }}
      ></div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <h4 className="text-muted" style={{ fontSize: '14px' }}>
          RACK LIST
        </h4>
        <button
          className="btn btn-primary-orange"
          disabled={disabledAdd}
          onClick={() => setShowModalAdd(true)}
        >
          Add
        </button>
      </div>
      <div className="row">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle">Name</th>
              <th className="p-2 align-middle">Rack Type</th>
              <th className="p-2 align-middle">Area</th>
              <th className="p-2 align-middle">Total Beam</th>
              <th className="p-2 align-middle">Total Level</th>
              <th className="p-2 align-middle">Status</th>
              <th className="p-2 align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={7}>
                  <Loader />
                </td>
              </tr>
            )}
            {!isLoading && racks.length === 0 && <RowEmpty colSpan={7} />}
            {!isLoading &&
              racks?.map((rack) => (
                <tr key={rack.id}>
                  <td className="p-2 align-middle">{rack.name}</td>
                  <td className="p-2 align-middle">
                    {firstLetterUpperCase(rack.type)}
                  </td>
                  <td className="p-2 align-middle">{rack?.area?.name}</td>
                  <td className="p-2 align-middle">{rack.total_beam}</td>
                  <td className="p-2 align-middle">{rack.total_level}</td>
                  <td className="p-2 align-middle">
                    <Toggle
                      className="actived"
                      defaultChecked={rack.is_active}
                      onClick={() =>
                        handleToggle(rack.id, rack.is_active, rack)
                      }
                      icons={false}
                    />
                  </td>
                  <td className="p-2 align-middle">
                    <div className="d-flex flex-row gap-2">
                      <button
                        className="btn btn-secondary ml-2"
                        onClick={() =>
                          navigate(`/setting/racks/detail/${rack.id}`)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ fontSize: '15px', rotate: '270deg' }}
                          className="fa-flip-horizontal"
                        />
                      </button>
                      <button
                        className="btn btn-secondary ml-2"
                        onClick={() => handleEdit(rack.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ fontSize: '15px', rotate: '90deg' }}
                          className="fa-flip-horizontal"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4} className="p-3">
                <div className="d-flex flex-row gap-3 align-items-center">
                  <span className="mr-2">Show</span>
                  <select
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '60px' }}
                    value={meta.per_page}
                    onChange={handleOnChangeMeta}
                  >
                    {[10, 25, 50, 100].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={3} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={meta.total_page}
                    onPageChange={handlePageChange}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddRack
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        warehouseId={warehouseId}
        refreshData={fetchRacks}
      />
      <ModalUpdateRack
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        id={editId}
        refreshData={fetchRacks}
      />
    </div>
  )
}

export default MasterRack
