import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Label from '../../../components/Label/Input'
import { convertDateFormat } from '../../../utils/FormatDate'

function Createdby({ detail }) {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Row>
              <Label
                label="created by"
                value={
                  !detail?.po_info?.createdBy ? '-' : detail?.po_info?.createdBy
                }
                col={3}
              />
              <Label
                label="created at"
                value={
                  !detail?.po_info?.createdAt
                    ? '-'
                    : convertDateFormat(detail?.po_info?.createdAt)
                }
                col={3}
              />
              <Label
                label="last updated by"
                value={
                  !detail?.po_info?.updatedBy ? '-' : detail?.po_info?.updatedBy
                }
                col={3}
              />
              <Label
                label="last updated at"
                value={
                  !detail?.po_info?.updatedAt
                    ? '-'
                    : convertDateFormat(detail?.po_info?.updatedAt)
                }
                col={3}
              />
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Createdby
