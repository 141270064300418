import {
  faCaretDown,
  faCheck,
  faClose,
  faPlus,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Col, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Pagination from '../../../components/atoms/Pagination/Index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { BadgeStatusOutboundPlan } from '../../../components/atoms/StatusLabel'
import ModalAddSelectStagingArea from '../../../components/modal/Outbound/ModalAddSelectStagingArea'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import styles from './outbound_plan.module.css'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const btnCreateSjStyle = {
  color: 'white',
  backgroundColor: '#203568'
}

const tableSecondarySj = {
  borderColor: 'transparent'
}

const OutboundPlan = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [outboundPlans, setOutboundPlans] = useState([])
  const [selectedWarehouseId, setSelectedWarehouseId] = useState({
    id: 0,
    warehouse_id: 0
  })
  const [loadingData, setLoadingData] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 100,
    total_page: 10
  })
  const { per_page, current_page } = meta
  const [modalShowSelectStagingArea, setModalShowSelectStagingArea] =
    useState(false)

  const handleOnChangeMeta = (e) => {
    setLoadingData(true)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
    setLoadingData(false)
  }

  const handlePageChange = (data) => {
    setLoadingData(true)
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
    setLoadingData(false)
  }

  const handleShowModalStagingArea = (id, warehouse_id) => {
    setModalShowSelectStagingArea(true)
    setSelectedWarehouseId({ id, warehouse_id })
  }

  useEffect(() => {
    getOutboundPlans()
    const timeoutScroll = setTimeout(() => window.scrollTo(0, 0), 0)
    const timeoutCloseNotification = setTimeout(() => {
      navigate('', { state: null })
    }, 10000)
    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => {
      clearTimeout(timeoutCloseNotification)
      clearTimeout(timeoutScroll)
    }
  }, [per_page, current_page])

  // get lists outbound plan
  const getOutboundPlans = async () => {
    try {
      setLoadingData(true)
      const res = await api.get('/outbound/outbound_plan/list/', {
        params: { per_page, page: current_page }
      })
      if (res.status === 200) {
        const { data, metadata } = res.data
        setOutboundPlans(data)
        setMeta(metadata)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingData(false)
    }
  }

  const handleCloseNotification = (e) => {
    e.stopPropagation()
    navigate('', { state: null })
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8">
            <h4 style={headerTitleStyle}>Outbound Plan</h4>
          </div>
          <div className="col-4">
            {state && (
              <div>
                <Card
                  className={`${styles.successNotification} me-4`}
                  style={{ cursor: 'pointer', maxWidth: '25.8vw' }}
                >
                  <Card.Body>
                    <Row>
                      <Col md={1}>
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="mt-1"
                            style={{
                              fontSize: '15px',
                              color: 'white'
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={11}>
                        <Row className="d-flex align-items-center">
                          <Col sm={10}>
                            <p className="text-light ms-2">Outbound created!</p>
                          </Col>
                          <Col sm={2}>
                            <div
                              className="d-flex justify-content-end"
                              onClick={(e) => {
                                handleCloseNotification(e)
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faClose}
                                style={{
                                  fontSize: '15px',
                                  color: 'white',
                                  marginTop: '-.8rem',
                                  cursor: 'pointer'
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            )}
            {!state && (
              <div className="d-flex justify-content-end">
                <Link
                  to="/outbound/plan/create"
                  className="text-decoration-none"
                >
                  <button
                    className="btn d-flex flex-row gap-2"
                    style={btnCreateSjStyle}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="fa-flip-horizontal d-flex flex-column justify-content-center items-content-center mt-1"
                      style={{ fontSize: '14px' }}
                    />
                    <span>
                      <small>Create Outbound</small>
                    </span>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-4 pe-2">
          <div className="col-12">
            <Table
              hover
              responsive="sm"
              size="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary" style={tableSecondarySj}>
                <tr>
                  <th className="p-2 align-middle">#Outbound</th>
                  <th className="p-2 align-middle">Source Warehouse</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Car</th>
                  <th className="p-2 align-middle">Delivery Date</th>
                  <th className="p-2 align-middle">Staging Area</th>
                  <th className="p-2 align-middle text-end">Status</th>
                  <th className="p-2 text-end" width="8%">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loadingData && (
                  <td colSpan={9} className="text-center">
                    {' '}
                    <Loader />
                  </td>
                )}
                {!loadingData && outboundPlans.length === 0 && (
                  <RowEmpty colSpan={9} />
                )}
                {!loadingData &&
                  outboundPlans.length > 0 &&
                  outboundPlans.map((value, index) => (
                    <RowData
                      key={index}
                      data={value}
                      showModalStagingArea={handleShowModalStagingArea}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="show-page"
                        style={{ width: '60px' }}
                        value={meta.per_page}
                        onChange={handleOnChangeMeta}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="ml-2">
                        Entries | {meta.current_page} to {meta.per_page} of{' '}
                        {meta.total_row} entries
                      </span>
                    </div>
                  </td>
                  <td colSpan={6}>
                    <div className="d-flex justify-content-end">
                      <Pagination
                        pageCount={meta.total_page}
                        onPageChange={handlePageChange}
                        page={current_page}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </div>
      <ModalAddSelectStagingArea
        show={modalShowSelectStagingArea}
        onHide={() => {
          setModalShowSelectStagingArea(false)
        }}
        warehouseId={selectedWarehouseId}
        refreshData={() => getOutboundPlans()}
      />
    </>
  )
}

function RowData({ data, showModalStagingArea }) {
  let urlDetail = `/outbound/plan/detail/${data.id}`
  const propsStaging = (value, showModalStagingArea) => {
    if (value.delivery_type === 'pick_up' && value.status === 'unassigned') {
      return {
        onClick: () => showModalStagingArea(data.id, data.warehouse_id),
        className: 'd-flex pe-auto',
        role: 'button'
      }
    }
    if (
      value.status === 'unassigned' &&
      value.car !== '-' &&
      value.driver !== '-'
    ) {
      return {
        onClick: () => showModalStagingArea(data.id, data.warehouse_id),
        className: 'd-flex pe-auto',
        role: 'button'
      }
    }
    return { className: 'd-flex' }
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{data.outbound}</td>
      <td className="p-2 align-middle">{data.source}</td>
      <td className="p-2 align-middle">
        {/* {firstLetterUpperCase(data?.delivery_type)} */}
        {!data?.delivery_type
          ? '-'
          : firstLetterUpperCase(
              data?.delivery_type === 'pick_up'
                ? 'Pick Up'
                : data?.delivery_type
            )}
      </td>
      <td className="p-2 align-middle">{!data.driver ? '-' : data.driver}</td>
      <td className="p-2 align-middle">{!data.car ? '-' : data.car}</td>
      <td className="p-2 align-middle">
        {moment(data.delivery_date).format('DD-MM-YYYY')}
      </td>
      <td className="p-2 align-middle">
        <div {...propsStaging(data, showModalStagingArea)}>
          {!data.staging_name ? (
            'Select Staging Area'
          ) : (
            <>
              <OverlayTrigger
                trigger={['hover', 'click']}
                key={'bottom'}
                placement={'bottom'}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      <p>{data.staging_name}</p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <p className="text-truncate mt-3" style={{ maxWidth: '150px' }}>
                  {data.staging_name}
                </p>
              </OverlayTrigger>
            </>
          )}
          {!data.staging_name &&
            data.driver !== '-' &&
            data.car !== '-' &&
            data.delivery_tye !== 'pick_up' && (
              <FontAwesomeIcon
                icon={faCaretDown}
                className="fa-flip-horizontal ml-2 mt-1"
              />
            )}
          {!data.staging_name && data.delivery_type === 'pick_up' && (
            <FontAwesomeIcon
              icon={faCaretDown}
              className="fa-flip-horizontal ml-2 mt-1"
            />
          )}
        </div>
      </td>
      <td className="text-end p-2 align-middle">
        {<BadgeStatusOutboundPlan text={data.status} />}
      </td>
      <td className="p-2 align-middle text-end">
        <Link to={urlDetail}>
          <button className="btn btn-secondary btn-sm">
            <FontAwesomeIcon icon={faSearch} className="fa-flip-horizontal" />
          </button>
        </Link>
      </td>
    </tr>
  )
}

export default OutboundPlan
