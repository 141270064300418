import { Formik } from 'formik'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import SelectInput from '../../../components/Input/SelectInput'
import TextField from '../../../components/Input/TextInput'
import Label from '../../../components/Label/Input'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { AddItemProblemSjSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
function ModalAddUpdateItem(props) {
  const {
    data,
    options,
    reportPickupCommand,
    pickupCommand,
    sjDraftId,
    command
  } = props
  const [batches, setBatches] = useState([])
  const [optionBatches, setOptionBatches] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  useEffect(() => {
    if (data.type === 'update') {
      handleChangeItem(data.item_code)
    }
  }, [data.type, data.command_id])
  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true)
      const totalQtyRequest = command.find(
        (item) => item.item_code === values.item_code
      )?.qty
      const totalQtyPickupCommand =
        pickupCommand
          .filter((item) => item.item_code === values.item_code)
          ?.reduce((acc, cur) => (acc += cur.qty), 0) +
        parseInt(values.qty) -
        reportPickupCommand
          .filter((item) => item.item_code === values.item_code)
          ?.reduce(
            (acc, cur) => (acc += cur.report_pack * cur.convertion_factor_pack),
            0
          )
      const totalPackBatch = batches.find(
        (item) => item.batch === values.item_batch_id
      )?.total_pack
      if (totalQtyRequest !== totalQtyPickupCommand) {
        Swal.fire({
          title: 'Action Denied!',
          text: 'Total qty exceeds request!',
          icon: 'warning',
          iconColor: '#828282',
          confirmButtonColor: '#F89D1D',
          customClass: {
            actions:
              'd-flex w-100 justify-content-end margin-left-action-sweetalert'
          }
        })
        setSubmitLoading(false)
        props.onHide()
        return
      }
      if (values.total_pack > totalPackBatch) {
        Swal.fire({
          title: 'Action Denied!',
          text: 'Total pack batch less than request',
          icon: 'warning',
          iconColor: '#828282',
          confirmButtonColor: '#F89D1D',
          customClass: {
            actions:
              'd-flex w-100 justify-content-end margin-left-action-sweetalert'
          }
        })
        setSubmitLoading(false)
        props.onHide()
        return
      }
      const urlValue =
        data?.type === 'create'
          ? `outbound/sjdraft/${sjDraftId}/add-item`
          : `outbound/sjdraft/${sjDraftId}/update/${data?.command_id}`
      const res = await api.post(urlValue, {
        item_code: values.item_code,
        item_batch_id: values.item_batch_id,
        total_pack: values.total_pack,
        report_id: pickupCommand.find(
          (item) => item.item_code === values.item_code
        )?.report_id,
        slot_id: batches.find(
          (item) => item.item_batch_id === values.item_batch_id
        )?.slot_id
      })
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setSubmitLoading(false)
    }
  }
  const handleChangeItem = async (value) => {
    try {
      if (value === '') {
        return
      }
      const res = await api.post(`master/batch/search`, {
        item_code: value,
        report_id: reportPickupCommand.find((item) => item.item_code === value)
          ?.report_id,
        exclude: reportPickupCommand
          .filter((item) => item.item_code === value)
          .map((item) => item.batch)
      })
      if (res.data.success) {
        setBatches(res.data.data)
        const data = res.data.data.reduce((acc, data) => {
          acc.push({
            id: data.item_batch_id,
            label: data.batch
          })
          return acc
        }, [])
        setOptionBatches(data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  return (
    <div>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <div className="d-flex flex-column justify-content-center mt-2">
            <p className="fw-bold" style={{ fontSize: '14px' }}>
              {data?.type === 'update' ? 'Update' : 'Add'} Item
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              item_code: data?.item_code,
              item_alias: data?.item_alias,
              item_batch_id: data?.item_batch_id,
              total_pack: data?.total_pack,
              exp_date: data?.exp_date,
              qty: data?.qty
            }}
            validationSchema={AddItemProblemSjSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              dirty,
              setFieldValue
            }) => {
              return (
                <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <SelectInput
                        label="item"
                        name="item_code"
                        id="item_code"
                        value={values.item_code}
                        handleChange={(e) => {
                          const { value } = e.target
                          setFieldValue('item_code', value)
                          setFieldValue(
                            'item_alias',
                            reportPickupCommand.find(
                              (item) => item.item_code === value
                            )?.item_alias ?? '-'
                          )
                          handleChangeItem(value)
                        }}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={options}
                        required={true}
                      />
                    </Col>
                    <Label
                      label="ITEM ALIAS"
                      value={values.item_alias}
                      col={12}
                      classNameValue="fw-bold text-muted"
                    />
                    <Col md={6}>
                      <SelectInput
                        label="Batch"
                        name="item_batch_id"
                        id="item_batch_id"
                        value={values.item_batch_id}
                        handleChange={(e) => {
                          const { value } = e.target
                          const expDate = batches.find(
                            (batch) => batch.item_batch_id === value
                          )?.exp_date
                          setFieldValue('item_batch_id', value)
                          setFieldValue(
                            'exp_date',
                            expDate ? moment(expDate).format('DD-MM-YYYY') : '-'
                          )
                        }}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={optionBatches}
                        required={true}
                        disabled={!values.item_code ? true : false}
                      />
                    </Col>
                    <Label
                      label="EXP DATE"
                      value={values.exp_date}
                      col={6}
                      classNameValue="fw-bold text-muted"
                    />
                    <Col md={6}>
                      <TextField
                        label="Pack"
                        type="number"
                        name="total_pack"
                        value={values.total_pack}
                        handleChange={(e) => {
                          setFieldValue(
                            'total_pack',
                            e.target.value > 0 ? parseInt(e.target.value) : 0
                          )
                          setFieldValue(
                            'qty',
                            e.target.value < 0 || values.total_pack === ''
                              ? 0
                              : parseInt(
                                  e.target.value *
                                    reportPickupCommand.find(
                                      (item) =>
                                        item.item_code === values.item_code
                                    )?.convertion_factor_pack
                                )
                          )
                        }}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        placeholder="Enter Pack..."
                        required={true}
                        disabled={!values.item_code ? true : false}
                      />
                    </Col>
                    <Label
                      label="QTY"
                      value={values.qty}
                      col={6}
                      classNameValue="fw-bold text-muted"
                    />
                  </Row>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton text={'Cancel'} onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={submitLoading ? 'secondary' : 'warning'}
                      disabled={
                        data?.type === 'create'
                          ? !isValid || !dirty || submitLoading
                          : !isValid || submitLoading
                      }
                      text={data?.type === 'update' ? 'Update' : 'Add'}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalAddUpdateItem
