import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { RoleAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalUpdateRole(props) {
  const [data, setData] = useState({
    role_code: '',
    role_name: ''
  })

  const loadData = async (id) => {
    try {
      const res = await api.get(`/cms/role/${id}`)

      setData({
        role_code: res.data.data.role_code,
        role_name: res.data.data.role_name
      })
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) {
      loadData(props.id)
    }
  }, [props.id])

  const handleSubmit = async (values) => {
    try {
      const res = await api.post(`/cms/role/${props.id}`, values)
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            role_name: data.role_name,
            role_code: data.role_code
          }}
          enableReinitialize={true}
          validationSchema={RoleAddEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={4}>
                    <TextField
                      label="role code"
                      name="role_code"
                      value={values.role_code}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input code"
                      disabled={true}
                    />
                  </Col>
                  <Col md={8}>
                    <TextField
                      label="role name"
                      name="role_name"
                      value={values.role_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input role"
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateRole
