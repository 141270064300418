import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Badge, Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import ModalAddCategoryItem from '../../../components/modal/items/ModalAddCategoryItem'
import ModalAddEditDimension from '../../../components/modal/items/ModalAddEditDimension'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import Breadcrumb from '../../../components/Label/Breadcrumb'

const MasterItemDetail = () => {
  const [detail, setDetail] = useState({})
  const [category, setCategory] = useState([])
  const [modalAddShow, setModalAddShow] = useState(false)
  const [modalDimension, setModalDimension] = useState(false)
  const [newCode, setNewCode] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { code } = useParams()

  useEffect(() => {
    loadData(code)
  }, [code])

  const loadData = async (code) => {
    setIsLoading(true)
    try {
      const res = await api.get(`master/items/${code}`)
      setDetail(res.data.data)
      setCategory(res.data.data.categories)
      setNewCode(res.data.data.id)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Container className="mb-5">
        {isLoading && <Loader />}
        <Row>
          <Col md={12} className="mt-3">
            <Breadcrumb
              label={detail.item_name}
              link="/master/items"
              back="Master Item"
            />
          </Col>
        </Row>
        <Row className="mt-4 pe-2">
          <Col md={6}>
            <div>
              <small style={{ fontSize: '11px' }}>ITEM NAME</small>
              <h6 className="mt-2">{detail.item_name}</h6>
            </div>
            <div className="mt-3">
              <small style={{ fontSize: '11px' }}>ITEM GROUP</small>
              <h6 className="mt-2">{detail.item_group}</h6>
            </div>
            <Row>
              <Col md={6}>
                <div className="mt-3">
                  <small style={{ fontSize: '11px' }}>QTY/UNIT</small>
                  <h6 className="mt-2">{detail.qty_unit}</h6>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <small style={{ fontSize: '11px' }}>UOM</small>
                  <h6 className="mt-2">{detail.uom}</h6>
                </div>
              </Col>
              <Col md={12} className="mt-4">
                <h6 className="text-secondary">
                  DIMENSIONS
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{
                      fontSize: '20px',
                      marginLeft: '15px',
                      cursor: 'pointer'
                    }}
                    onClick={() => setModalDimension(true)}
                    disabled
                  />
                </h6>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <small style={{ fontSize: '11px' }}>P x L x T /cm</small>
                  <h6 className="mt-2">{detail.p_l_t}</h6>
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-3">
                  <small style={{ fontSize: '11px' }}>VOLUME</small>
                  <h6 className="mt-2">{detail.volume_item}</h6>
                </div>
              </Col>
              <Col md={12} className="mt-4">
                <h6 className="text-secondary">
                  CATEGORY
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{
                      fontSize: '20px',
                      marginLeft: '15px',
                      cursor: 'pointer'
                    }}
                    onClick={() => setModalAddShow(true)}
                    disabled
                  />
                </h6>
              </Col>
              <Col md={12}>
                <Row>
                  {category.map((itemCategory) => {
                    return (
                      <Col sm={4} key={itemCategory.id}>
                        <Badge
                          pill
                          bg="secondary"
                          key={itemCategory.id}
                          style={{ width: '100%' }}
                        >
                          {itemCategory.name}
                        </Badge>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <div>
              <small style={{ fontSize: '11px' }}>ITEM CODE</small>
              <h6 className="mt-2">{detail.item_code}</h6>
            </div>
            <div className="mt-3">
              <small style={{ fontSize: '11px' }}>DESCRIPTION</small>
              <h6 className="mt-2">{detail.description}</h6>
            </div>
            <div className="mt-3">
              <small style={{ fontSize: '11px' }}>PRIMARY ITEM GROUP</small>
              <h6 className="mt-2">{detail.primary_item_group || '-'}</h6>
            </div>
          </Col>
        </Row>
      </Container>
      <ModalAddCategoryItem
        show={modalAddShow}
        onHide={() => setModalAddShow(false)}
        code={newCode}
        refreshData={() => loadData(code)}
        lastcategory={category}
      />
      <ModalAddEditDimension
        show={modalDimension}
        onHide={() => setModalDimension(false)}
        refreshData={() => loadData(code)}
        detail={detail}
      />
    </>
  )
}

export default MasterItemDetail
