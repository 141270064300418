import React from 'react'
import { CloseButton, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import FormUpdateGround from './forms/FormUpdateGround'
import FormUpdateRack from './forms/FormUpdateRack'
import FormUpdateStagingArea from './forms/FormUpdateStagingArea'
function ModalUpdateMapEditor(props) {
  const handleSubmit = async (data) => {
    try {
      let res = ''
      // override value form submit
      if (data.staging_id) {
        const values = {
          staging_id: data.staging_id.value,
          blok_id: data.blok_id.value,
          coordinates: props.rectangleBoundValue
        }
        res = await api.post(`settings/map/editor/staging/add`, values)
      } else {
        const values = {
          rack_id: data.rack.value,
          beam_id: data.beam.value,
          slot_id: data.slot.value ?? '',
          type: props.onSelectlevel === 1 ? data.type : '',
          type_id:
            props.onSelectlevel === 1
              ? data.type_id.reduce((acc, cur) => {
                  acc.push(cur.value)
                  return acc
                }, [])
              : [],
          area_id: data.area.value ?? '',
          active: data.activeToggle,
          coordinates: props.rectangleBoundValue
        }

        res = await api.post(`settings/map/editor/slot/${data.id}`, values)
      }

      if (res?.data) {
        props.onHandleListMapEditor()
        props.onHide()
        document
          .querySelector(".leaflet-interactive[stroke='#3388ff']")
          ?.remove()
        Common.showPopUpSuccessMessage(res?.data?.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleCloseButton = () => {
    props.onHide()
  }

  // function handle remove coordinate map
  const handleRemoveCoordinateMap = async (id) => {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response =
            props.onSelectlevel === 1 || props.onSelectlevel === 'on-ground'
              ? await api.delete(`settings/map/editor/slot/${id}`)
              : await api.delete(`settings/map/editor/staging/${id}`)
          if (response.data.success) {
            props.onHide()
            props.onHandleListMapEditor()
            Common.showPopUpSuccessMessage(response.data.message)
          }
        } catch (error) {
          const errMsg =
            error?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  return (
    <Modal {...props} backdrop={'static'} size="lg" centered>
      <Modal.Header>
        <Modal.Title>
          {props?.onSelectlevel === 1 && 'Define Area'}
          {props?.onSelectlevel === 'on-ground' && 'Define Ground Area'}
          {props?.onSelectlevel === 'staging-area' && 'Define Staging Area'}
        </Modal.Title>
        <CloseButton onClick={() => handleCloseButton()} />
      </Modal.Header>
      <Modal.Body>
        {props?.onSelectlevel === 1 && (
          <FormUpdateRack
            handleSubmit={handleSubmit}
            handleRemoveCoordinateMap={handleRemoveCoordinateMap}
            handleCloseButton={handleCloseButton}
            {...props}
          />
        )}
        {props?.onSelectlevel === 'on-ground' && (
          <FormUpdateGround
            handleSubmit={handleSubmit}
            handleRemoveCoordinateMap={handleRemoveCoordinateMap}
            handleCloseButton={handleCloseButton}
            {...props}
          />
        )}
        {props?.onSelectlevel === 'staging-area' && (
          <FormUpdateStagingArea
            handleRemoveCoordinateMap={handleRemoveCoordinateMap}
            handleCloseButton={handleCloseButton}
            {...props}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateMapEditor
