import React from 'react'
import Button from 'react-bootstrap/Button'

const CustomButton = ({
  onClick,
  variant,
  disabled,
  text,
  style = null,
  classNameValue = 'text-white'
}) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      className={classNameValue}
      style={style}
    >
      {text}
    </Button>
  )
}

export default CustomButton
