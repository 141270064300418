import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { SectorUpdateSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'

function ModalUpdateSector(props) {
  const [data, setData] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)

  const fetchSector = async (id) => {
    try {
      const res = await api.get(`master/warehouses/${id}`)

      setData(res.data.data)
      const dataObj = {
        label: res.data.data.warehouse_customer?.warehouse_name ?? null,
        value: res.data.data.warehouse_customer?.id ?? null
      }
      setSelectedValue(dataObj)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async (values, actions) => {
    try {
      // create new object data
      const newValues = {
        warehouse_customer_id: values.warehouse_customer?.value ?? null
      }
      const response = await api.post(
        `/master/warehouses/${data.id}`,
        newValues
      )

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchWarehouses = useCallback(async () => {
    try {
      const res = await api.get(`master/warehouses/all?q=${inputValue}&is_parent=true`)
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((warehouse) =>
          optionValue.push({
            value: warehouse.id,
            label: warehouse.warehouse_name
          })
        )
      }
      setOptions(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }, [inputValue])

  useEffect(
    function () {
      if (props.id !== 0 && props.show) {
        fetchSector(props.id)
      }
    },
    [props.id, props.show]
  )

  useEffect(
    function () {
      fetchWarehouses()
    },
    [fetchWarehouses]
  )

  const handleInputChange = (newValue) => {
    setInputValue(newValue)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption)
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Sector</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            warehouse_code: data.warehouse_code ?? '-',
            warehouse_name: data.warehouse_name ?? '-',
            warehouse_location: data.location?.area_name ?? '-',
            warehouse_type: data.warehouse_type ?? '-',
            warehouse_customer: selectedValue
          }}
          enableReinitialize={true}
          validationSchema={SectorUpdateSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="code"
                      name="warehouse_code"
                      value={values.warehouse_code}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="name"
                      name="warehouse_name"
                      value={values.warehouse_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="city"
                      name="warehouse_location"
                      value={values.warehouse_location}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="type"
                      name="type"
                      value={values.warehouse_type}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                    />
                  </Col>
                  <Col md={12}>
                    <Form.Label>Customer</Form.Label>
                    <Select
                      id="warehouse_customer"
                      name="warehouse_customer"
                      options={options}
                      onInputChange={handleInputChange}
                      value={values.warehouse_customer}
                      onChange={handleSelectChange}
                      placeholder="Search for Customer..."
                      isClearable
                      isSearchable
                    />
                    {touched.warehouse_customer &&
                      !!errors.warehouse_customer && (
                        <Form.Control.Feedback type="invalid">
                          {errors.warehouse_customer}
                        </Form.Control.Feedback>
                      )}
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateSector
