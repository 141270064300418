import { Container, Row, Col, Form, Table, Collapse } from 'react-bootstrap'
import BreadCrumb from '../../components/Label/Breadcrumb/index'

const WorkOrderPlanDetail = () => {
  const titleStyle = {
    fontSize: '11px',
    letterSpacing: '0.5px'
  }

  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  return (
    <>
      <Container>
        <Row className="mb-3">
          <Col md={4}>
            <BreadCrumb
              label={'WOP23/02/B0021'}
              link="/materialrequest/"
              back="REPACK LIST"
              status={'waiting'}
              type={'BadgeStatusPrList'}
            />
          </Col>
          <Col md={8} className="text-end">
            <button className="btn btn-danger me-3">Cancel WO</button>
            <button className="btn" style={btnOrange}>
              Continue with available source
            </button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>request time</small>
            </h6>
            <h5>14-05-2024; 23:00</h5>
          </Col>
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>type</small>
            </h6>
            <h5>Alias</h5>
          </Col>
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>ERP WO NO.</small>
            </h6>
            <h5>-</h5>
          </Col>
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>warehouse</small>
            </h6>
            <h5>Gudang Utama Jakarta</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">TARGET</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">No</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Alias</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle">Batch</th>
                  <th className="p-3 align-middle">Expired</th>
                  <th className="p-3 align-middle">Qty@Pack</th>
                  <th className="p-3 align-middle">Total Qty</th>
                  <th className="p-3 align-middle">UOM</th>
                  <th className="p-3 align-middle">Total Pack</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3">1</td>
                  <td className="p-3">CETIOL CC</td>
                  <td className="p-3">CETICOLA</td>
                  <td className="p-3">8457-AU-002-1654</td>
                  <td className="p-3">1234567891</td>
                  <td className="p-3">10-02-2025</td>
                  <td className="p-3">100</td>
                  <td className="p-3">500</td>
                  <td className="p-3">L</td>
                  <td className="p-3">5 Drum (-1)</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">SOURCE</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">No</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Alias</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle">Batch</th>
                  <th className="p-3 align-middle">Expired</th>
                  <th className="p-3 align-middle">Total Qty</th>
                  <th className="p-3 align-middle">Total Pack</th>
                  <th className="p-3 align-middle">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3">1</td>
                  <td className="p-3">CETIOL CC</td>
                  <td className="p-3">-</td>
                  <td className="p-3">8457-AU-002-1654</td>
                  <td className="p-3">1234567891</td>
                  <td className="p-3">10-02-2025</td>
                  <td className="p-3">500 L</td>
                  <td className="p-3">5 Drum (-1)</td>
                  <td className="p-3">B.N.A.D</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-4">
          <hr />
        </Row>
        <Row>
          <Col>
            <h5 className="fw-semibold mb-3">PROGRESS DETAIL</h5>
          </Col>
          <Collapse in={true}>
            <ul className="list-unstyled">
              <li>
                <div className="d-flex flex-row gap-3 progress-detail">
                  <div className="circle-progress-detail"></div>
                  <p>14 may 2024 11:32</p>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{ height: '13px' }}
                  >
                    <div className="d-flex flex-row gap-3">
                      <div className="circle-small-progress-detail mt-2"></div>
                      <span style={{ fontSize: '13px' }} className="fw-bold">
                        PnP (Name) Report
                      </span>
                    </div>
                  </div>
                </div>
                <ul className="list-unstyled" style={{ marginLeft: '2rem' }}>
                  <li
                    style={{
                      color: 'black',
                      fontSize: '12px'
                    }}
                    className="mb-3"
                  >
                    B.N.A.D.10.05.01.1 - CETIOL CC - 1234567891, Barang Tidak
                    Ada (1) - Tidak ada suggestion lagi
                  </li>
                </ul>
              </li>
              <li>
                <div className="d-flex flex-row gap-3 progress-detail">
                  <div className="circle-progress-detail"></div>
                  <p>14 may 2024 10:32</p>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{ height: '13px' }}
                  >
                    <div className="d-flex flex-row gap-3">
                      <div className="circle-small-progress-detail mt-2"></div>
                      <span style={{ fontSize: '13px' }} className="fw-bold">
                        PNP (Name) start confirmation
                      </span>
                    </div>
                  </div>
                </div>
                {/* <ul className="list-unstyled" style={{ marginLeft: '2rem' }}>
                  <li
                    style={{
                      color: 'black',
                      fontSize: '12px'
                    }}
                    className="mb-3"
                  >
                    B.N.A.D.10.05.01.1 - CETIOL CC - 1234567891, Barang Tidak
                    Ada (1) - Tidak ada suggestion lagi
                  </li>
                </ul> */}
              </li>
              <li>
                <div className="d-flex flex-row gap-3 progress-detail">
                  <div className="circle-progress-detail"></div>
                  <p>14 may 2024 10:00</p>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{ height: '13px' }}
                  >
                    <div className="d-flex flex-row gap-3">
                      <div className="circle-small-progress-detail mt-2"></div>
                      <span style={{ fontSize: '13px' }} className="fw-bold">
                        OM (Name) created Work Order Plan
                      </span>
                    </div>
                  </div>
                </div>
                {/* <ul className="list-unstyled" style={{ marginLeft: '2rem' }}>
                  <li
                    style={{
                      color: 'black',
                      fontSize: '12px'
                    }}
                    className="mb-3"
                  >
                    B.N.A.D.10.05.01.1 - CETIOL CC - 1234567891, Barang Tidak
                    Ada (1) - Tidak ada suggestion lagi
                  </li>
                </ul> */}
              </li>
            </ul>
          </Collapse>
        </Row>
        <Row className="mb-5">
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>pnp checker</small>
            </Form.Label>
            <Form.Control disabled placeholder="John Rembo" />
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>rnr checker</small>
            </Form.Label>
            <Form.Control disabled />
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>mover</small>
            </Form.Label>
            <Form.Control disabled placeholder="John Connor" />
          </Col>
        </Row>
        <Row className="mb-3">
          <hr />
        </Row>
        <Row>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>created by</small>
            </Form.Label>
            <Form.Control disabled placeholder="John Connor" />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>created at</small>
            </Form.Label>
            <Form.Control disabled placeholder="15-03-2024, 08:00" />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>last update by</small>
            </Form.Label>
            <Form.Control disabled />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>last update at</small>
            </Form.Label>
            <Form.Control disabled />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WorkOrderPlanDetail
