import {
  faCircleXmark,
  faFileArrowDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { convertDateFormat } from '../../../utils/FormatDate'
import './style.css'

const veticalLine = {
  borderLeft: '3px #E0E0E0 solid',
  height: '50px',
  width: '0px',
  marginLeft: '6px'
}

const circle = {
  width: '15px',
  height: '15px',
  background: '#E0E0E0',
  borderRadius: '50%',
  marginTop: '5px'
}

const titleHistory = {
  color: '#828282'
}

const ModalUpdateVehiclePrDraft = (props) => {
  const { datahistory, detailinbounds } = props
  const [fileArmada, setFileArmada] = useState()
  const [fileMaterial, setFileMaterial] = useState()
  useEffect(() => {
    setFileArmada(datahistory?.data?.inbound?.media?.file_armada)
    setFileMaterial(datahistory?.data?.inbound?.media?.file_material)
  }, [datahistory])

  const handleRemove = async () => {
    try {
      const data = {
        action: detailinbounds.status
      }
      const res = await api.post(
        `inbound/pr-draft/unassign/${detailinbounds.id}`,
        data
      )

      if (res.data.success) {
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleDownloadArmada = () => {
    const pdfUrl = fileArmada
    const link = document.createElement('a')
    link.href = pdfUrl
    link.download = fileArmada
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadMaterial = () => {
    const pdfUrl = fileMaterial
    const link = document.createElement('a')
    link.href = pdfUrl
    link.download = fileMaterial
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Modal {...props} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{detailinbounds.ticket_id}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col md={3}>
            <h4>{detailinbounds?.vehicle?.plate_no}</h4>
          </Col>
          {datahistory?.data?.inbound?.status === 'put_away' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Put Away by{' '}
                  {datahistory?.data?.inbound?.action?.pnp?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.pnp?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Mover by{' '}
                  {datahistory?.data?.inbound?.action?.mover?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.mover?.name ===
                          null
                            ? '-'
                            : datahistory?.data?.inbound?.action?.mover?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : datahistory?.data?.inbound?.status === 'waiting_om' ||
            datahistory?.data?.inbound?.status === 'waiting_pnp' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  {datahistory?.data?.inbound?.status === 'waiting_om'
                    ? 'Waiting OM'
                    : 'Waiting PNP'}
                </li>
              </Col>
            </>
          ) : datahistory?.data?.inbound?.status === 'receiving_progress' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Receiving By{' '}
                  {datahistory?.data?.inbound?.action?.rnr?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.rnr?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>Done</li>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-4">
          <Col md={12} className="ms-2">
            <h6>
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                icon={faFileArrowDown}
                className="text-warning fa-xl"
                onClick={() => handleDownloadArmada()}
              />
              <small
                style={{
                  fontSize: '14px',
                  marginRight: '20px',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
              >
                Checklist Armada
              </small>
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                icon={faFileArrowDown}
                className="text-warning fa-xl"
                onClick={() => handleDownloadMaterial()}
              />
              <small
                style={{
                  fontSize: '14px',
                  marginRight: '20px',
                  marginLeft: '5px'
                }}
              >
                Checklist Material
              </small>
            </h6>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={1} className="text-center">
            {datahistory?.data?.histories.map((data, i) => (
              <div key={i} style={{ marginTop: '-4px' }}>
                <div style={circle} />
                <div style={veticalLine} />
              </div>
            ))}
          </Col>
          <Col>
            {datahistory?.data?.histories.map((data, i) => {
              return (
                <div key={i} className="mb-4">
                  <h5 style={titleHistory}>
                    {convertDateFormat(data.createdAt)}
                  </h5>
                  <h5 style={titleHistory}>
                    <small style={{ fontSize: '14px', marginRight: '5px' }}>
                      {data.notes}
                    </small>
                  </h5>
                </div>
              )
            })}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateVehiclePrDraft
