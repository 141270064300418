import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../Button/CustomButton'
function ModalSolveContinueTicket(props) {
  return (
    <Modal {...props} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center text-center gap-3 h-100 align-items-center">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ color: 'grey', fontSize: '100px' }}
          />
          <p style={{ fontSize: '14px' }}>Item Qty is Less than Request!</p>
          <p
            className="fw-bold"
            style={{ fontSize: '14px', marginTop: '-1rem' }}
          >
            Continue? Action can’t be canceled
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-row gap-2 w-100 justify-content-end">
          <CustomButton
            variant={'transparent'}
            text={'Cancel'}
            classNameValue="text-muted"
            onClick={() => props.onHide()}
          />
          <CustomButton
            variant={
              !props.submitSolveContinueTicket ? 'transparent' : 'secondary'
            }
            classNameValue={`text-white ${
              !props.submitSolveContinueTicket ? 'btn-primary-orange' : ''
            }`}
            text={!props.submitSolveContinueTicket ? 'Continue' : 'Loading...'}
            onClick={() => props.handleSubmitSolveContinueTicket()}
            disabled={props.submitSolveContinueTicket ? true : false}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSolveContinueTicket
