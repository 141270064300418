import React, { useEffect, useState } from 'react'
import { Button, Col, Container, NavLink, Row } from 'react-bootstrap'
import ExpeditionTable from '../../../components/master/ExpeditionTable'
import RentTable from '../../../components/master/RentTable'
import ModalAddExpedition from '../../../components/modal/expeditionrent/ModalAddExpedition'
import ModalAddRent from '../../../components/modal/expeditionrent/ModalAddRent'
import ModalUpdateExpedition from '../../../components/modal/expeditionrent/ModalUpdateExpedition'
import ModalUpdateRent from '../../../components/modal/expeditionrent/ModalUpdateRent'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'

const MasterExpedition = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const [modalAddShow, setModalAddShow] = useState(false)
  const [typeData, setTypeData] = useState('expedition')
  const [modalAddExpedition, setModalAddExpedition] = useState(false)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [modalUpdateExpedition, setModalUpdateExpedition] = useState(false)
  const [editId, setEditId] = useState(0)
  const [dataExpedition, setDataExpedition] = useState([])
  const [dataRent, setDataRent] = useState([])
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [pageCount, setPageCount] = useState(0)

  const { per_page, current_page } = meta

  const loadData = async () => {
    try {
      const res =
        typeData === 'expedition'
          ? await api.get('master/expedition/list', {
              params: { per_page, page: current_page }
            })
          : await api.get('master/expedition/rents', {
              params: { per_page, page: current_page }
            })
      const { metadata, data } = res.data
      if (typeData === 'expedition') {
        setDataExpedition(data)
      }
      if (typeData === 'rent') {
        setDataRent(data)
      }
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    loadData()
  }, [typeData, per_page, current_page])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(row) {
    if (row.type) {
      setModalUpdateExpedition(true)
    } else {
      setModalUpdateShow(true)
    }
    setEditId(row.id)
  }

  const [expeditionShow, setExpeditionShow] = useState(false)
  const [rentShow, setRentShow] = useState(true)

  const showExpedition = () => {
    setExpeditionShow(false)
    setRentShow(true)
    setTypeData('expedition')
    setMeta({
      per_page: 10,
      current_page: 1,
      total_row: 0,
      total_page: 0
    })
  }

  const showRent = () => {
    setExpeditionShow(true)
    setRentShow(false)
    setTypeData('rent')
    setMeta({
      per_page: 10,
      current_page: 1,
      total_row: 0,
      total_page: 0
    })
  }

  return (
    <>
      <Container className="ms-1">
        <Row>
          <h3 style={headerTitleStyle}>Master Expedition & Rent</h3>
        </Row>
        <Row className="mt-4">
          <Row className="shadow-sm bg-white p-2">
            <Col md={2}>
              <NavLink variant="link" onClick={showExpedition}>
                <h5
                  className={
                    expeditionShow === false ? `text-dark` : `text-secondary`
                  }
                >
                  <strong
                    className="p-2"
                    style={
                      expeditionShow === false
                        ? { borderBottom: '10px solid orange' }
                        : {}
                    }
                  >
                    Expedition
                  </strong>
                </h5>
              </NavLink>
            </Col>
            <Col md={2} style={{ marginLeft: '-50px' }}>
              <NavLink onClick={showRent}>
                <h5
                  className={rentShow === true ? `text-secondary` : `text-dark`}
                >
                  <strong
                    className="p-2"
                    style={
                      rentShow === true
                        ? {}
                        : { borderBottom: '10px solid orange' }
                    }
                  >
                    Rent
                  </strong>
                </h5>
              </NavLink>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12} hidden={expeditionShow}>
              <Container>
                <Row className="mt-3">
                  <Col md={4} className="mt-3">
                    <small className="text-secondary">
                      <strong>EXPEDITION LIST</strong>
                    </small>
                  </Col>
                  <Col md={8} className="text-end">
                    <Button
                      className="btn btn-warning me-2 text-white"
                      onClick={() => setModalAddExpedition(true)}
                    >
                      Add
                    </Button>
                    <ModalAddExpedition
                      show={modalAddExpedition}
                      onHide={() => setModalAddExpedition(false)}
                      refreshData={() => loadData()}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <ExpeditionTable
                      dataExpedition={dataExpedition}
                      handleEdit={handleEdit}
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      pageCount={pageCount}
                      handlePageChange={handlePageChange}
                    />
                  </Col>
                </Row>
              </Container>
              <ModalUpdateExpedition
                show={modalUpdateExpedition}
                onHide={() => setModalUpdateExpedition(false)}
                id={editId}
                refreshData={() => loadData()}
              />
            </Col>
            {/* RENT */}
            <Col md={12} hidden={rentShow}>
              <Container>
                <Row className="mt-3">
                  <Col md={4} className="mt-3">
                    <small className="text-secondary">
                      <strong>RENT LIST</strong>
                    </small>
                  </Col>
                  <Col md={8} className="text-end">
                    <Button
                      className="btn btn-warning me-2 text-white"
                      onClick={() => setModalAddShow(true)}
                    >
                      Add
                    </Button>
                    <ModalAddRent
                      show={modalAddShow}
                      onHide={() => setModalAddShow(false)}
                      refreshData={() => loadData()}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <RentTable
                      dataRent={dataRent}
                      handleEdit={handleEdit}
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      pageCount={pageCount}
                      handlePageChange={handlePageChange}
                    />
                  </Col>
                </Row>
              </Container>
              <ModalUpdateRent
                show={modalUpdateShow}
                onHide={() => setModalUpdateShow(false)}
                id={editId}
                refreshData={() => loadData()}
              />
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  )
}

export default MasterExpedition
