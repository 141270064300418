import { useNavigate } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Pagination from '../../../components/atoms/Pagination/Index'
import api from '../../../config/AxiosInstance'
import { useEffect, useState } from 'react'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MasterStockDetail = () => {
  const [data, setData] = useState({})
  const [stocks, setStocks] = useState([])
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 10,
    total_row: 0,
    total_page: 0
  })

  const { code } = useParams()

  const loadData = async (code) => {
    try {
      const response = await api.get(`/master/stocks/${code}`)
      console.log(response.data.metadata)
      setData(response.data.data)
      setStocks(response.data.data.stocks)
      setMeta(response.data.metadata)
    } catch (err) {
      console.log(err)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    loadData(code)
  }, [])

  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <Col className="d-flex align-items-center gap-4">
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faArrowLeft}
            onClick={() => navigate(-1)}
          ></FontAwesomeIcon>
          <h5 className="pt-1">{data.item_name}</h5>
        </Col>
      </Row>
      <Row className="my-2">
        <Col md={2}>
          <small className="text-secondary fw-bold">
            {data.warehouse_name}
          </small>
        </Col>
      </Row>
      <Row>
        <Col>
          <small className="text-secondary fw-semibold">Description</small>
          <p className="fw-bold">{data.description}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Master Stock</h1>
        </Col>
      </Row>
      <Row>
        <Table>
          <thead className="table-secondary">
            <tr>
              <th className="align-middle p-3">Batch</th>
              <th className="align-middle P-3">Received</th>
              <th className="align-middle P-3">Exp Date</th>
              <th className="align-middle P-3">Total Pack</th>
              <th className="align-middle P-3">Qty/Pack</th>
              <th className="align-middle P-3">Total Qty</th>
              <th className="align-middle P-3">Location</th>
            </tr>
          </thead>
          {stocks.map((stock) => (
            <tbody>
              <tr>
                <td className="p-3">{stock.batch}</td>
                <td className="p-3">{stock.received}</td>
                <td className="p-3">{stock.exp_date}</td>
                <td className="p-3">
                  {stock.total_pack} {stock.uom_pack}
                </td>
                <td className="p-3">
                  {stock.qty_per_pack} {stock.uom_qty_per_pack}
                </td>
                <td className="p-3">
                  {stock.total_qty} {stock.uom_qty}
                </td>
                <td className="p-3">{stock.location}</td>
              </tr>
            </tbody>
          ))}
          <tfoot>
            <tr>
              <td colSpan={4} className="p-3">
                <div className="d-flex align-items-center">
                  <span className="mr-2">Show</span>
                  <select
                    name="per_page"
                    className="form-select"
                    aria-label="show-page"
                    style={{ width: '80px' }}
                    onChange={handleOnChangeMeta}
                  >
                    {[10, 25, 50, 100].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={5} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    onPageChange={handlePageChange}
                    pageCount={meta.total_page}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  )
}

export default MasterStockDetail
