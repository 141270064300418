import { Modal, Row, Col } from 'react-bootstrap'
import { Formik, Field, Form } from 'formik'
import CustomButton from '../../Button/CustomButton'
import { useNavigate } from 'react-router-dom'
function ModalTypePrp(props) {
  const navigate = useNavigate()
  const { data, code } = props

  async function handleSubmit(values) {
    const type = values.type

    const encodedName = encodeURIComponent(code)
    navigate(`/inbound/purchase-order/create/${encodedName}`, {
      state: { data: data, type: type }
    })
  }
  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Pilih Tipe PRP</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          type: ''
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ handleSubmit, isValid, dirty }) => {
          return (
            <Form className="mt-4" onSubmit={handleSubmit}>
              <Modal.Body>
                <Row style={{ fontSize: '14px' }}>
                  <Col>
                    <label className="ms-2">
                      <Field type="radio" name="type" value="local" />
                      <span className="ml-2">Local</span>
                    </label>
                  </Col>
                  <Col>
                    <label className="ms-2">
                      <Field type="radio" name="type" value="import" />
                      <span className="ml-2">Import</span>
                    </label>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="mt-4">
                <CustomButton
                  text={'Save'}
                  variant={'warning'}
                  onClick={handleSubmit}
                  disabled={!isValid || !dirty}
                />
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalTypePrp
