import React, { useEffect } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import { dimensionSchema } from '../../../utils/validationSchema'
import TextField from '../../Input/TextInput'

const DimensionForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue
}) => {
  useEffect(() => {
    const volume = values.length * values.width * values.height
    setFieldValue('volume', volume, false)
  }, [values.length, values.width, values.height, setFieldValue])

  return (
    <Row>
      {['length', 'width', 'height'].map((field, idx) => (
        <TextField
          label={`${field} cm`}
          name={field}
          value={values[field]}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          placeholder="0"
          required={true}
          type="number"
          col={6}
        />
      ))}
      <TextField
        label="volume (cm)"
        name="volume"
        value={values.volume}
        touched={touched}
        errors={errors}
        required={true}
        type="number"
        col={6}
        disabled
      />
    </Row>
  )
}

function ModalAddEditDimension({ detail, onHide, refreshData, ...props }) {
  const initialValues = {
    length: detail.length_item || 0,
    width: detail.width_item || 0,
    height: detail.height_item || 0,
    volume: detail.length_item * detail.width_item * detail.height_item || 0
  }

  const handleSubmit = async (values) => {
    try {
      const res = await api.post(
        `/master/items/${detail.id}/dimensions`,
        values
      )
      Common.showPopUpSuccessMessage(res.data.message)
      onHide()
      refreshData()
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Dimensions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={dimensionSchema}
          validateOnChange
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue
          }) => (
            <Form className="d-grid" onSubmit={handleSubmit}>
              <DimensionForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              <Modal.Footer>
                <CustomCloseButton onClick={onHide} />
                <CustomButton
                  onClick={handleSubmit}
                  variant="warning"
                  disabled={!isValid}
                  text="Save"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddEditDimension
