import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  InputGroup
} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../../components/atoms/Pagination/Index'
import ModalCreate from '../../../components/modal/ReceiveAtWarehousePlan/ModalCreate'
import { useState } from 'react'

const ReceiveAtWarehousePlan = () => {
  const [show, setShow] = useState(false)
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }

  const data = [
    {
      id: 1,
      dd: '15-05-2024',
      tr: 'TR23/02/B0034',
      mr: 'MR23/02/B0034',
      sw: 'Gudang Transit Surabaya',
      tw: 'Gudang Utama Jakarta',
      dt: 'Internal',
      driver: 'John Cena',
      car: 'B 1237  BC'
    }
  ]
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 style={headerTitleStyle}>Receive At Warehouse Plan</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Select name="source warehouse">
              <option value="">All</option>
              <option value="">1</option>
              <option value="">2</option>
              <option value="">3</option>
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Select name="source warehouse">
              <option value="">All</option>
              <option value="">1</option>
              <option value="">2</option>
              <option value="">3</option>
            </Form.Select>
          </Col>
          <Col md={4} className="d-flex gap-2">
            <div className="w-50">
              <Form.Label
                className="fw-bold text-secondary text-uppercase"
                style={titleStyle}
              >
                <small>Delivery Date</small>
              </Form.Label>
              <Form.Control type="date" format="yyyy-mm-dd" name="startDate" />
            </div>
            <h1 style={{ marginTop: '18px' }} className="text-secondary">
              -
            </h1>
            <div className="w-50">
              <Form.Label
                className="fw-bold text-secondary text-uppercase"
                style={(titleStyle, { marginTop: '15px' })}
              >
                <small></small>
              </Form.Label>
              <Form.Control type="date" name="endDate" />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}></Col>
          <Col md={6} className="text-end">
            <Button
              variant="transparent"
              className="btn text-muted fw-semibold me-2"
            >
              Reset Filter
            </Button>
            <Button style={btnOrange}>Apply Filter</Button>
          </Col>
        </Row>
        <Row>
          <Col md={8}></Col>
          <Col md={4} className="text-end"></Col>
        </Row>
        <Row className="mt-3">
          <Col md={9}></Col>
          <Col md={3}>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Example text with button addon"
                aria-describedby="basic-addon1"
                placeholder="Search TR"
                className="border-end-0"
              />
              <Button
                className="border-start-0 border-secondary-subtle"
                id="button-addon1"
                variant="transparent"
                disabled
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">Delivery Date</th>
                  <th className="p-2 align-middle">#TR</th>
                  <th className="p-2 align-middle">#MR</th>
                  <th className="p-2 align-middle">Source Warehouse</th>
                  <th className="p-2 align-middle">Target Warehouse</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Car</th>
                  <th className="p-2 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <>
                    <tr key={item.id}>
                      <td className="p-2 align-middle">{item.dd}</td>
                      <td className="p-2 align-middle">{item.tr}</td>
                      <td className="p-2 align-middle">{item.mr}</td>
                      <td className="p-2 align-middle">{item.sw}</td>
                      <td className="p-2 align-middle">{item.tw}</td>
                      <td className="p-2 align-middle">{item.dt}</td>
                      <td className="p-2 align-middle">{item.driver}</td>
                      <td className="p-2 align-middle">{item.car}</td>
                      <td className="p-2 align-middle">
                        <button
                          className="btn btn-primary-blue ms-2"
                          onClick={() => setShow(true)}
                        >
                          +ML DRAFT
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-2">
                    <div className="d-flex align-items-center gap-2">
                      <span className="mr-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="show-page"
                        style={{ width: '60px' }}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="ml-2">
                        Entries | 1 to 10 of 10 entries
                      </span>
                    </div>
                  </td>
                  <td colspan={8} className="p-2">
                    <div className="d-flex justify-content-end">
                      <Pagination pageCount={10} page={1} />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <ModalCreate show={show} onHide={() => setShow(false)} />
      </Container>
    </>
  )
}

export default ReceiveAtWarehousePlan
