import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { BranchUpdateSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'

function ModalUpdateBranch(props) {
  const [data, setData] = useState({})

  const fetchBranch = async (id) => {
    try {
      const res = await api.get(`master/locations/${id}`)
      setData(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post(`/master/locations/${data.id}`, values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(
    function () {
      if (props.id !== 0 && props.show) {
        fetchBranch(props.id)
      }
    },
    [props.id, props.show]
  )

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            !data
              ? { code: '', name: '', description: '' }
              : {
                  code: data.location_code,
                  name: data.area_name,
                  description: data.area_description
                }
          }
          enableReinitialize={true}
          validationSchema={BranchUpdateSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="code"
                      name="code"
                      value={values.code}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="name"
                      name="name"
                      value={values.name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                      label="description"
                      name="description"
                      as="textarea"
                      value={values.description}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateBranch
