import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Form, Table } from 'react-bootstrap'
import Pagination from '../../../components/atoms/Pagination/Index'
import api from '../../../config/AxiosInstance'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MasterStock = () => {
  const [warehouse, setWarehouse] = useState([])
  const [warehouseid, setWarehouseid] = useState('')
  const [stocks, setStocks] = useState([])
  const [itemName, setItemName] = useState('')
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })

  const fetchWarehouse = async () => {
    try {
      const response = await api.get('/master/warehouses/all?is_parent=true')
      setWarehouse(response.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchStock = async () => {
    try {
      if (warehouseid === '') return
      const response = await api.get(`/master/stocks`, {
        params: {
          warehouse_id: warehouseid,
          item_name: itemName
        }
      })
      setStocks(response.data.data)
      setMeta(response.data.metadata)
      console.log(response.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelected = () => {
    fetchStock()
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handleDetail = (code) => {
    const encodedName = encodeURIComponent(code)
    navigate(`/master/stock/${encodedName}`)
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    fetchWarehouse()
    fetchStock()
  }, [])

  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Master Stock</h1>
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                warehouse name
              </small>
            </Form.Label>
            <Form.Select
              name="warehouse"
              id="warehouse"
              onChange={(e) => setWarehouseid(e.target.value)}
            >
              <option value="">All</option>
              {warehouse.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                item name
              </small>
            </Form.Label>
            <Form.Control
              onChange={(e) => setItemName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={2} className="d-flex justify-content-end align-items-end pt-4">
          <button
            className="btn btn-primary-orange"
            onClick={() => handleSelected()}
          >
            View
          </button>
        </Col>
      </Row>
      <Row className="mt-4 mb-3">
        <h1
          className="text-secondary fw-bold text-uppercase"
          style={{ fontSize: '18px' }}
        >
          item list
        </h1>
      </Row>
      <Row>
        <Table>
          <thead className="table-secondary">
            <tr>
              <th className="align-middle p-3">Item Name</th>
              <th className="align-middle P-3">Item Alias</th>
              <th className="align-middle P-3">Item Code</th>
              <th className="align-middle P-3">Total Pack</th>
              <th className="align-middle P-3">Total Qty</th>
              <th className="align-middle P-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {stocks.length === 0 && (
              <tr>
                <td colSpan={6} className="p-3 text-center">
                  No data
                </td>
              </tr>
            )}
            {stocks.length > 0 &&
              stocks.map((stock) => (
                <tr key={stock.item_id}>
                  <td className="p-3">{stock.item_name}</td>
                  <td className="p-3">{stock.item_alias ?? '-'}</td>
                  <td className="p-3">{stock.item_code}</td>
                  <td className="p-3">
                    {stock.total_pack} {stock.uom_pack}
                  </td>
                  <td className="p-3">
                    {stock.total_qty} {stock.uom_qty}
                  </td>
                  <td className="p-3">
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleDetail(stock.item_id)}
                    >
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        style={{ fontSize: '14px' }}
                      ></FontAwesomeIcon>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4} className="p-3">
                <div className="d-flex align-items-center">
                  <span className="mr-2">Show</span>
                  <select
                    name="per_page"
                    className="form-select"
                    aria-label="show-page"
                    value={meta.per_page}
                    style={{ width: '80px' }}
                    onChange={handleOnChangeMeta}
                  >
                    {[10, 25, 50, 100].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">
                    Entries | {meta.current_page} to {meta.per_page} of{' '}
                    {meta.total_row} entries
                  </span>
                </div>
              </td>
              <td colSpan={5} className="p-3">
                <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={meta.total_page}
                    onPageChange={handlePageChange}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  )
}

export default MasterStock
