import { Col, Form, Modal, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import * as Common from '../../../utils/Common'
import api from '../../../config/AxiosInstance'
import { Formik } from 'formik'
import { CategoryAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Inactive' }
]

function ModalUpdateCategory(props) {
  const [selectedData, setSelectedData] = useState({
    category_name: '',
    status: ''
  })
  const [loading, setLoading] = useState(true)

  const loadData = async (id) => {
    setLoading(true)
    try {
      const response = await api.get(`master/categories/${id}`)
      setSelectedData({
        category_name: response.data.data.name,
        status: response.data.data.status
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) {
      loadData(props.id)
    }
  }, [props.id])

  const handleSubmit = async (values) => {
    try {
      const response = await api.post(`master/categories/${props.id}`, values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        setSelectedData(values)

        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <Formik
            key={props.id}
            initialValues={
              !selectedData
                ? {
                    category_name: '',
                    status: ''
                  }
                : {
                    category_name: selectedData.category_name,
                    status: selectedData.status
                  }
            }
            validationSchema={CategoryAddEditSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              dirty
            }) => {
              return (
                <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <TextField
                        label="category name"
                        name="category_name"
                        value={values.category_name}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectInput
                        label="Status"
                        name="status"
                        id="status"
                        handleChange={handleChange}
                        value={values.status}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={statuses}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton text={'Cancel'} onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Update'}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateCategory
