import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { RackAddEditSchema } from '../../../utils/validationSchema'
import Loader from '../../atoms/Loader'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import SelectInput from '../../Input/SelectInput'
import TextField from '../../Input/TextInput'

const rackes = [
  { id: 'rack', name: 'Rack' },
  { id: 'ground', name: 'Ground' }
]

function ModalUpdateRack(props) {
  const [areas, setAreas] = useState([])
  const [dataValue, setDataValue] = useState()
  const [loading, setLoading] = useState(true)
  const RackId = props.id
  const [hasData, setHasData] = useState({
    rack_name: '',
    area_id: '',
    category_id: [],
    type: '',
    total_beam: '',
    total_level: ''
  })

  useEffect(() => {
    if (RackId !== 0) {
      loadData(RackId)
      loadArea()
    }
  }, [RackId, props.show])

  const loadData = async (RackId) => {
    setLoading(true)
    try {
      const response = await api.get(`master/racks/${RackId}`)
      const responses = response.data.data
      const ctg = responses.categories

      setHasData({
        rack_name: responses.name,
        area_id: responses.area_id,
        category_id: ctg.map((item) => ({
          id: item.id,
          name: item.name
        })),
        type: responses.type,
        total_beam: responses.total_beam,
        total_level: responses.total_level
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async (values, actions) => {
    try {
      values.category_id =
        hasData.type === 'rack'
          ? hasData.category_id.map((item) => item.id)
          : []
      const response = await api.post(`/master/racks/${RackId}`, values)
      if (response.data.success) {
        props.refreshData()
        loadData(RackId)
        props.onHide()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const loadArea = async () => {
    try {
      const res = await api.get('/master/area')
      setAreas(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Rack</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        <Formik
          initialValues={{
            rack_name: hasData.rack_name,
            total_beam: hasData.total_beam,
            total_level: hasData.total_level,
            area_id: hasData.area_id,
            type: hasData.type,
            category_id: hasData.category_id
          }}
          enableReinitialize={true}
          validationSchema={RackAddEditSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
            touched
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <TextField
                    labelsm="name"
                    name="name"
                    value={values.rack_name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    required={true}
                    col={6}
                  />
                  <TextField
                    labelsm="total beam"
                    name="total_beam"
                    value={values.total_beam}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    required={true}
                    col={3}
                    disabled={hasData.type === 'ground' ? true : false}
                  />
                  <TextField
                    labelsm="total level"
                    name="total_level"
                    value={values.total_level}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    required={true}
                    col={3}
                    disabled={hasData.type === 'ground' ? true : false}
                  />
                  <SelectInput
                    label="area"
                    name="area_id"
                    id="area_id"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={areas}
                    required={true}
                    col={6}
                  />
                  <SelectInput
                    label="type"
                    name="type"
                    id="type"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={rackes}
                    handleChange={(e) => {
                      setFieldValue('type', e.target.value)
                      if (e.target.value === 'ground') {
                        setFieldValue('total_beam', 1)
                        setFieldValue('total_level', 1)
                      } else {
                        setFieldValue('total_beam', values.total_beam ?? '')
                        setFieldValue('total_level', values.total_level ?? '')
                      }
                      setHasData({ ...hasData, type: e.target.value })
                    }}
                    value={values.type}
                    col={6}
                    required={true}
                  />
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid}
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateRack
