import { Form, Modal, Row, Col } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import * as Common from '../../../utils/Common'
import api from '../../../config/AxiosInstance'
import { Formik } from 'formik'
import { expeditionRentAddEditSchema } from '../../../utils/validationSchema'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Inactive' }
]

function ModalUpdateRent(props) {
  const [selectedData, setSelectedData] = useState({
    name: '',
    address: '',
    phone: '',
    status: true
  })
  const [loading, setLoading] = useState(true)

  const loadData = async (id) => {
    setLoading(true)
    try {
      const response = await api.get(`master/expedition/${id}`)
      setSelectedData({
        name: response.data.data.name,
        address: response.data.data.address,
        phone: response.data.data.phone,
        status: response.data.data.status
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) {
      loadData(props.id)
    }
  }, [props.id])

  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post(`master/expedition/${props.id}`, values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()

        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Rent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <Formik
            initialValues={
              !selectedData
                ? {
                    name: '',
                    address: '',
                    phone: '',
                    status: true
                  }
                : {
                    name: selectedData.name,
                    address: selectedData.address,
                    phone: selectedData.phone,
                    status: selectedData.status
                  }
            }
            validationSchema={expeditionRentAddEditSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              dirty
            }) => {
              return (
                <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <TextField
                        label="name"
                        name="name"
                        value={values.name}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        placeholder="Input Name"
                        required={true}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        label="phone number"
                        name="phone"
                        value={values.phone}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        placeholder="Input Phone Number"
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        label="address"
                        name="address"
                        value={values.address}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        placeholder="Input Address"
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectInput
                        label="Status"
                        name="status"
                        id="status"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={statuses}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Update'}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateRent
