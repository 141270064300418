import {
  faEllipsisVertical,
  faRefresh
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import { Col, Container, Dropdown, Navbar, Row } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import ModalChangePassword from '../../components/modal/changepassword/ModalChangePassword'
import { UserContext } from '../../context/userContext'
import '../../styles/style.css'
import { firstLetterUpperCase } from '../../utils/TextFormatter'

const NavbarMover = ({
  user,
  onHandleGetDataFilter,
  onHandleGetAssignMover
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [state, dispatch] = useContext(UserContext)
  const [filterButton, setFilterButton] = useState(false)
  const params = useParams()
  const handleFilterToggle = (value) => {
    setFilterButton(value)
  }

  const handleLogOut = () => {
    Swal.fire({
      title: 'Sign Out?',
      icon: 'warning',
      iconColor: '#828282',
      showCancelButton: true,
      confirmButtonColor: '#C12727',
      cancelButtonColor: 'transparent',
      confirmButtonText: 'Sign out',
      showCloseButton: true,
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-remove-outbound'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: 'logout', payload: '' })
        navigate('/')
      }
    })
  }

  return (
    <>
      <Navbar className="shadow-lg bg-white rounded">
        <Container>
          <Row className="w-100 p-2">
            <Col>
              <Navbar.Brand
                href="#home"
                className="d-flex h-100 align-items-center fw-bold"
              >
                {`Hello ${firstLetterUpperCase(user.username)}!`}
              </Navbar.Brand>
              <Navbar.Toggle />
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="d-flex flex-row gap-4">
                <FontAwesomeIcon
                  icon={faRefresh}
                  className="mt-2"
                  style={{ fontSize: '25px', color: '#F89D1D' }}
                  onClick={() => onHandleGetAssignMover('assign')}
                />
                <Navbar.Collapse className="ml-5">
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        padding: '0rem 1rem .2rem 1rem',
                        backgroundColor: 'grey',
                        cursor: 'pointer'
                      }}
                      className="shadow-sm"
                      id="dropdown-setting-mover"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className="mt-2"
                        style={{ fontSize: '25px', color: 'white' }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ left: '-8rem', top: '3rem' }}>
                      <Dropdown.Item
                        className="d-flex w-100 justify-content-end"
                        style={{ color: '#C12727', paddingRight: '1rem' }}
                        onClick={handleLogOut}
                      >
                        Keluar
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Collapse>
              </div>
            </Col>
          </Row>

          {/* <div className="d-flex justify-content-end">
            
          </div> */}
        </Container>
      </Navbar>
      <ModalChangePassword
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
      />
    </>
  )
}

export default NavbarMover
