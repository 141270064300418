import { Container, Row, Col, Table, Form, Badge } from 'react-bootstrap'
import BreadCrumb from '../../../components/Label/Breadcrumb/index'
import api from '../../../config/AxiosInstance'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const DetailDeliveryHistory = () => {
  const { id } = useParams()
  const [detail, setDetail] = useState({})
  const [sjList, setSjList] = useState([])

  const getDetail = async () => {
    try {
      const response = await api.get(`outbound/delivery_history/detail/${id}`)
      console.log(response.data.data)
      setDetail(response.data.data)
      setSjList(response.data.data.sj_draft)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDetail()
  }, [])
  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <BreadCrumb
            label={detail.plate_no}
            link="/outbound/delivery-history"
            back="Delivery History"
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>plate no</small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.plate_no} />
        </Col>
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>vehicle type</small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.vehicle_type} />
        </Col>
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>driver</small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.driver} />
        </Col>
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>capacity(kg)</small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.capacity} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>
              max volume(cm<sup>3</sup>)
            </small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.max_volume} />
        </Col>
        <Col md={3}>
          <Form.Label
            style={titleStyle}
            className="fw-bold text-secondary text-uppercase"
          >
            <small>
              total volume picked(cm<sup>3</sup>)
            </small>
          </Form.Label>
          <Form.Control disabled placeholder={detail.total_volume_picked} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h5 className="text-primary fw-semibold">{detail.outbound}</h5>
          <Table
            hover
            size="m"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">#SJ</th>
                <th className="p-2 align-middle">Departure Date</th>
                <th className="p-2 align-middle">Delivery Status</th>
              </tr>
            </thead>
            <tbody>
              {sjList.map((item) => {
                return (
                  <tr key={item.draft_code}>
                    <td className="p-2">{item.draft_code}</td>
                    <td className="p-2">{item.departure_date}</td>
                    <td className="p-2">
                      {item.delivery_status === 'done' ? (
                        <Badge bg="success">{item.delivery_status}</Badge>
                      ) : (
                        <Badge bg="primary" className="text-uppercase">
                          on delivery
                        </Badge>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default DetailDeliveryHistory
