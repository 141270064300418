import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Table } from 'react-bootstrap'
import Pagination from '../atoms/Pagination/Index'
import { ActiveInActive } from '../atoms/StatusLabel'

const RentTable = ({
  dataRent,
  handleEdit,
  meta,
  handleOnChangeMeta,
  pageCount,
  handlePageChange
}) => {
  return (
    <Table
      responsive="sm"
      hover
      className="shadow-sm mb-5 bg-white rounded"
      size="sm"
      style={{ fontSize: '14px' }}
    >
      <thead className="table-secondary">
        <tr>
          <th className="p-2 align-middle">Name</th>
          <th className="p-2 align-middle">Address</th>
          <th className="p-2 align-middle">Phone Number</th>
          <th className="p-2 align-middle text-end">Status</th>
          <th className="p-2 align-middle text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        {dataRent.map((data, i) => {
          return (
            <tr key={i}>
              <td className="p-2 align-middle">{data?.name}</td>
              <td className="p-2 align-middle">{data?.address}</td>
              <td className="p-2 align-middle">{data?.phone}</td>
              <td className="p-2 align-middle text-end">
                <ActiveInActive text={data.status ? 'active' : 'inactive'} />
              </td>
              <td className="p-2 align-middle text-end">
                <button
                  className="btn btn-secondary ml-2"
                  onClick={() => handleEdit(data)}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ fontSize: '15px', rotate: '90deg' }}
                    className="fa-flip-horizontal"
                  />
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} className="p-3">
            <div className="d-flex">
              <span className="mt-2 mr-2">Show</span>
              <select
                name="per_page"
                className="form-select"
                aria-label="show-page"
                style={{ width: '13%' }}
                value={meta.per_page}
                onChange={(e) => handleOnChangeMeta(e)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="mt-2 ml-2">
                Entries | {meta.current_page} to {meta.per_page} of{' '}
                {meta.total_row} entries
              </span>
            </div>
          </td>
          <td colSpan={2} className="p-3">
            <div className="d-flex justify-content-end">
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
                page={meta.current_page}
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default RentTable
