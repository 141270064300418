import { PDFDownloadLink } from '@react-pdf/renderer'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'react-toggle/style.css'
import CustomButton from '../../../components/Button/CustomButton'
import '../../../styles/style.css'
import Pdf from '../../Download/Pdf'

function ModalTypeDownloadRack(props) {
  const [typeValue, setTypeValue] = useState('slot')
  const [showDownloadPdf, setShowDownloadPdf] = useState(false)
  const [nameFile, setNameFile] = useState('print_qr_beam.pdf')
  const shardingItems = () => {
    const data = props.slots.reduce((acc, item) => {
      acc.push({
        id: item.id,
        name: item.code,
        qr: item.qr
      })
      return acc
    }, [])
    const shardedData = []

    for (let i = 0; i < data.length; i += 2) {
      shardedData.push(data.slice(i, i + 2))
    }
    return shardedData
  }
  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Print All QR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-2">
          <div
            className="form-check d-flex flex-row gap-3 mb-3"
            onChange={() => {
              setTypeValue('beam')
              setNameFile('print_qr_beam.pdf')
            }}
          >
            <input
              className="staging-area form-check-input"
              type="radio"
              name="type"
              id="Beam"
              checked={typeValue === 'beam'}
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <label
                className="form-check-label fs-6 mt-1 fw-bold"
                htmlFor="Beam"
              >
                Beam
              </label>
            </div>
          </div>
          <div
            className="form-check d-flex flex-row gap-3"
            onChange={() => {
              setTypeValue('slot')
              setNameFile('print_qr_slot.pdf')
            }}
          >
            <input
              className="staging-area form-check-input"
              type="radio"
              name="type"
              id="Slot"
              checked={typeValue === 'slot'}
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
              <label
                className="form-check-label fs-6 mt-1 fw-bold"
                htmlFor="Slot"
              >
                Slot
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-3">
        <div className="d-flex w-100 justify-content-end flex-row">
          {typeValue === '' && (
            <CustomButton
              variant={'secondary'}
              text={'Print'}
              disabled={true}
            />
          )}
          {typeValue !== '' && (
            // <PDFDownloadLink
            //   document={<Pdf data={shardingItems()} typeDownload={typeValue} />}
            //   fileName={nameFile}
            //   onClick={() => {
            //     setShowDownloadPdf(true)
            //     setTimeout(() => {
            //       setShowDownloadPdf(false)
            //       props.onHide()
            //     }, 3000)
            //   }}
            // >
            //   {({ loading }) => (
            //     <CustomButton
            //       variant={typeValue === '' ? 'secondary' : 'warning'}
            //       text={showDownloadPdf ? 'Download' : 'Print'}
            //       disabled={typeValue === '' ? true : false}
            //     />
            //   )}
            // </PDFDownloadLink>
            <PDFDownloadLink
              document={<Pdf data={shardingItems()} typeDownload={typeValue} />}
              fileName={nameFile}
              onClick={() => {
                setShowDownloadPdf(true)
              }}
            >
              {({ loading }) => {
                if (!loading && showDownloadPdf) {
                  setTimeout(() => {
                    setShowDownloadPdf(false)
                    props.onHide()
                  }, 1000) // You can adjust the delay as needed
                }

                return (
                  <CustomButton
                    variant={typeValue === '' ? 'secondary' : 'warning'}
                    text={
                      loading && !showDownloadPdf
                        ? 'Preparing...'
                        : showDownloadPdf
                        ? 'Download'
                        : 'Print'
                    }
                    disabled={typeValue === '' ? true : false}
                  />
                )
              }}
            </PDFDownloadLink>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalTypeDownloadRack
