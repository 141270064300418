import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Table, Badge } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const btnSecondary = {
  backgroundColor: '#ffffff',
  borderColor: '#ffffff',
  color: 'white'
}

const DeliveryHistory = () => {
  const [deliveryList, setDeliveryList] = useState([])
  const [plateNo, setPlateNo] = useState([])
  const [filter, setFilter] = useState({
    plate_no: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add('14', 'days').format('YYYY-MM-DD')
  })
  const navigate = useNavigate()

  const navigateToDetail = (data) => {
    const encode = encodeURIComponent(data.outbound_id)
    navigate(`/outbound/delivery-history/detail/${encode}`)
  }

  const getList = async () => {
    try {
      const response = await api.get(
        `outbound/delivery_history/list?start_delivery_date=${filter.startDate}&end_delivery_date=${filter.endDate}&plate_no=${filter.plate_no}`
      )
      console.log(response.data.data)
      setDeliveryList(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getPlateNo = async () => {
    try {
      const response = await api.get('master/vehicles/all')
      console.log(response.data.data)
      setPlateNo(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnCHange = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    setFilter((prev) => ({ ...prev, [name]: value }))
  }

  const handleDisable = () => {
    if (moment(filter.startDate) > moment(filter.endDate)) {
      return true
    }
    return false
  }

  useEffect(() => {
    getList()
    getPlateNo()
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Delivery History</h1>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Form.Label
            className="fw-bold text-secondary text-uppercase"
            style={titleStyle}
          >
            <small>plate no.</small>
          </Form.Label>
          <Form.Select
            name="plate_no"
            value={filter.plate_no}
            onChange={(e) => handleOnCHange(e)}
          >
            <option value="">All</option>
            {plateNo.map((data) => {
              return (
                <option value={data.plate_no} key={data.id}>
                  {data.plate_no}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col md={4} className="d-flex gap-2">
          <div className="w-50">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>Delivery Date</small>
            </Form.Label>
            <Form.Control
              type="date"
              value={filter.startDate}
              format="yyyy-mm-dd"
              name="startDate"
              onChange={(e) => handleOnCHange(e)}
            />
          </div>
          <h1 style={{ marginTop: '18px' }} className="text-secondary">
            -
          </h1>
          <div className="w-50">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={(titleStyle, { marginTop: '15px' })}
            >
              <small></small>
            </Form.Label>
            <Form.Control
              type="date"
              value={filter.endDate}
              name="endDate"
              onChange={(e) => handleOnCHange(e)}
            />
          </div>
        </Col>
        <Col md={2} className="d-flex align-items-center">
          <button
            style={btnOrange}
            className={`btn mt-4 ${handleDisable() ? 'cursor-disabled' : ''}`}
            onClick={() => getList()}
            disabled={handleDisable()}
          >
            Search
          </button>
        </Col>
      </Row>
      <Row className="mb-3 mt-2">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            hover
            size="m"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">Plate No.</th>
                <th className="p-2 align-middle">Vehicle Type</th>
                <th className="p-2 align-middle">Driver</th>
                <th className="p-2 align-middle">Capacity(Kg)</th>
                <th className="p-2 align-middle">
                  MAX Volume(cm<sup>3</sup>)
                </th>
                <th className="p-2 align-middle">
                  Total Volume Picked(cm<sup>3</sup>)
                </th>
                <th className="p-2 align-middle">#Outbound</th>
                <th className="p-2 align-middle">Delivery Date</th>
                <th className="p-2 align-middle">Delivery Status</th>
                <th className="p-2 align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              {deliveryList.map((item) => {
                return (
                  <tr key={item.outbound_id}>
                    <td className="p-2 align-middle">{item.plate_no}</td>
                    <td className="p-2 align-middle">{item.vehicle_type}</td>
                    <td className="p-2 align-middle">{item.driver}</td>
                    <td className="p-2 align-middle">{item.capacity}</td>
                    <td className="p-2 align-middle">{item.max_volume}</td>
                    <td className="p-2 align-middle">
                      {item.total_volume_picked}
                    </td>
                    <td className="p-2 align-middle">{item.outbound}</td>
                    <td className="p-2 align-middle">{item.delivery_date}</td>
                    <td className="p-2 align-middle">
                      {item.delivery_status === 'done' ? (
                        <Badge pill bg="success">
                          {item.delivery_status}
                        </Badge>
                      ) : (
                        <Badge pill bg="primary">
                          {item.delivery_status}
                        </Badge>
                      )}
                    </td>
                    <td className="p-2 align-middle">
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        onClick={() => navigateToDetail(item)}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default DeliveryHistory
