import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

const TableItem = ({ detail }) => {
  return (
    <Row>
      <Col md={12} className="mb-3">
        <h5 className="fw-bold">ITEM RECEIPT DETAILS</h5>
      </Col>
      <Col md={12} className="">
        <Table
          hover
          responsive="sm"
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '12px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-3 align-middle" style={{ width: '30%' }}>
                Item
              </th>
              <th className="p-3 align-middle">Batch</th>
              <th className="p-3 text-end align-middle">DO Pack</th>
              <th className="p-3 text-end align-middle">DO Qty</th>
              <th className="p-3 text-end align-middle">Accepted Pack</th>
              <th className="p-3 text-end align-middle">Accepted Qty</th>
              <th className="p-3 text-end align-middle">Quarantine Pack</th>
              <th className="p-3 text-end align-middle">Quarantine Qty</th>
              <th className="p-3 text-end align-middle">Rejected Pack</th>
              <th className="p-3 text-end align-middle">Rejected Qty</th>
            </tr>
          </thead>
          <tbody>
            {detail && !detail.receipt_detail && (
              <tr>
                <td
                  className="p-3 align-middle fw-bold text-center"
                  colSpan={10}
                >
                  No data
                </td>
              </tr>
            )}
            {detail && detail.receipt_detail && (
              <tr>
                <td className="p-3 align-middle">
                  {detail?.receipt_detail?.item}
                </td>
                <td className="p-3 align-middle">
                  {detail?.receipt_detail?.any_value}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.do_pack}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.do_qty}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.acc_pack}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.acc_qty}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.quarantine_pack}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.quarantine_qty}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.damaged_pack}
                </td>
                <td className="p-3 text-end align-middle">
                  {detail?.receipt_detail?.damaged_qty}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default TableItem
