import React, { useState, useEffect, useRef } from 'react'
import { Col, Container, Row, Form, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { useNavigate, useParams } from 'react-router-dom'
import CustomButton from '../../../components/Button/CustomButton'
import Swal from 'sweetalert2'
import CardInfoCreatedUpdated from '../../Inbound/PurchaseReceipt/createdby'
import ModalUpdateQtyDc from '../../../components/modal/dc/ModalUpdateQtyDc'
import RowEmpty from '../../../components/atoms/RowEmpty'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'

function Detail() {
  const navigate = useNavigate()
  const [modalUpdateItem, setModalUpdateItem] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [detail, setDetail] = useState({})
  const fileInputDeliveryProof = useRef(null)
  const [image, setImage] = useState(null)
  const [preview, setPreview] = useState(null)
  const [isImage, setIsImage] = useState(false)
  const [items, setItems] = useState([])
  const [proof, setProof] = useState([])

  const { id } = useParams()

  useEffect(() => {
    loadData(id)
  }, [id])


  const loadData = async (id) => {
    try {
      const res = await api.get(`outbound/dcdrafts/detail/${id}`)
      const { data } = res.data
      setDetail(data)
      setItems(data?.items)
      setProof(data?.delivery_proof)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0]
      const res = await api.postForm(
        `outbound/dcdrafts/upload-proof/${detail?.dc_id}`, { 
          proof: file
        }
      )
      if(res.data.success){ 
        Common.showPopUpSuccessMessage(res.data.message)
        loadData(id)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Send to ERP?',
      text: `This action can't be repeated!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Send'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await api.postForm(`/outbound/dcdrafts//${id}/send-erp`)
          if (res.data.success) {
            Common.showPopUpSuccessMessage(res.data.message)
            navigate('/inbound/purchase-receipt')
          }
        } catch (err) {
          const errMsg =
            err?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const handleUpdateItem = (data) => { 
    setModalUpdateItem(true)
    setSelectedItem(data)
  }

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <Breadcrumb
              label={detail.wms_dc_no ?? '-'}
              link="/outbound/dc"
              back="Delivery Confirmation"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Form onSubmit={handleSubmit}>
            <div>
              <Row className="mb-5">
                <Label
                  label="customer"
                  value={detail.customer ?? '-'}
                  col={4}
                />
                <Label
                  label="ERP SJ No."
                  value={detail.erp_sj_no ?? '-'}
                  col={4}
                />
                <Label label="#so" value={detail.so ?? '-'} col={4} />
                <Label label="source" value={detail.source ?? '-'} col={4} />
                <Label
                  label="delivery type"
                  value={detail.delivery_type ?? '-'}
                  col={4}
                />
                <Label label="driver" value={detail.driver ?? '-'} col={4} />
                <Label
                  label="delivery date"
                  value={detail.delivery_date ?? '-'}
                  col={4}
                />
                <Label label="erp dc date" value={detail.date ?? '-'} col={4} />
              </Row>
              <Row>
                <Col md={12}>
                  <Table
                    hover
                    responsive="sm"
                    className="shadow-sm mb-5 bg-white rounded"
                    size="sm"
                    style={{ fontSize: '12px' }}
                  >
                    <thead className="table-secondary">
                      <tr>
                        <th className="p-2 align-middle">No</th>
                        <th className="p-2 align-middle">Item</th>
                        <th className="p-2 text-end align-middle">Batch</th>
                        <th className="p-2 text-end align-middle">Total Qty</th>
                        <th className="p-2 text-end align-middle">
                          Confirmed Qty
                        </th>
                        <th className="p-2 text-end align-middle">
                          Difference
                        </th>
                        <th className="p-2 text-end align-middle">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.length > 0 ? (
                        items.map((detail, index) => (
                          <tr key={index}>
                            <td className="p-2 align-middle">{index + 1}</td>
                            <td className="p-2 align-middle">
                              {detail?.item ?? '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {detail?.batch ?? '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {detail?.total_qty ?? '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {detail?.confirmed_qty ?? '-'}
                            </td>
                            <td className="p-2 text-end align-middle bg-secondary text-white">
                              {detail?.difference_qty ?? '0'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              <button
                                type="button"
                                className="btn btn-secondary ml-2"
                                onClick={() => handleUpdateItem(detail)}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <RowEmpty colSpan={7} />
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col md={12}>
                  <h5 className="text-secondary mb-3">DELIVERY PROOF</h5>
                </Col>
                {proof.length > 0 && (
                  proof.map((image, i) => (
                    <Col md={1} key={i}>
                      <img
                        src={image}
                        alt="Preview"
                        style={{
                          border: '2px solid #dee2e6',
                          borderRadius: '10px',
                          width: '100px',
                          height: '100px',
                          marginBottom: '15px',
                        }}
                      />
                    </Col>
                )))}
                <Col md={2}>
                  <div
                    style={{
                      border: '2px solid #dee2e6',
                      borderRadius: '10px',
                      width: '100px',
                      height: '100px',
                      padding: '30px',
                      cursor: 'pointer'
                    }}
                  >
                    <label
                      htmlFor="file"
                      onClick={() => fileInputDeliveryProof.current.click()}
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        style={{ marginLeft: '10px' }}
                        className="text-secondary"
                      />
                      <small
                        style={{ marginLeft: '5px' }}
                        className="text-secondary"
                      >
                        Foto
                      </small>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInputDeliveryProof}
                      hidden={true}
                      accept="image/*"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* BUTTON */}
            <Row className="shadow-sm mb-5 bg-white rounded p-2">
              <Col>
                <small className="text-danger">Cancel</small>
              </Col>
              <Col className="text-end mb-3">
                <CustomButton
                  onClick={handleSubmit}
                  variant={
                    detail?.po_info?.draft_status !== 'done'
                      ? 'secondary'
                      : 'warning'
                  }
                  disabled={
                    detail?.po_info?.draft_status !== 'done' ? true : false
                  }
                  text={'Send to ERP'}
                />
              </Col>
            </Row>
            {/* CREATED AT UPDATED AT */}
            <CardInfoCreatedUpdated detail={detail} />
          </Form>
        </Row>
      </Container>

      <ModalUpdateQtyDc
        show={modalUpdateItem}
        onHide={() => setModalUpdateItem(false)}
        selectedItem={selectedItem}
        refreshdata={() => loadData(id)}
      />
    </>
  )
}

export default Detail
