import React, { useContext, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import '../../styles/style.css'
import NavbarMover from './NavbarMover'

const DashboardMover = () => {
  const [state] = useContext(UserContext)
  const { pathname } = useLocation()
  const [dataFilter, setDataFilter] = useState(null)
  const [assignMover, setAssignMover] = useState(null)

  const handleGetDataFilter = (value) => {
    setDataFilter(value)
  }

  const handleGetAssignMover = (value) => {
    setAssignMover(value)
  }

  const containerClass =
    pathname === '/mover/detail/'
      ? 'p-0 min-vh-100 half-background-detail-mover'
      : 'p-0 min-vh-100'

  return (
    <Container fluid className={containerClass}>
      <div style={{ overflowX: 'hidden' }}>
        <Row>
          <Col>
            <Row>
              <NavbarMover
                user={state.username}
                onHandleGetDataFilter={handleGetDataFilter}
                onHandleGetAssignMover={handleGetAssignMover}
              />
            </Row>
            <Row className="mt-4 ms-2 me-2">
              <Outlet
                context={{
                  filterValue: dataFilter,
                  assignMoverValue: assignMover
                }}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default DashboardMover
