import React from 'react'
import { Modal, Form, Row, Col } from 'react-bootstrap'
import { Formik } from 'formik'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomCloseButton from '../../Button/CustomCloseButton'
import CustomButton from '../../Button/CustomButton'
import { deliveryConfirmationUpdateItem } from '../../../utils/validationSchema'
import TextField from '../../Input/TextInput'

function ModalUpdateQtyDc({ selectedItem, onHide, refreshdata, ...props }) {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const response = await api.put(
      `/outbound/dcdrafts/update-difference/${selectedItem?.item_id}`,
      values
    )

    if (response?.status === 200) {
      Common.showPopUpSuccessMessage(response?.data?.message)
      onHide()
      refreshdata()
      resetForm()
    }else { 
      Common.showPopUpSuccessMessage(response?.data?.message)
    }
    // try {
    // } catch (err) {
    //   const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
    //   Common.showPopUpErrorMessage(errMsg)
    // } finally {
    //   setSubmitting(false)
    // }
  }

  return (
    <Modal {...props} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Input Confirmed Pack Qty</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            !selectedItem
              ? { qty: 0 }
              : { qty: selectedItem.difference_qty }
          }
          validationSchema={deliveryConfirmationUpdateItem}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty,
            isSubmitting
          }) => (
            <Form className="d-grid gap-2" onSubmit={handleSubmit}>
              <Row>
                <Col md={12} className="mb-3">
                  <h5>{selectedItem.item}</h5>
                </Col>
                <Col md={12}>
                  <TextField
                    label="pack qty"
                    name="qty"
                    value={values.qty}
                    placeholder="0"
                    type="number"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    required={true}
                  />
                </Col>
              </Row>
              <Modal.Footer className="mt-3">
                <CustomCloseButton onClick={onHide} />
                <CustomButton
                  onClick={handleSubmit}
                  variant="warning"
                  disabled={!isValid || !dirty || isSubmitting}
                  text="Save"
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateQtyDc
