import { Formik } from 'formik'
import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import 'react-toggle/style.css'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { CreateMasterPalet } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalAddPallete(props) {
  const handleSubmit = async (values) => {
    try {
      const response = await api.post('/master/palets', values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Palet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            palet_id: ''
          }}
          validationSchema={CreateMasterPalet}
          onSubmit={(values) => {
            // add data warehouse id from props
            values.warehouse_id = props.data
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <TextField
                      label="id"
                      name="palet_id"
                      value={values.palet_id}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                      placeholder="Input"
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Add'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddPallete
