import { createContext, useReducer } from 'react'

export const UserContext = createContext()

const initialState = {
  login: localStorage.getItem('token') !== null,
  username: localStorage.getItem('username')
    ? JSON.parse(localStorage.getItem('username'))
    : null,
  showLoginPopup: false
}

const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'success':
    case 'login':
      localStorage.setItem('token', payload.token)
      localStorage.setItem('username', JSON.stringify(payload))
      return {
        ...state,
        login: true,
        username: payload
      }
    case 'failed':
    case 'logout':
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      return {
        ...state,
        login: false,
        username: null
      }

    //MODAL
    /* falls through */
    case 'showLoginPopup':
      return {
        ...state,
        showLoginPopup: true
      }
    /* falls through */
    case 'hideLoginPopup':
      return {
        ...state,
        showLoginPopup: false
      }

    /* falls through */
    default:
      throw new Error()
  }
}

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  )
}
