import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ModalAddDriver from '../../../components/modal/Outbound/ModalAddDriver'
import ModalAddVehicle from '../../../components/modal/Outbound/ModalAddVehicle'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { formatThousandSeparator } from '../../../utils/TextFormatter'
// Style constants
const styles = {
  headerTitle: {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  },
  labelForm: {
    fontSize: '12px'
  }
}

// Main component
const CreateDetailOutboundPlan = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const fileUploadSim = useRef(null)
  const fileUploadMemo = useRef(null)
  const fileUploadStnk = useRef(null)
  const [fileNameSim, setFileNameSim] = useState('')
  const [fileNameMemo, setFileNameMemo] = useState('')
  const [fileNameStnk, setFileNameStnk] = useState('')
  const [fieldSim, setFieldSim] = useState('')
  const [fieldMemo, setFieldMemo] = useState('')
  const [fieldStnk, setFieldStnk] = useState('')
  const [showModalAddDriver, setShowModalAddDriver] = useState(false)
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [selectedDriver, setSelectedDriver] = useState({ id: '', name: '' })
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState({
    id: '',
    vehicle: ''
  })
  const [changeSelectDriver, setChangeSelectDriver] = useState(null)
  const [changeSelectVehicle, setChangeSelectVehicle] = useState(null)
  const [itemCommands, setItemCommands] = useState(state?.selectedSj)
  const [submitButton, setSubmitButton] = useState(false)

  const handleSelectDriver = () => {
    if (changeSelectDriver) {
      setShowModalAddDriver(false)
      const selected = drivers.find(
        (driver) => driver.id === changeSelectDriver
      )
      setSelectedDriver({ id: selected.id, name: selected.name })
      setChangeSelectDriver(null)
    }
  }

  const handleSelectVehicle = () => {
    if (changeSelectVehicle) {
      setShowModalAddVehicle(false)
      const selected = vehicles.find(
        (vehicle) => vehicle.id === changeSelectVehicle
      )
      setSelectedVehicle({
        id: selected.id,
        vehicle: `${selected.plate_no} - ${
          selected.vehicle_type
        } - ${formatThousandSeparator(
          selected.capacity
        )} Kg - ${formatThousandSeparator(selected.volume)} cm3`
      })
      setChangeSelectVehicle(null)
    }
  }

  const handleRemoveSj = (sj) => {
    Swal.fire({
      title: 'Remove SJ draft?',
      text: 'This action can’t be undone',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#C12727',
      cancelButtonColor: 'transparent',
      confirmButtonText: 'Remove',
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-remove-outbound'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const updateItemCommand = itemCommands.filter(
          (itemCommand) => itemCommand.sj !== sj
        )
        setItemCommands(updateItemCommand)
        // when item command length equal 0 redirect to url create sj
        if (updateItemCommand.length === 0) {
          navigate('/outbound/plan/create', {
            state: {
              selectedSj: state?.selectedSj,
              filterData: state?.filterData
            }
          })
        }
      }
    })
  }

  const handleCancelOutboundPlan = () => {
    Swal.fire({
      title: 'Cancel Creating Ticket?',
      text: 'Draft will be discarded',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#C12727',
      cancelButtonColor: 'transparent',
      confirmButtonText: 'Discard',
      cancelButtonText: 'Continue creating',
      customClass: {
        title: 'swal-title-outbound',
        popup: 'swal-popup-outbound',
        actions: 'swal-remove-outbound'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/outbound/plan', { state: null })
      }
    })
  }

  const calculateTotalQty = (type) => {
    let qtyMap = []
    if (itemCommands?.length > 0) {
      itemCommands?.map((sjValue) => {
        sjValue.pickup_command.forEach((command) => {
          if (type === 'qty') {
            const { qty, uom } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty, uom })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom
                    ? { ...value, qty: (value.qty += qty) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty, uom })
              }
            }
          }
          if (type === 'pack') {
            const { total_pack, uom_pack } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty: total_pack, uom: uom_pack })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom_pack
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom_pack
                    ? { ...value, qty: (value.qty += total_pack) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty: total_pack, uom: uom_pack })
              }
            }
          }
        })
      })
      const totalQty = qtyMap
        .map((data) => `${data.qty} ${data.uom}`)
        .join(' + ')
      return totalQty
    }
    return 0
  }

  const calculateTotalVolume = () => {
    if (itemCommands?.length > 0) {
      let tmpVolume = []
      itemCommands?.map((sjValue) => {
        sjValue.pickup_command.map((pickupCommand) => {
          tmpVolume.push(pickupCommand.qty * pickupCommand.volume_item)
        })
      })

      if (tmpVolume.length > 0) {
        const totalVolume = tmpVolume.reduce((acc, volume) => {
          return (acc += volume ?? 0)
        }, 0)
        return totalVolume
      }
    }
    return 0
  }
  const handleCreateOutboundPlan = async () => {
    try {
      setSubmitButton(true)
      const res = await api.post('/outbound/outbound_plan/create', itemCommands)
      if (res.status === 200) {
        navigate('/outbound/plan', {
          state: { success: true, message: 'Outbond created!' }
        })
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setSubmitButton(false)
    }
  }
  // const handleCreateOutboundPlan = async () => {
  //   try {
  //     let values = {}
  //     setSubmitButton(true)
  //     if (state?.filterData.delivery_type === 'pick up') {
  //       values = {
  //         warehouse_id: state?.filterData.warehouse_id,
  //         delivery_date: moment(state?.filterData.delivery_date).format(
  //           'YYYY-MM-DD'
  //         ),
  //         delivery_type: state?.filterData.delivery_type,
  //         outbound_type:
  //           state?.filterData.type === 'pick_up'
  //             ? 'pick up'
  //             : state?.filterData.type,
  //         driver_id: '',
  //         user_id: '',
  //         vehicle_id: '',
  //         docs: {
  //           memo: fieldMemo,
  //           sim: fieldSim,
  //           stnk: fieldStnk
  //         },
  //         sj_drafts: itemCommands.reduce((acc, itemCommand) => {
  //           acc.push(itemCommand.id)
  //           return acc
  //         }, [])
  //       }
  //     } else {
  //       values = {
  //         warehouse_id: state?.filterData.warehouse_id,
  //         delivery_date: moment(state?.filterData.delivery_date).format(
  //           'YYYY-MM-DD'
  //         ),
  //         delivery_type: state?.filterData.delivery_type,
  //         outbound_type:
  //           state?.filterData.type === 'pick_up'
  //             ? 'pick up'
  //             : state?.filterData.type,
  //         driver_id:
  //           state?.filterData.delivery_type === 'sewa' ? selectedDriver.id : '',
  //         user_id:
  //           state?.filterData.delivery_type === 'internal'
  //             ? selectedDriver.id
  //             : '',
  //         vehicle_id: selectedVehicle.id,
  //         sj_drafts: itemCommands.reduce((acc, itemCommand) => {
  //           acc.push(itemCommand.id)
  //           return acc
  //         }, [])
  //       }
  //     }
  //     const res = await api.postForm('/outbound/outbound_plan/create', values)
  //     if (res.status === 200) {
  //       navigate('/outbound/plan', {
  //         state: { success: true, message: 'Outbond created!' }
  //       })
  //     }
  //   } catch (err) {
  //     const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
  //     Common.showPopUpErrorMessage(errMsg)
  //   } finally {
  //     setSubmitButton(false)
  //   }
  // }
  const propSubmitOutboundPlan = () => {
    // check item same or not
    let valueCompareItemSameLength = true
    if (!submitButton) {
      for (const itemCommand of itemCommands) {
        // override object distict
        const itemPickupCommandLength = [
          ...new Set(itemCommand.pickup_command.map((item) => item.item_name))
        ].length
        if (itemCommand.items.length !== itemPickupCommandLength) {
          valueCompareItemSameLength = false
          break
        }
      }
    }

    if (submitButton) {
      return {
        variant: 'secondary',
        disabled: true,
        value: 'Loading...',
        as: 'input',
        type: 'button'
      }
    }
    return {
      variant: valueCompareItemSameLength ? 'transparent' : 'secondary',
      className: valueCompareItemSameLength ? 'btn-primary-orange' : '',
      disabled: valueCompareItemSameLength ? false : true,
      value: 'Submit',
      as: 'input',
      type: 'button',
      onClick: () => handleCreateOutboundPlan()
    }
  }
  // const propSubmitOutboundPlan = () => {
  //   if (state?.filterData.delivery_type === 'pick up') {
  //     if (fileNameSim === '' || fileNameMemo === '' || fileNameStnk === '') {
  //       return {
  //         variant: 'secondary',
  //         disabled: true,
  //         value: 'Submit',
  //         as: 'input',
  //         type: 'button'
  //       }
  //     }
  //     if (submitButton) {
  //       return {
  //         variant: 'secondary',
  //         disabled: true,
  //         value: 'Loading...',
  //         as: 'input',
  //         type: 'button'
  //       }
  //     }
  //     return {
  //       variant: 'transparent',
  //       className: 'btn-primary-orange',
  //       value: 'Submit',
  //       as: 'input',
  //       type: 'button',
  //       onClick: () => handleCreateOutboundPlan()
  //     }
  //   } else {
  //     if (submitButton) {
  //       return {
  //         variant: 'secondary',
  //         disabled: true,
  //         value: 'Loading...',
  //         as: 'input',
  //         type: 'button'
  //       }
  //     }
  //     return {
  //       variant: 'transparent',
  //       className: 'btn-primary-orange',
  //       value: 'Submit',
  //       as: 'input',
  //       type: 'button',
  //       onClick: () => handleCreateOutboundPlan()
  //     }
  //   }
  // }

  return (
    <>
      <div className="container mb-3">
        <div className="row">
          <div className="col">
            <h4 style={styles.headerTitle}>Create Outbound</h4>
            <Breadcrumb onClickCancel={handleCancelOutboundPlan} />
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <Form.Group className="mb-3 text-muted" controlId="sourcewarehouse">
              <Form.Label style={styles.labelForm}>SOURCE WAREHOUSE</Form.Label>
              <Form.Select aria-label="sourcewarehouse" disabled>
                <option value={state?.filterData.src}>
                  {state?.filterData.srcLabel}
                </option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-4">
            <Form.Group className="mb-3 text-muted" controlId="deliverydate">
              <Form.Label style={styles.labelForm}>DELIVERY DATE</Form.Label>
              <Form.Control
                type="date"
                value={moment(state?.filterData.delivery_date).format(
                  'YYYY-MM-DD'
                )}
                disabled
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div style={{ borderTop: '3px solid #F1F2F7' }} />
      <div className="row">
        <div className="col-3" style={{ marginLeft: '-.5rem' }}>
          <Table borderless className="mt-3">
            <tbody>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Qty
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {calculateTotalQty('qty')}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Pack
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {calculateTotalQty('pack')}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="fw-bold" width={'10%'}>
                  Vol
                </td>
                <td className="fw-bold" width={'5%'}>
                  :
                </td>
                <td>
                  <span style={{ color: '#F7931E' }} className="fw-bold">
                    {formatThousandSeparator(calculateTotalVolume())} cm3
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      {itemCommands.map((itemCommand, index) => (
        <ListDataSj
          key={itemCommand.id}
          data={itemCommand}
          number={index + 1}
          onHandleRemoveSj={handleRemoveSj}
        />
      ))}

      <div className="col-12 mb-5">
        <div className="row">
          <div className="col-3" style={{ marginLeft: '-1rem' }}>
            <button
              className="btn fw-bold"
              style={{ color: '#C12727' }}
              onClick={handleCancelOutboundPlan}
            >
              Cancel
            </button>
          </div>
          <div className="col-9" style={{ marginLeft: '1rem' }}>
            <div className="d-flex w-100 justify-content-end">
              <Button {...propSubmitOutboundPlan()} />
            </div>
          </div>
        </div>
      </div>

      <ModalAddDriver
        show={showModalAddDriver}
        drivers={drivers}
        onHide={() => {
          setShowModalAddDriver(false)
          setChangeSelectDriver(null)
        }}
        onHandleSelectDriver={handleSelectDriver}
        onHandleChangeSelectDriver={setChangeSelectDriver}
        dataChangeSelectDriver={changeSelectDriver}
      />
      <ModalAddVehicle
        show={showModalAddVehicle}
        vehicles={vehicles}
        onHide={() => {
          setShowModalAddVehicle(false)
          setChangeSelectVehicle(null)
        }}
        onHandleSelectVehicle={handleSelectVehicle}
        onHandleChangeSelectVehicle={setChangeSelectVehicle}
        dataChangeSelectVehicle={changeSelectVehicle}
        totalVolume={calculateTotalVolume()}
      />
    </>
  )
}

// Breadcrumb Component
const Breadcrumb = ({ onClickCancel }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb" style={{ fontSize: '12px', cursor: 'pointer' }}>
      <li className="breadcrumb-item sj-item" onClick={onClickCancel}>
        <span className="text-black fw-bold">OUTBOUND</span>
      </li>
      <li
        className="breadcrumb-item sj-item active text-uppercase"
        aria-current="page"
      >
        Create
      </li>
    </ol>
  </nav>
)

// DriverSelect Component
// const DriverSelect = ({ selectedDriver, setShowModalAddDriver }) => (
//   <Form.Group className="mb-3 text-muted" controlId="driver">
//     <Form.Label style={styles.labelForm}>DRIVER</Form.Label>
//     <Form.Select
//       aria-label="driver"
//       onClick={() => setShowModalAddDriver(true)}
//     >
//       <option value={selectedDriver.id}>
//         {selectedDriver.id === '' ? 'Select' : selectedDriver.name}
//       </option>
//     </Form.Select>
//   </Form.Group>
// )

// // VehicleSelect Component
// const VehicleSelect = ({ selectedVehicle, setShowModalAddVehicle }) => (
//   <Form.Group className="mb-3 text-muted" controlId="vehicle">
//     <Form.Label style={styles.labelForm}>VEHICLE</Form.Label>
//     <Form.Select
//       aria-label="vehicle"
//       onClick={() => setShowModalAddVehicle(true)}
//     >
//       <option value={selectedVehicle.id}>
//         {selectedVehicle.id === '' ? 'Select' : selectedVehicle.vehicle}
//       </option>
//     </Form.Select>
//   </Form.Group>
// )

// ListDataSj Component
const ListDataSj = ({ data, number, onHandleRemoveSj }) => (
  <>
    <div className="col-12 mt-3" key={number}>
      <div className="row">
        <div className="col-8">
          <p className="d-flex">
            <span className="fw-bold mr-2">
              {number}. {data.draft}
            </span>
          </p>
        </div>
        <div className="col-4">
          <div className="d-flex w-100 justify-content-end">
            <button
              className="btn btn-danger btn-sm d-flex flex-row gap-2"
              onClick={() => onHandleRemoveSj(data.sj)}
            >
              <span>REMOVE</span>
              <FontAwesomeIcon
                icon={faCircleMinus}
                style={{ color: 'white' }}
                className="ml-2 mt-1"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12">
      <div className="col-4">
        <p>{data.customer}</p>
      </div>
    </div>
    <div className="col-12">
      <div className="col-6">
        <div dangerouslySetInnerHTML={{ __html: data.delivery_address }}></div>
      </div>
    </div>
    <div className="col-12 mt-3">
      <h6 className="fw-bold text-muted">PICK UP COMMAND</h6>
    </div>
    <div className="col-12 mt-3 pe-2">
      <Table
        hover
        responsive="sm"
        className="shadow-sm mb-5 bg-white rounded"
        size="sm"
        style={{ fontSize: '14px' }}
      >
        <thead
          className="table-secondary"
          style={{ borderColor: 'transparent' }}
        >
          <tr>
            <th className="p-2 align-middle">No</th>
            <th className="p-2 align-middle">Item</th>
            <th className="p-2 align-middle">Item Alias</th>
            <th className="p-2 align-middle">Item Code</th>
            <th className="p-2 align-middle">Batch</th>
            <th className="p-2 align-middle">Expired</th>
            <th className="p-2 align-middle text-center">Total Qty</th>
            <th className="p-2 align-middle text-end">Total Pack</th>
          </tr>
        </thead>
        <tbody>
          {data.pickup_command.length === 0 && (
            <tr>
              <td colSpan={8}>
                <p className="fw-bold text-center">Item Empty</p>
              </td>
            </tr>
          )}
          {data.pickup_command.length > 0 &&
            data.pickup_command.map((item, index) => (
              <ListPickupCommand data={item} number={index + 1} key={index} />
            ))}
        </tbody>
      </Table>
    </div>
  </>
)

// ListPickupCommand Component
const ListPickupCommand = ({ data, number }) => (
  <tr key={number}>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {number}
    </td>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.item_name}
    </td>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.item_alias ?? '-'}
    </td>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.item_code}
    </td>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.batch}
    </td>
    <td
      className="p-2 align-middle"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.expired ? moment(data.expired).format('DD-MM-YYYY') : '-'}
    </td>
    <td
      className="p-2 align-middle text-center"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.qty} {data.uom}
    </td>
    <td
      className="p-2 align-middle text-end"
      style={{ backgroundColor: data?.item_alias ? '#FF8F8F' : '' }}
    >
      {data.total_pack}
    </td>
  </tr>
)

export default CreateDetailOutboundPlan
