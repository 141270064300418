import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'react-bootstrap'
import '../../../styles/style.css'
function ModalActionRemove(props) {
  const handleRemove = () => {
    props.onHandleRemovePickupCommands(props.pickupCommandId)
    props.onHide()
  }
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-5">
          <div className="text-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: 'grey', fontSize: '100px' }}
              className="ml-2"
            />
          </div>
          <div className="text-center mt-3">
            <h5>Remove Item?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleRemove()}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalActionRemove
