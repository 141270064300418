import { faFileUpload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import { BadgeStatusSj } from '../../../components/atoms/StatusLabel'
import ModalUploadSupportingDocument from '../../../components/modal/InboundHistory/ModalUploadSupportingDocument'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { formatDateDayMonthYear } from '../../../utils/FormatDate'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}
const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const InboundHistory = () => {
  const [inboundHistories, setInboundHistories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [
    showModalUploadDocumentSupporting,
    setShowModalUploadDocumentSupporting
  ] = useState(false)
  const [valueInboundId, setValueInboundId] = useState(null)
  const [pageFilter, setPageFilter] = useState(10)
  const [filterData, setFilterData] = useState({
    type: '',
    status: ''
  })
  const [metaData, setMetaData] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const { per_page, current_page } = metaData
  const [pageCount, setPageCount] = useState(0)
  useEffect(() => {
    fetchListInboundHistories()
  }, [per_page, current_page])

  // event handler
  const handleChangeValueFilter = (e) => {
    const { name, value } = e.target
    setFilterData((filterData) => ({ ...filterData, [name]: value }))
  }

  const handleApplyFilter = () => {
    fetchListInboundHistories()
  }

  const handleOnChangeMeta = (e) => {
    setMetaData({
      ...metaData,
      [e.target.name]: e.target.value
    })
    setPageFilter(e.target.value)
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      current_page: selectedPage
    }))
    window.scrollTo(0, 0)
  }

  const handleCloseModalUploadDocument = () => {
    setShowModalUploadDocumentSupporting(false)
    setValueInboundId(null)
  }

  const handleShowModalModalUploadDocument = (id) => {
    setShowModalUploadDocumentSupporting(true)
    setValueInboundId(id)
  }

  // function fetch list inbound history
  const fetchListInboundHistories = async () => {
    try {
      setIsLoading(true)
      const response = await api.get('/inbound/history', {
        params: {
          per_page,
          page: current_page,
          type: filterData.type || '',
          status: filterData.status || ''
        }
      })
      const { metadata, data } = response.data
      setMetaData(metadata)
      setInboundHistories(data)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <h4 style={headerTitleStyle}>Inbound History</h4>
          </div>
        </div>
        <div className="row mt-3">
          <FilterData
            onHandleChangeValueFilter={handleChangeValueFilter}
            onHandleApplyFilter={handleApplyFilter}
          />
        </div>
        <div className="row mt-3">
          <TableData
            isLoading={isLoading}
            inboundHistories={inboundHistories}
            page={metaData}
            pageCount={pageCount}
            pageFilter={pageFilter}
            onHandleOnChangeMeta={handleOnChangeMeta}
            onHandlePageChange={handlePageChange}
            onHandleShowModalUploadDocument={handleShowModalModalUploadDocument}
          />
        </div>
      </div>
      <ModalUploadSupportingDocument
        show={showModalUploadDocumentSupporting}
        onHide={() => handleCloseModalUploadDocument()}
        valueInboundId={valueInboundId}
        onRefreshData={() => fetchListInboundHistories(per_page, current_page)}
      />
    </>
  )
}
function FilterData({ onHandleChangeValueFilter, onHandleApplyFilter }) {
  return (
    <>
      <div className="col-3">
        <Form.Group className="mb-3" controlId="group-type">
          <Form.Label className="fw-bold text-secondary" style={titleStyle}>
            TYPE
          </Form.Label>
          <Form.Select
            aria-label="type"
            name="type"
            onChange={(e) => onHandleChangeValueFilter(e)}
          >
            <option value="">All</option>
            <option value="PR">Purchase Receipt (PR)</option>
            <option value="Retur">Material Receipt (Retur)</option>
            <option value="ML">Receive at Warehouse (ML)</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-3">
        <Form.Group className="mb-3" controlId="group-status">
          <Form.Label className="fw-bold text-secondary" style={titleStyle}>
            STATUS
          </Form.Label>
          <Form.Select
            aria-label="status"
            name="status"
            onChange={(e) => onHandleChangeValueFilter(e)}
          >
            <option value="">All</option>
            <option value="success">Success</option>
            <option value="failed">Failed</option>
          </Form.Select>
        </Form.Group>
      </div>
      <div className="col-3">
        <div className="d-flex h-100 align-items-center mt-2">
          <button
            className="btn btn-primary-orange"
            onClick={() => onHandleApplyFilter()}
          >
            Apply Filter
          </button>
        </div>
      </div>
    </>
  )
}
function TableData({
  isLoading,
  inboundHistories,
  page,
  pageCount,
  pageFilter,
  onHandleOnChangeMeta,
  onHandlePageChange,
  onHandleShowModalUploadDocument
}) {
  const tableSecondarySj = {
    borderColor: 'transparent'
  }
  return (
    <>
      <Table
        hover
        responsive="sm"
        className="shadow-sm mb-5 bg-white rounded"
        size="sm"
        style={{ fontSize: '14px' }}
      >
        <thead className="table-secondary" style={tableSecondarySj}>
          <tr>
            <th className="p-2 align-middle">ERP Doc No.</th>
            <th className="p-2 align-middle">Source Doc No.</th>
            <th className="p-2 align-middle">Type</th>
            <th className="p-2 align-middle">Supporting Document</th>
            <th className="p-2 align-middle">Submit</th>
            <th className="p-2 align-middle">Status</th>
            <th className="p-2 text-center align-middle" width="15%">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <td colSpan={7} className="text-center">
              <Loader />
            </td>
          )}
          {!isLoading && inboundHistories.length === 0 && (
            <td colSpan={7} className="text-center fw-bold">
              No Data
            </td>
          )}
          {!isLoading &&
            inboundHistories.length > 0 &&
            inboundHistories.map((value) => (
              <RowData
                data={value}
                onHandleShowModalUploadDocument={
                  onHandleShowModalUploadDocument
                }
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} className="p-3">
              <div className="d-flex align-items-center">
                <span className="mr-2">Show</span>
                <select
                  name="per_page"
                  className="form-select"
                  aria-label="show-page"
                  style={{ width: '60px' }}
                  value={pageFilter}
                  onChange={(e) => onHandleOnChangeMeta(e)}
                >
                  {[10, 25, 50, 100].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span className="ml-2">
                  Entries | {page.current_page} to {page.per_page} of{' '}
                  {page.total_row} entries
                </span>
              </div>
            </td>
            <td colSpan={6} className="p-3">
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={pageCount}
                  onPageChange={onHandlePageChange}
                  page={page.current_page}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}

function RowData({ data, onHandleShowModalUploadDocument }) {
  const navigate = useNavigate()
  const fileName = data.support_doc ? data.support_doc.split('/').pop() : '-'
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle text-center">
        {data.pr_code_erp ?? '-'}
      </td>
      <td className="p-2 align-middle">{data.po_code}</td>
      <td className="p-2 align-middle">{data.type}</td>
      <td className="p-2 align-middle">
        {!data.support_doc && '-'}
        {data.support_doc && (
          <Link to={data.support_doc} className="text-decoration-none">
            {fileName}
          </Link>
        )}
      </td>
      <td className="p-2 align-middle">
        {data.submited_at ? formatDateDayMonthYear(data.submited_at, '-') : '-'}
      </td>
      <td className="p-2 align-middle">
        {!data.status ? (
          '-'
        ) : (
          <BadgeStatusSj
            text={data.status}
            bgColor={data.status === 'success' ? 'success' : 'danger'}
          />
        )}
      </td>
      <td className="text-center p-2 align-middle d-flex flex-row gap-2 justify-content-center">
        <button
          className="btn btn-secondary btn-sm"
          onClick={() =>
            onHandleShowModalUploadDocument({
              prId: data.pr_id,
              poCode: data.po_code
            })
          }
        >
          <FontAwesomeIcon icon={faFileUpload} className="fa-flip-horizontal" />
        </button>
        <button
          className="btn btn-secondary btn-sm ml-2"
          onClick={() =>
            navigate(`/inbound/history/detail/${data.pr_id}`, {
              state: { code: data.pr_id }
            })
          }
        >
          <FontAwesomeIcon icon={faSearch} className="fa-flip-horizontal" />
        </button>
      </td>
    </tr>
  )
}

export default InboundHistory
