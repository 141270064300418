import React from 'react'
import Button from 'react-bootstrap/Button'

const CustomCloseButton = ({ text = '', onClick }) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      variant="secondary"
      className="btn text-muted"
      style={{ backgroundColor: 'transparent', border: 'none' }}
    >
      {text ? text : 'Close'}
    </Button>
  )
}

export default CustomCloseButton
