import axios from 'axios'

const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://wms-be.staging.bahteraadijaya.com/api/'
const AUTH_HEADER = 'Authorization'

export const API = axios.create({
  baseURL: BASE_URL
})

export const setAuthToken = (token) => {
  try {
    if (token) {
      API.defaults.headers.common[AUTH_HEADER] = `Bearer ${token}`
    } else {
      delete API.defaults.headers.common[AUTH_HEADER]
    }
  } catch (error) {
    console.error('Failed to set authorization token:', error)
  }
}
