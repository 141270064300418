import { Formik } from 'formik'
import { Col, Form, Modal, ModalHeader, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { AreaAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalAddArea(props) {
  const handleSubmit = async (values) => {
    try {
      // add attribute warehouse id from props to values
      values.warehouse_id = props.warehouseId
      const res = await api.post('/master/area/add', values)
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <ModalHeader closeButton>
        <Modal.Title>Add Area</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <Formik
          initialValues={{
            code: '',
            area_name: ''
          }}
          validationSchema={AreaAddEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={4}>
                    <TextField
                      label="Code"
                      name="code"
                      value={values.code}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Enter Code"
                      required={true}
                    />
                  </Col>
                  <Col md={8}>
                    <TextField
                      label="Name"
                      name="area_name"
                      value={values.area_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Enter Name"
                      required={true}
                    />
                  </Col>
                </Row>
                <Modal.Footer>
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Add'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddArea
