import React, { useEffect, useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { Multiselect } from 'multiselect-react-dropdown'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomCloseButton from '../../Button/CustomCloseButton'

function ModalAddCategoryItem({
  code,
  lastcategory,
  name,
  onHide,
  refreshData,
  ...props
}) {
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await api.get('master/categories/all')
        setCategories(res.data.data)
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }

    fetchCategories()
  }, [])

  const handleSelect = (selectedList) => {
    const categoryIds = selectedList.map((item) => item.id)
    setSelectedCategory(categoryIds)
  }

  const handleSubmit = async () => {
    const body = { category_ids: selectedCategory }
    const params = name ? `?name=${name}` : ''
    const res = await api.post(`/master/items/${code}${params}`, body)
    if (res.data.success) {
      Common.showPopUpSuccessMessage(res.data.message)
      onHide()
      refreshData()
    } else {
      Common.showPopUpErrorMessage(res.data?.errors?.message)
      onHide()
      refreshData()
    }
  }

  return (
    <Modal {...props} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="d-grid gap-2">
          <Form.Label className="fw-normal">
            <Multiselect
              options={categories}
              displayValue="name"
              onSelect={handleSelect}
              selectedValues={lastcategory}
              onRemove={handleSelect}
            />
          </Form.Label>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomCloseButton text="Cancel" onClick={onHide} />
        <Button
          type="submit"
          variant="warning"
          className="text-white"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddCategoryItem
