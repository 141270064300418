import {
  faClose,
  faEye,
  faEyeSlash,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { DriverEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Inactive' }
]

function ModalEditDriver(props) {
  const [selectedData, setSelectedData] = useState({
    name: '',
    phone_number: '',
    address: '',
    username: '',
    password: '',
    status: '',
    sim_doc: '',
    ktp_doc: ''
  })

  const [loading, setLoading] = useState(true)

  const fileUploadSim = useRef(null)
  const fileUploadKtp = useRef(null)
  const [showPassword, setShowPassword] = useState(false)
  const [fileNameSim, setFileNameSim] = useState('')
  const [fileNameKtp, setFileNameKtp] = useState('')

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const loadData = async (id) => {
    setLoading(true)
    try {
      let response = null
      if (props.activeKey === 'internal') {
        response = await api.get(`master/driver/${id}`)
      } else {
        response = await api.get(`master/driver/external/${id}`)
      }
      setSelectedData(response.data.data)
      if (props.activeKey === 'external') {
        setFileNameKtp(
          response.data.data.ktp_doc
            ? response.data.data.ktp_doc.split('/').pop()
            : ''
        )
        setFileNameSim(
          response.data.data.sim_doc
            ? response.data.data.sim_doc.split('/').pop()
            : ''
        )
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (props.id !== 0) {
      loadData(props.id)
    }
  }, [props.id, props.show])

  const handleSubmit = async (values, actions) => {
    try {
      let response = null
      if (values.typeDriver === 'internal') {
        response = await api.post(`master/driver/${props.id}`, values)
      } else {
        const formData = new FormData()
        for (const key in values) {
          if (key === 'sim_doc' || key === 'ktp_doc') {
            continue
          }
          formData.append(key, values[key])
        }
        // override data file upload to key docs
        formData.append('docs[sim]', values.sim_doc)
        formData.append('docs[ktp]', values.ktp_doc)
        response = await api.postForm(
          `master/driver/external/${props.id}`,
          formData
        )
      }

      if (response.data.success) {
        props.onHide()
        props.refreshData()

        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <Formik
            initialValues={
              !selectedData
                ? {
                    name: '',
                    phone_number: '',
                    address: '',
                    username: '',
                    password: '',
                    status: ''
                  }
                : {
                    name: selectedData.name,
                    phone_number: selectedData?.phone_number ?? '',
                    address: selectedData?.address ?? '',
                    username: selectedData?.username ?? '',
                    password: selectedData?.password ?? '',
                    status: selectedData?.status ?? '',
                    sim_doc: selectedData?.sim_doc ?? '',
                    ktp_doc: selectedData?.ktp_doc ?? '',
                    typeDriver: props.activeKey
                  }
            }
            validationSchema={DriverEditSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              isValid,
              dirty,
              setFieldValue
            }) => {
              return (
                <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                  {props.activeKey === 'internal' && (
                    <>
                      <Row>
                        <Col md={6}>
                          <TextField
                            label="name"
                            name="name"
                            value={values.name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input Name"
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <TextField
                            label="phone number"
                            name="phone_number"
                            value={values.phone_number}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input Phone Number"
                            required={true}
                          />
                        </Col>
                        <Col md={12}>
                          <TextField
                            label="address"
                            name="address"
                            value={values.address}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input Address"
                            required={true}
                          />
                        </Col>
                        <Col md={12}>
                          <SelectInput
                            label="Status"
                            name="status"
                            id="status"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            options={statuses}
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <TextField
                            label="username"
                            name="username"
                            value={values.username}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input Username"
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Label
                            className="text-muted text-uppercase"
                            style={{ fontSize: '13px', letterSpacing: '0.5px' }}
                          >
                            <small>Password</small>
                          </Form.Label>
                          <div className="password-input-container">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="password"
                              name="password"
                              className={`form-control mt-3 ${
                                touched.password && !!errors.password
                                  ? 'is-invalid'
                                  : ''
                              } `}
                              required
                              placeholder="******"
                              value={values.password}
                            />
                            {!errors.password && touched.password && (
                              <div
                                className="password-toggle-icon position-absolute"
                                onClick={togglePasswordVisibility}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEyeSlash : faEye}
                                />
                              </div>
                            )}

                            {!!errors.password && touched.password && (
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                  {props.activeKey === 'external' && (
                    <>
                      <Row>
                        <Col md={6}>
                          <TextField
                            label="name"
                            name="name"
                            value={values.name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input Name"
                            required={true}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectInput
                            label="Status"
                            name="status"
                            id="status"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            options={statuses}
                            required={true}
                          />
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label
                              className="text-muted"
                              style={{ fontSize: '13px' }}
                            >
                              SIM <sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            {fileNameSim === '' && (
                              <Card
                                style={{
                                  width: '3rem',
                                  height: '3rem',
                                  cursor: 'pointer'
                                }}
                                onClick={() => fileUploadSim.current.click()}
                              >
                                <Card.Body>
                                  <div style={{ marginTop: '-.3rem' }}>
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ color: '#828282' }}
                                    />
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                            {fileNameSim !== '' && (
                              <Card>
                                <Card.Body>
                                  <div className="d-flex h-100 align-items-center">
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      style={{
                                        color: '#828282',
                                        fontSize: '20px',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => {
                                        setFileNameSim('')
                                        setFieldValue('sim_doc', '')
                                      }}
                                    />
                                    <span className="ml-2">{fileNameSim}</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                            <input
                              type="file"
                              name="sim_doc"
                              accept="image/jpg,image/jpeg,image/png,.doc,.docx,.pdf"
                              onChange={(e) => {
                                setFieldValue('sim_doc', e.target.files[0])
                                setFileNameSim(e.target.files[0].name)
                              }}
                              onBlur={handleBlur}
                              className="d-none"
                              ref={fileUploadSim}
                            />
                            {!!errors.sim_doc && (
                              <p className="text-danger mt-2">
                                {errors.sim_doc}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label
                              className="text-muted"
                              style={{ fontSize: '13px' }}
                            >
                              KTP <sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            {fileNameKtp === '' && (
                              <Card
                                style={{
                                  width: '3rem',
                                  height: '3rem',
                                  cursor: 'pointer'
                                }}
                                onClick={() => fileUploadKtp.current.click()}
                              >
                                <Card.Body>
                                  <div style={{ marginTop: '-.3rem' }}>
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{ color: '#828282' }}
                                    />
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                            {fileNameKtp !== '' && (
                              <Card>
                                <Card.Body>
                                  <div className="d-flex h-100 align-items-center">
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      style={{
                                        color: '#828282',
                                        fontSize: '20px',
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => {
                                        setFileNameKtp('')
                                        setFieldValue('ktp_doc', '')
                                      }}
                                    />
                                    <span className="ml-2">{fileNameKtp}</span>
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                            <input
                              type="file"
                              name="sim_doc"
                              accept="image/jpg,image/jpeg,image/png,.doc,.docx,.pdf"
                              onChange={(e) => {
                                setFieldValue('ktp_doc', e.target.files[0])
                                setFileNameKtp(e.target.files[0].name)
                              }}
                              onBlur={handleBlur}
                              className="d-none"
                              ref={fileUploadKtp}
                            />
                            {!!errors.ktp_doc && (
                              <p className="text-danger mt-2">
                                {errors.ktp_doc}
                              </p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Modal.Footer className="mt-3">
                    <CustomCloseButton onClick={props.onHide} />
                    <CustomButton
                      onClick={handleSubmit}
                      variant={'warning'}
                      disabled={!isValid || !dirty}
                      text={'Update'}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ModalEditDriver
