import { Container, Row, Col, Table, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../../components/atoms/Pagination/Index'
import { useNavigate } from 'react-router-dom'

const WorkOrderPlan = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const data = [
    {
      id: 1,
      wo: 'WOP23/02/B0021',
      rt: '14-05-2024; 23:00',
      dp: '14-05-2024',
      ft: '-',
      sj: 'SJ24032906',
      erp: '-',
      warehouse: 'Gudang Jatake',
      type: 'Alias',
      status: 'waiting'
    },
    {
      id: 2,
      wo: 'WOP23/02/B0021',
      rt: '14-05-2024; 23:00',
      dp: '-',
      ft: '-',
      sj: '-',
      erp: 'WO123456',
      warehouse: 'Gudang Jatake',
      type: 'Repack',
      status: 'waiting'
    }
  ]

  const navigate = useNavigate()
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 style={headerTitleStyle}>Work Order Plan</h1>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#Work Order</th>
                  <th className="p-3 align-middle">Request Time</th>
                  <th className="p-3 align-middle">Deliver Plan</th>
                  <th className="p-3 align-middle">Finish Time</th>
                  <th className="p-3 align-middle">SJ</th>
                  <th className="p-3 align-middle">ERP Doc. No.</th>
                  <th className="p-3 align-middle">Warehouse</th>
                  <th className="p-3 align-middle">Type</th>
                  <th className="p-3 align-middle">Status</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <>
                    <tr key={item.id}>
                      <td className="p-3 align-middle">{item.wo}</td>
                      <td className="p-3 align-middle">{item.rt}</td>
                      <td className="p-3 align-middle">{item.dp}</td>
                      <td className="p-3 align-middle">{item.ft}</td>
                      <td className="p-3 align-middle">{item.sj}</td>
                      <td className="p-3 align-middle">{item.erp}</td>
                      <td className="p-3 align-middle">{item.warehouse}</td>
                      <td className="p-3 align-middle">{item.type}</td>
                      <td className="p-3 align-middle">
                        <Badge pill className="p-2 bg-secondary">
                          {item.status}
                        </Badge>
                      </td>
                      <td className="p-3 align-middle">
                        <button className="btn btn-secondary">
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            onClick={() => navigate('/materialrequest/detail')}
                          />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-3">
                    <div className="d-flex align-items-center">
                      <span className="mr-2">Show</span>
                      <select
                        name="per_page"
                        className="form-select"
                        aria-label="show-page"
                        style={{ width: '60px' }}
                      >
                        {[10, 25, 50, 100].map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <span className="ml-2">
                        Entries | 1 to 10 of 10 entries
                      </span>
                    </div>
                  </td>
                  <td colspan={10} className="p-3">
                    <div className="d-flex justify-content-end">
                      <Pagination pageCount={10} page={1} />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WorkOrderPlan
