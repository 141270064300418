function Index({ colSpan = 5 }) {
  return (
    <tr>
      <td colSpan={colSpan} className="fw-bold text-center">
        Data empty
      </td>
    </tr>
  )
}

export default Index
